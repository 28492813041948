import React, { useEffect, useState } from "react";
import { FaderReverse } from "../../components/fader/Fader";
import { useLocale } from "../../hooks/useLocale";
import categoryServices from "../../services/categoryServices";
import "./OfferPage.css";
import { TreatmentCategoryCard } from "./TreatmentCategoryCard";

const doNotRenderCategIds = ["ponuda-meseca", "monthly-special", "voucher", "vaucer"];

export const OfferPage = () => {
	const locale = useLocale();
	const [loading, setLoading] = useState(true);
	const [categories, setCategories] = useState<Category[]>([]);

	const getCategories = async () => {
		const categs = (await categoryServices.getAll(locale))
			.filter(categ => doNotRenderCategIds.indexOf(categ.slug) === -1);
		setCategories(categs);
	};

	useEffect(() => {
		window.scrollTo({top: 0, behavior: "smooth"});
		getCategories()
			.finally(() => setLoading(false));
		// eslint-disable-next-line
	}, []);

	// items per row
	const n = 3;
	const rows = [...Array( Math.ceil(categories.length / n) )];
	// maps categories into list of rows
	const categoryRows = rows.map((row, idx) => categories.slice(idx * n, idx * n + n));

	const content = categoryRows.map((categRow, idx) => (
		<div className="row text-center" key={idx}>
			{categRow.map(categ =>
				<TreatmentCategoryCard
					cardSize={Math.round(12 / categRow.length)}
					key={categ.slug}
					category={categ}/>)}
		</div> )
	);

	let loadingBar = undefined;
	if (loading) {
		loadingBar = (<div className="progress mt-5">
				<div className="indeterminate"/>
			</div>)
	}

	return (
		<div>
			<div className="container offer-container">
				{loadingBar}
				{content}
			</div>
			<FaderReverse/>
			<div>
				<img
					src={"https://portmeirion.wales/oimgnn/spa/1600/beauty-spa-treatment-with-candles-pfzlm5z-1-80q.jpg"}
					alt="" className='monthly-special'/>
			</div>

		</div>
	);
};
