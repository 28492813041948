import * as React from "react";
import { useEffect } from "react";
import { ReservationForm } from "../../components/forms/ReservationForm";
import { backendServerUrl } from "../../globals";

type ReservationFormPageProps = {};
export const ReservationFormPage = (props: ReservationFormPageProps) => {
	useEffect(() => {
		window.scrollTo({top: 0, behavior: "smooth"});
		// eslint-disable-next-line
	}, []);
	return (
		<div id="consultation-form-page" className="container">
			<ReservationForm action={backendServerUrl + "/forms/reservation"} isRequired={false}/>
		</div>
	);
};
