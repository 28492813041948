import * as React from "react";
import { CSSProperties } from "react";
import "./CircleButton.css";
import particleCircle from "../../assets/img/particle-circle.png";
import { Link } from "react-router-dom";

type CircleButtonProps = {
	size: number;
	img: any;
	onClick?: any;
	caption?: any;
	link?: string;
};

export const CircleButton = (props: CircleButtonProps) => {
	const styles: CSSProperties = {
		minHeight: props.size,
		minWidth: props.size,
	};

	const getLink = () => {
		if (!props.link) return (
			<span style={{fontSize: props.size / 9}}>
            	{props.caption}
            </span>
		);

        if (props.link.startsWith("http")) {
            return (
                <a href={props.link}>
                    <span style={{fontSize: props.size / 9}}>
                        {props.caption}
                    </span>
                </a>
            );
        }

		return (
			<Link to={props.link}>
                <span style={{fontSize: props.size / 9}}>
                    {props.caption}
                </span>
			</Link>
		);

	}

	return (
		<div style={styles} className="circle-button" onClick={ev => {
			props.onClick && props.onClick(ev);
		}}>
			<img className="circle-button-texture" src={particleCircle} alt="Circle Button"/>
			<img className="circle-button-badge" src={props.img} alt="Circle Button Badge"/>
			<div className="circle-button-caption">
				{getLink()}
			</div>
		</div>
	);
};
