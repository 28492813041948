import React, {useEffect, useState} from "react";
import "./About.css";
import {EmployeeCard} from "./EmployeeCard";
import noUser from "../../assets/img/no-user.png";
import groupPhoto from "../../assets/img/employees/group.jpg";
import {defaultLocale, locales} from "../../localization/locales";
import langs from "../../localization/languages/languages";
import employeeServices from "../../services/employeeServices";
import {backendServerUrl} from "../../globals";
import {semanticJoin, ownerFirst, translateTitle} from "../../util/util";

export const About = (props:any) => {
	let {lang = defaultLocale} = props.match?.params;
	let [employees, setEmployees] = useState<Employee[]>([]);

	if (locales.indexOf(lang) === -1) {
		lang = defaultLocale;
	}
	useEffect(() => {
		employeeServices.getAll()
			.then(setEmployees);
		window.scrollTo({top: 0});
		// eslint-disable-next-line
	}, []);

	const boss = employees.find(e => e.titles.indexOf("OWNER") !== -1);

	return (
		<div className="about-container">
			<h2 className="center gold mb-5">{langs[lang].about_group_title}</h2>
			<div className="container">
				<div className="row mb-5">
					<div className="col s12 l12 group-photo">
						<img src={groupPhoto} alt="Zen team"/>
					</div>
				</div>
				<div className="boss-card mt-5">
					<div className="boss-image">
						<img src={boss ? backendServerUrl + boss?.imageUrl : ""} alt="Owner" />
					</div>
					<h2 className="boss-name">{boss?.firstName} {boss?.lastName}</h2>
					<h5 className="boss-title">{boss?.titles?.sort(ownerFirst)
						.map(translateTitle(lang))
						.reduce(semanticJoin(lang))}</h5>
				</div>
				<div className="employee-container row">
					{(employees).filter(e => e.titles.indexOf("OWNER") === -1).map((employee, i) => {
						return (
							<EmployeeCard
								id={employee.id + ""}
								name={`${employee.firstName} ${employee.lastName}`}
								titles={employee.titles}
								key={i}
								imgTitleProp={langs[lang].about_image_title_prop}
								img={employee.imageUrl ? (backendServerUrl + employee.imageUrl) : noUser}/>
						)
					})}
				</div>
			</div>
		</div>
	);
};
