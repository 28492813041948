import axios from "axios";
import { backendServerUrl } from "../globals";

const verify = async (orderId: string, locale?: string): Promise<PurchaseVerifyResponse> => {
	return (await axios.post(`${backendServerUrl}/purchase/verify`, {orderId, locale})).data;
};

const purchaseServices: PurchaseServices = {
	verify,
};

export default purchaseServices;
