import * as React from "react";
import "./Fader.css";

export const Fader = () => {
	return (
		<div className="fader"/>
	);
};


export const FaderReverse = () => {
	return (
		<div className="fader-reverse"/>
	);
};

