import * as React from "react";
import langs from "../../../localization/languages/languages";
import { AboutEducator } from "../AboutEducator";
import { useLocale } from "../../../hooks/useLocale";

export const PrenatalWeekendSeminar = () => {
	const lang = useLocale();

	return (
		<div>
			<h3>Prenatalna masaža - masaža trudnica</h3>

			<p>
				<span className="bold-text">Datum održavanja kursa:</span> april/maj 2023.<br/>
				<span className="bold-text">Vreme održavanja kursa:</span> 10 – 18h (pauza za ručak 13-14h)
			</p>

			<h4>Šta je to prenatalna masaža?</h4>

			<p>
				Mišićna napetost, otok u nogama, bol u ramenom predelu i kukovima su neretke pojave u drugom stanju.
				Masaža
				u značajnoj meri može da ublaži ove simptome. Prenatalna masaža je skup adaptiranih tehnika za
				prenatalni
				period koje se pre svega odnose na bezbedni pritisak koji smemo da vršimo na telo kako I ispravno
				pozicioniranje u toku masaže.
			</p>

			<h4>Za koga je ovaj seminar?</h4>

			<p>
				Prenatalna masaža zahteva posebno znanje koje se odnosi na prenatalni period kao i poseban set veština u
				pogledu izvođenja masaže I poznavanja indikacija I kontraindikacija. Zato je ovaj kurs namenjen
				isključivo
				onima koji imaju iskustvo u izvođenju relaksacione ili terapeutske masaže, jer će se podrazumevati da
				imate
				osnovno znanje iz oblasti masaže i anatomije tela.
			</p>

			<h4>Zašto je dobro znati prenatalnu masažu?</h4>

			<p>
				U svakodnevnoj praksi se često susrećemo sa budućim majkama, budući da zbog loše posture tela koju
				razvijaju
				u trudnoći, potrebuju masažu više nego inače. Jako je važno da, kao i za izvođenje bilo koje druge vrste
				masaže, budemo profesionalni I stručni, što se pre svega odnosi na prvi princip u našoj praksi “pre
				svega-
				ne naškodi”. Poznavanje pravilnog pozicioniranja klijentkinja u toku masaže, ispravna tehnika pokreta,
				preporučeni pritisak u masai - su samo neke od tema koje se obrađuju, a koje će doprineti da se osećate
				samopouzdano radeći sa damama u ovom osetljivom preriodu života.
			</p>

			<h4>Program kursa</h4>

			<p>
				Ovaj kurs sastoji se od teorijskog predavanja i praktične vežbe.
				Na teorijskom predavanju saznaćete benefite prenatalne masaže, kontraindikacije i indikacije za
				izvođenje
				iste, opreme koja nam je potrebna za rad, bezbedna ulja koja smemo da upotrebljavamo, opis glavnih
				karakteristika svakog trimestra...
				Praktično, pokazaćemo vam tehnike koju primenjujemo u slučaju otoka u nogama, napetosti u vratu i donjim
				leđima, zatim izvođenje opuštajuće masaže za celo telo, tehnike koje umanjuju mogućnost nastanka grčeva
				u
				listovima itd.
			</p>

			<h4>Cena kursa:</h4>

			<p>
				150e
			</p>

			<h4>Predavač:</h4>

			<AboutEducator {...langs[lang].course_educators[0]}/>
		</div>
	);
};
