import * as React from "react";
import { createRef, FormEvent } from "react";
import "./Forms.css";
import { useModal } from "../../hooks/useModal";
import axios from "axios";
import langs from "../../localization/languages/languages";
import { useLocale } from "../../hooks/useLocale";

type CareerFormProps = {
	action: string;
	isRequired: boolean;
};

export const CareerForm = ({action, isRequired}: CareerFormProps) => {
	const [modal, setModalProps, openModal] = useModal();
	const formRef = createRef<HTMLFormElement>();
	const lang = useLocale();

	const onSubmit = async (ev: FormEvent) => {
		ev.preventDefault();
		const target = formRef.current as HTMLFormElement;
		let formData: Record<string, string> = {};
		Object.keys(target.elements).forEach(k => {
			const elem = target.elements.item(parseInt(k)) as HTMLInputElement;

			if (elem.name !== "") {
				if (elem.type === "radio") {
					if (elem.checked) {
						formData[elem.name] = elem.value;
					}
				} else {
					formData[elem.name] = elem.value;
				}
			}
		});

		try {
			await axios.post(action, formData);
			setModalProps({title: langs[lang].forms_info_title, list: [langs[lang].forms_info_message]});
			openModal();
			target.reset();

		} catch (e) {
			let errors = e.response.data;
			let errorMessages = Object.keys(errors).map(key => `${key}: ${errors[key]}`)
			setModalProps({title: langs[lang].forms_invalid_title, list: errorMessages});
			// console.log(e.response.data);
			openModal();
			// console.dir(e);
			return;
		}
	};

	return (
		<form onSubmit={onSubmit} ref={formRef} action={action} method="POST">
			{modal}
			<div className="row">
				<div className="col s12">
					<h4 className="gold">{langs[lang].forms_career_title_1}</h4>
					<p>{langs[lang].forms_career_paragraph_1}</p>
					<p>{langs[lang].forms_career_paragraph_2}</p>
					<p>{langs[lang].forms_career_paragraph_3}</p>
					<p>{langs[lang].forms_career_paragraph_4}</p>
					<h4 className="gold">{langs[lang].forms_career_title_2}</h4>
					<p>{langs[lang].forms_career_paragraph_5}</p>
					<p>{langs[lang].forms_career_paragraph_6}</p>
					<h4 className="gold">{langs[lang].forms_career_title_3}</h4>
					<p>{langs[lang].forms_career_paragraph_7}</p>
					<p>{langs[lang].forms_career_paragraph_8}</p>
					<h4 className="gold">{langs[lang].forms_career_title_4}</h4>
					<p>{langs[lang].forms_career_paragraph_9}</p>
					<h4 className="gold">{langs[lang].forms_career_title_5}</h4>

				</div>
			</div>

			<div className="row">
				<div className="input-field col s12 m12 l6">
					<input
						placeholder={langs[lang].forms_career_name_placeholder}
						name="name"
						type="text"
						required={isRequired}/>
					<label htmlFor="name">{langs[lang].forms_career_name}</label>
				</div>
			</div>
			<div className="row">
				<div className="input-field col s12 m12 l6">
					<input
						placeholder={langs[lang].forms_career_email_placeholder}
						name="email"
						type="email"
						required={isRequired}/>
					<label htmlFor="email">{langs[lang].forms_career_email}</label>
				</div>
				<div className="input-field col s12 m12 l6">
					<input
						placeholder={langs[lang].forms_career_phone_placeholder}
						name="phone"
						type="text"
						required={isRequired}/>
					<label htmlFor="phone">{langs[lang].forms_career_phone}</label>
				</div>
			</div>
			<div className="row">
				<div className="input-field col s12">
					<textarea name="bio" className="materialize-textarea"/>
					<label className="active" htmlFor="message">{langs[lang].forms_career_bio}</label>
				</div>
			</div>
			<div className="row">
				<div className="col s12">
					<button className="btn-dark-gold" type="submit">{langs[lang].forms_career_btn}</button>
				</div>
			</div>
		</form>
	);
};
