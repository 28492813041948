import * as React from "react";
import { backendServerUrl } from "../../globals";

type TreatmentPurchaseButtonProps = {
	treatment: Treatment;
	index: number;
};

export const TreatmentPurchaseButton = ({treatment, index}: TreatmentPurchaseButtonProps) => {
	const price = treatment.prices[index];
	let buttonText;

	if (price.type === "PACKAGE") {
		buttonText = (<span>
					{price.duration === 0 ? undefined : (price.duration + " / ")}
			{price.price}
				</span>);
	} else {
		buttonText = (<span>
					{price.duration === 0 ? undefined : (price.duration + "' / ")}
			{price.price}
				</span>);
	}

	return (<div className="col s12 m12 l12">
		{/* eslint-disable-next-line react/jsx-no-undef */}
		<a href={backendServerUrl + `/purchase?treatment=${treatment.id}&price=${price.id}`}
		   className="btn-treatment-purchase">
			{buttonText}
			<i className="material-icons">shopping_basket</i>
		</a>
	</div>);
};
