import { faq_course as course_en } from "./course/en";
import { faq_course as course_rs } from "./course/rs";

import { faq_treatment as treatment_en } from "./treatment/en";
import { faq_treatment as treatment_rs } from "./treatment/rs";

export const faq = {
	treatment: {
		en: treatment_en,
		rs: treatment_rs,
	},
	course: {
		en: course_en,
		rs: course_rs,
	},
};
