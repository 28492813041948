import * as React from "react";
import langs from "../../localization/languages/languages";
import { parseUrls } from "../../util/parsing";
import { useLocale } from "../../hooks/useLocale";

type NoticeProps = {text?: any}
export const Notice = (props:NoticeProps) => {
	const lang = useLocale();

	const text = props.text
		? props.text
		: parseUrls(langs[lang].course_notice_paragraph_text, "gold");

	return (
		<div>
			<p className="border-dark-gold gold p-3 white-text">
				{text}
			</p>
		</div>
	);
};
