import * as React from "react";
import { createRef, FormEvent, useEffect } from "react";
import "./Forms.css";
import { useModal } from "../../hooks/useModal";
import axios from "axios";
import langs from "../../localization/languages/languages";

type ConsultationFormProps = {
	action: string;
	isRequired: boolean;
	lang: string;
};
export const ConsultationForm = ({isRequired, action, lang}: ConsultationFormProps) => {
	const selectRefs = [createRef<HTMLSelectElement>()];
	const [modal, setModalProps, openModal] = useModal();
	const formRef = createRef<HTMLFormElement>();

	const onSubmit = async (ev: FormEvent) => {
		ev.preventDefault();
		const target = formRef.current as HTMLFormElement;
		let formData: Record<string, string | string[]> = {};
		Object.keys(target.elements).forEach(k => {
			const elem = target.elements.item(parseInt(k)) as HTMLInputElement;

			if (elem.name !== "") {
				if (elem.type === "radio") {
					if (elem.checked) {
						formData[elem.name] = elem.value;
					}
				} else if (elem.type === "checkbox") {
					if (elem.checked) {
						if (!formData[elem.name]) {
							formData[elem.name] = [];
						}
						(formData[elem.name] as string[]).push(elem.value);
					}
				} else {
					formData[elem.name] = elem.value;
				}
			}
		});

		try {
			await axios.post(action, formData);
			setModalProps({title: langs[lang].forms_info_title, list: [langs[lang].forms_info_message]});
			openModal();
			target.reset();

		} catch (e) {
			let errors = e.response.data;
			let errorMessages = Object.keys(errors).map(key => `${key}: ${errors[key]}`)
			setModalProps({title: langs[lang].forms_invalid_title, list: errorMessages});
			// console.log(e.response.data);
			openModal();
			// console.dir(e);
			return;
		}
	};

	useEffect(() => {
		selectRefs.forEach(ref => {
			if (ref.current) {
				M.FormSelect.init(ref.current, {});
			}
		});
		// eslint-disable-next-line
	}, [lang]);

	return (
		<form ref={formRef} onSubmit={onSubmit} action={action} method="POST">
			{modal}
			<div className="row">
				<div className="input-field col s12 m12 l6">
					<input
						placeholder=""
						id="first_name"
						name="first_name"
						type="text"
						required={isRequired}
					/>
					<label htmlFor="first_name">{langs[lang].forms_consultation_regular_first_name}</label>
				</div>
				<div className="input-field col s12 m12 l6">
					<input
						placeholder=""
						id="last_name"
						name="last_name"
						type="text"
						required={isRequired}
					/>
					<label htmlFor="last_name">{langs[lang].forms_consultation_regular_last_name}</label>
				</div>
			</div>
			<div className="row">
				<div className="input-field col s12 m12 l6">
					<input
						placeholder="example@mail.com"
						name="email"
						type="email"
						required={isRequired}
					/>
					<label htmlFor="email">{langs[lang].forms_consultation_regular_email}</label>
				</div>
				<div className="input-field col s12 m12 l6">
					<input
						placeholder="+381 60 00 00 000 "
						name="phone"
						type="text"
						required={isRequired}
					/>
					<label htmlFor="phone">{langs[lang].forms_consultation_regular_phone}</label>
				</div>
			</div>
			<div className="row">
				<div className="input-field col s12 m12 l6">
					<select
						ref={selectRefs[0]}
						name="therapist_gender"
						defaultValue="svejedno"
						required={isRequired}>
						<option
							value="svejedno">{langs[lang].forms_consultation_regular_therapist_gender_list[0]}</option>
						<option value="muški">{langs[lang].forms_consultation_regular_therapist_gender_list[1]}</option>
						<option
							value="ženski">{langs[lang].forms_consultation_regular_therapist_gender_list[2]}</option>
					</select>
					<label htmlFor="therapist_gender">{langs[lang].forms_consultation_regular_therapist_gender}</label>
				</div>
				<div className="col s12 m12 l6">
					<label
						className="input-label">{langs[lang].forms_consultation_regular_radio_age_group_title}</label>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="age_group"
								value="Do 21"
								type="radio"
								defaultChecked={true}
								required={isRequired}
							/>
							<span>{langs[lang].forms_consultation_regular_radio_age_group_items[0]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="age_group"
								value="Od 22 do 35"
								type="radio"
								required={isRequired}
							/>
							<span>{langs[lang].forms_consultation_regular_radio_age_group_items[1]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="age_group"
								value="Od 36 do 55"
								type="radio"
								required={isRequired}
							/>
							<span>{langs[lang].forms_consultation_regular_radio_age_group_items[2]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="age_group"
								value="Preko 56"
								type="radio"
								required={isRequired}
							/>
							<span>{langs[lang].forms_consultation_regular_radio_age_group_items[3]}</span></label>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col s12 m12 l6 mb-3">
					<label
						className="input-label">{langs[lang].forms_consultation_regular_check_condition_title}</label>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="condition"
								value="Nedavne operacije"
								className="filled-in"
								type="checkbox"
								required={isRequired}
							/>
							<span>{langs[lang].forms_consultation_regular_check_condition_items[0]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="condition"
								value="Diskus hernija/Diskopatija"
								className="filled-in"
								type="checkbox"
								required={isRequired}
							/>
							<span>{langs[lang].forms_consultation_regular_check_condition_items[1]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="condition"
								value="Bol u donjem delu leđa"
								className="filled-in"
								type="checkbox"
								required={isRequired}
							/>
							<span>{langs[lang].forms_consultation_regular_check_condition_items[2]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="condition"
								value="Artritis"
								className="filled-in"
								type="checkbox"
								required={isRequired}
							/>
							<span>{langs[lang].forms_consultation_regular_check_condition_items[3]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="condition"
								value="Alergija"
								className="filled-in"
								type="checkbox"
								required={isRequired}
							/>
							<span>{langs[lang].forms_consultation_regular_check_condition_items[4]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="condition"
								value="Bol u vratu ili ruci"
								className="filled-in"
								type="checkbox"
								required={isRequired}
							/>
							<span>{langs[lang].forms_consultation_regular_check_condition_items[5]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="condition"
								value="Spazam mišića('grčevi')"
								className="filled-in"
								type="checkbox"
								required={isRequired}
							/>
							<span>{langs[lang].forms_consultation_regular_check_condition_items[6]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="condition"
								value="Kancer"
								className="filled-in"
								type="checkbox"
								required={isRequired}
							/>
							<span>{langs[lang].forms_consultation_regular_check_condition_items[7]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="condition"
								value="Trudnoća"
								className="filled-in"
								type="checkbox"
								required={isRequired}
							/>
							<span>{langs[lang].forms_consultation_regular_check_condition_items[8]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="condition"
								value="Povišeni/sniženi krvni pritisak"
								className="filled-in"
								type="checkbox"
								required={isRequired}
							/>
							<span>{langs[lang].forms_consultation_regular_check_condition_items[9]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="condition"
								value="Venska tromboza"
								className="filled-in"
								type="checkbox"
								required={isRequired}
							/>
							<span>{langs[lang].forms_consultation_regular_check_condition_items[10]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="condition"
								value="Epilepsija"
								className="filled-in"
								type="checkbox"
								required={isRequired}
							/>
							<span>{langs[lang].forms_consultation_regular_check_condition_items[11]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="condition"
								value="Osteoporoza"
								className="filled-in"
								type="checkbox"
								required={isRequired}
							/>
							<span>{langs[lang].forms_consultation_regular_check_condition_items[12]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="condition"
								value="Migrene/Glavobolje"
								className="filled-in"
								type="checkbox"
								required={isRequired}
							/>
							<span>{langs[lang].forms_consultation_regular_check_condition_items[13]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="condition"
								value="Stres"
								className="filled-in"
								type="checkbox"
								required={isRequired}
							/>
							<span>{langs[lang].forms_consultation_regular_check_condition_items[14]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="condition"
								value="Bol/Povreda ramena"
								className="filled-in"
								type="checkbox"
								required={isRequired}
							/>
							<span>{langs[lang].forms_consultation_regular_check_condition_items[15]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="condition"
								value="Bivši prelomi/Uganuća/Istegnuća"
								className="filled-in"
								type="checkbox"
								required={isRequired}
							/>
							<span>{langs[lang].forms_consultation_regular_check_condition_items[16]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="condition"
								value="Proširene vene"
								className="filled-in"
								type="checkbox"
								required={isRequired}
							/>
							<span>{langs[lang].forms_consultation_regular_check_condition_items[17]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="condition"
								value="Istorija kancera"
								className="filled-in"
								type="checkbox"
								required={isRequired}
							/>
							<span>{langs[lang].forms_consultation_regular_check_condition_items[18]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="condition"
								value="Uklonjeni limfni čvorovi"
								className="filled-in"
								type="checkbox"
								required={isRequired}
							/>
							<span>{langs[lang].forms_consultation_regular_check_condition_items[19]}</span></label>
					</div>
				</div>
				<div className="col s12 m12 l6 mb-3">
					<label
						className="input-label">{langs[lang].forms_consultation_regular_radio_expectation_title}</label>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="expectation"
								value="Relaksaciju"
								type="radio"
								defaultChecked={true}
								required={isRequired}
							/>
							<span>{langs[lang].forms_consultation_regular_radio_expectation_items[0]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="expectation"
								value="Otklanjanje bola"
								type="radio"
								required={isRequired}
							/>
							<span>{langs[lang].forms_consultation_regular_radio_expectation_items[1]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="expectation"
								value="Opuštanje misicne tenzije"
								type="radio"
								required={isRequired}
							/>
							<span>{langs[lang].forms_consultation_regular_radio_expectation_items[2]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="expectation"
								value="Oslobađanje od stresa"
								type="radio"
								required={isRequired}
							/>
							<span>{langs[lang].forms_consultation_regular_radio_expectation_items[3]}</span></label>
					</div>
				</div>
				<div className="col s12 m12 l6 mb-3">
					<label
						className="input-label">{langs[lang].forms_consultation_regular_radio_massage_frequency_title}</label>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="massage_frequency"
								value="Minimum jednom mesečno"
								type="radio"
								defaultChecked={true}
								required={isRequired}
							/>
							<span>{langs[lang].forms_consultation_regular_radio_massage_frequency_items[0]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="massage_frequency"
								value="Jednom ili dva puta godišnje"
								type="radio"
								required={isRequired}
							/>
							<span>{langs[lang].forms_consultation_regular_radio_massage_frequency_items[1]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="massage_frequency"
								value="Nikada nisam imao/la profesionalnu masažu"
								type="radio"
								required={isRequired}
							/>
							<span>{langs[lang].forms_consultation_regular_radio_massage_frequency_items[2]}</span></label>
					</div>
				</div>
				<div className="col s12 m12 l6 mb-3">
					<label
						className="input-label">{langs[lang].forms_consultation_regular_radio_sport_frequency_title}</label>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="sport_frequency"
								value="Da"
								type="radio"
								required={isRequired}
							/>
							<span>{langs[lang].forms_consultation_regular_radio_sport_frequency_items[0]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="sport_frequency"
								value="Ne
								   "
								type="radio"
								required={isRequired}
								defaultChecked={true}
							/>
							<span>{langs[lang].forms_consultation_regular_radio_sport_frequency_items[1]}</span></label>
					</div>
					<div className="input-field mt-5 mb-2">
                        <textarea
	                        name="sport"
	                        className="materialize-textarea"
	                        required={isRequired}
                        />
						<label className="multiline-label-1">{langs[lang].forms_consultation_regular_sport}</label>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col s12 m12 l6">
					<label
						className="input-label">{langs[lang].forms_consultation_regular_radio_heard_about_title}</label>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="heard_about"
								value="Facebook"
								type="radio"
								defaultChecked={true}
								required={isRequired}
							/>
							<span>{langs[lang].forms_consultation_regular_radio_heard_about_items[0]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="heard_about"
								value="Instagram"
								type="radio"
								required={isRequired}
							/>
							<span>{langs[lang].forms_consultation_regular_radio_heard_about_items[1]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="heard_about"
								value="Video/la sam salon u prolazu"
								type="radio"
								required={isRequired}
							/>
							<span>{langs[lang].forms_consultation_regular_radio_heard_about_items[2]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="heard_about"
								value="Preporuka"
								type="radio"
								required={isRequired}
							/>
							<span>{langs[lang].forms_consultation_regular_radio_heard_about_items[3]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="heard_about"
								value="Bilbord"
								type="radio"
								required={isRequired}
							/>
							<span>{langs[lang].forms_consultation_regular_radio_heard_about_items[4]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="heard_about"
								value="Dobilo/a sam vaučer"
								type="radio"
								required={isRequired}
							/>
							<span>{langs[lang].forms_consultation_regular_radio_heard_about_items[5]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="heard_about"
								value="Drugo"
								type="radio"
								required={isRequired}
							/>
							<span>{langs[lang].forms_consultation_regular_radio_heard_about_items[6]}</span></label>
					</div>
				</div>
				<div className="input-field col s12 m12 l6">
					<textarea name="other" className="materialize-textarea"/>
					<label>{langs[lang].forms_consultation_regular_other}</label>
				</div>
			</div>
			<div className="row">
				<div className="col s12">
					<button className="btn-dark-gold" type="submit">
						{langs[lang].forms_consultation_regular_button_txt}
					</button>
				</div>
			</div>
			<div className="row">
				<div className="col s12">
					<p className="border-dark-gold gold p-3">{langs[lang].forms_consultation_regular_paragraph_5}</p>
				</div>
			</div>
		</form>
	);
};
