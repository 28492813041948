import * as React from "react";

type HeaderSlideProps = {
	backImg: any;
	mobileImg?: any;
	textImg?: any;
	links?: any[];
};
export const HeaderSlide = (props: HeaderSlideProps) => {
	return (
		<div key={props.backImg} className="h-100 carousel-item2">
			<img className="slide-img" src={props.backImg} alt="Slide"/>
			{props.mobileImg ?
				<img className="mobile-img" src={props.mobileImg}
					 alt="Slide"/> : undefined}
			<div className="container">
				{props.textImg ? <img className="text-img" src={props.textImg}
									  alt="Slide text"/> : ""}
				<div className='slide-links'>
					{props.links ? props.links.map(link => link) : ""}
				</div>
			</div>
		</div>
	);
};
