// @flow
import * as React from "react";
import {CSSProperties} from "react";
import {Link} from "react-router-dom";

type CourseLevelProps = {
	title: string;
	titleDate?: string;
	description: string;
	image: any;
	path: string;
	imageStyles?: CSSProperties
};

export const SeminarCard = (props: CourseLevelProps) => {
	return (
		<div className="card course-card">
			<div className="row">
				<div className="col s12 m4 l3 image">
					<div className="course-element-avatar">
						<div className="transparent-filter">
							<img src={props.image} alt={props.title} style={{...props.imageStyles}}/>
							<div className="overlay"/>
						</div>
					</div>
				</div>
				<div className="col s12 m8 l9 card-content course-summary">
					<h4 className="left-align">{props.title}</h4>
					<p>{props.description}</p>
					<p>{<Link className="btn bg-dark-gold white-text"
							  key={props.path}
							  to={props.path}>
						SAZNAJ VIŠE</Link>}</p>
				</div>
			</div>
		</div>
	);
};
