import langs from "../localization/languages/languages";

type PurchaseParams = {
	treatment: string;
	price: string;
	category: string;
	[key: string]: string;
}
export const getPurchaseParamsFromQuery = (query: string): PurchaseParams => {
	let data: PurchaseParams = {
		treatment: "",
		price: "",
		category: "",
	};

	if (!query) {
		return data;
	}

	const str = query.replace("?", "");

	str.split("&").forEach(kv => {
		const [key, value] = kv.split("=");
		if (key && value) {
			switch (key) {
				case"treatment":
					data["treatment"] = value;
					break;
				case"price":
					data["price"] = value;
					break;
				case"category":
					data["category"] = value;
					break;
			}
		}
	});

	return data;
};

export const conjuction:Record<string, string> = {
	"en": " and ",
	"rs": " i "
}

export const translateTitle = (lang: string) => {
	return (title: string) => {
		return langs[lang].titles[title];
	}
}

export const ownerFirst = (title1: string, title2: string) => {
	if (title1 === title2) {
		return 0;
	}

	if (title1 === "OWNER") {
		return -1;
	}

	if (title2 === "OWNER") {
		return 1;
	}

	return title1.localeCompare(title2);
}

export const semanticJoin = (lang: string) => {
	return (prev: string, curr: string, index: number, arr: string[]) => {
		if (index < arr.length - 1) {
			return prev + ", " + curr;
		}

		return prev + conjuction[lang] + curr;
	}
}
