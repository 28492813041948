import axios from "axios";
import { backendServerUrl } from "../globals";
import { defaultLocale } from "../localization/locales";

const getAll = async (locale = defaultLocale): Promise<Treatment[]> => {
	return (await axios.get(`${backendServerUrl}/treatments?lang=${locale}`)).data;
};


const getByQuery = async (query: string, locale = defaultLocale): Promise<Treatment> => {
	return (await axios.get(`${backendServerUrl}/treatments/${query}?lang=${locale}`)).data;
};

const getByCategory = async (query: string, locale = defaultLocale): Promise<Treatment[]> => {
	return (await axios.get(`${backendServerUrl}/categories/${query}/treatments?lang=${locale}`)).data;
};


const treatmentServices: TreatmentServices = {
	getAll,
	getByQuery,
	getByCategory,
};

export default treatmentServices;
