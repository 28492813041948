import * as React from "react";
import { defaultLocale, locales } from "../../localization/locales";
import { Redirect } from "react-router-dom";
import routes from "../../localization/routes/routes";

type LocaleFixRedirecterProps = {};
export const LocaleFixRedirecter = (props: LocaleFixRedirecterProps) => {
	const pathname = window.location.hash;
	const lang = pathname.match(/\/[a-z]{2}\//) ? pathname.match(/\/([a-z]{2})\//)![1] : "";

	if (locales.indexOf(lang) === -1){
		return (<Redirect to={`/${defaultLocale}/${routes[defaultLocale].home}`}/>);
	} else {
		return <span/>;
	}
};
