// @flow
import * as React from "react";

type CourseLevelProps = {
	id: string;
	title: string;
	titleDate?: string;
	description: string;
	avatar: any;
	btnText:string;
};
export const CourseCard = (props: CourseLevelProps) => {
	return (
		<div className="card course-card">
			<div className="row">
				<div className="col s12 m3 l3 course-image">
					<img src={props.avatar} alt={props.title + " image"}/>
				</div>
				<div className="col s12 m9 l9 card-content course-summary">
					<h2>{props.title}</h2>
					<p>{props.description}</p>
				</div>
			</div>
		</div>
	);
};
