import * as React from "react";
import { createRef, FormEvent } from "react";
import "./Forms.css";
import { useModal } from "../../hooks/useModal";
import axios from "axios";
import langs from "../../localization/languages/languages";
import { parseUrls } from "../../util/parsing";
import { useLocale } from "../../hooks/useLocale";

type ReservationFormProps = {
	action: string;
	isRequired: boolean;
};
export const ReservationForm = ({action, isRequired}: ReservationFormProps) => {
const lang = useLocale();

	const [modal, setModalProps, openModal] = useModal();
	const formRef = createRef<HTMLFormElement>();

	const onSubmit = async (ev: FormEvent) => {
		ev.preventDefault();
		const target = formRef.current as HTMLFormElement;
		let formData: Record<string, string> = {};
		Object.keys(target.elements).forEach(k => {
			const elem = target.elements.item(parseInt(k)) as HTMLInputElement;

			if (elem.name !== "") {
				if (elem.type === "radio") {
					if (elem.checked) {
						formData[elem.name] = elem.value;
					}
				} else {
					formData[elem.name] = elem.value;
				}
			}
		});

		try {
			await axios.post(action, formData);
			setModalProps({title: langs[lang].forms_info_title, list: [langs[lang].forms_info_message]});
			openModal();
			target.reset();

		} catch (e) {
			setModalProps({title: langs[lang].forms_invalid_title, list: e.response.data.errors});
			// console.log(e.response.data);
			openModal();
			// console.dir(e);
			return;
		}
	};

	return (
		<form ref={formRef} onSubmit={onSubmit} action={action} method="POST">
			{modal}
			<div className="row">
				<div className="col s12">
					<h4 className="gold">{langs[lang].forms_reservation_header_txt}</h4>
					<p>{langs[lang].forms_reservation_paragraph_1}</p>
					<p>{parseUrls(langs[lang].forms_reservation_paragraph_2, "gold")}</p>
					<h6>{langs[lang].forms_reservation_list_header}</h6>
					<ol>
						{langs[lang].forms_reservation_list.map(item => <li>{parseUrls(item, "gold")}</li>)}
					</ol>
				</div>
			</div>
			<div className="row">
				<div className="input-field col s12 m12 l6">
					<input
						placeholder={langs[lang].forms_reservation_name_placeholder}
						name="name"
						type="text"
						required={isRequired}
					/>
					<label htmlFor="name">{langs[lang].forms_reservation_name}</label>
				</div>
			</div>

			<div className="row mt-4">
				<div className="input-field col s12 m12 l6">
					<input
						placeholder={langs[lang].forms_reservation_email_placeholder}
						name="email"
						type="email"
						required={isRequired}
					/>
					<label htmlFor="name">{langs[lang].forms_reservation_email}</label>
				</div>
				<div className="input-field col s12 m12 l6">
					<input
						placeholder={langs[lang].forms_reservation_phone_placeholder}
						name="phone"
						type="text"
						required={isRequired}
					/>
					<label htmlFor="phone">{langs[lang].forms_reservation_phone}</label>
				</div>
			</div>

			<div className="row">
				<div className="input-field col s12">
					<textarea name="message" className="materialize-textarea"/>
					<label className="active" htmlFor="message">
						{langs[lang].forms_reservation_message}
					</label>
				</div>
			</div>

			<div className="row">
				<div className="col s12">
					<button className="btn-dark-gold" type="submit">
						{langs[lang].forms_reservation_button_txt}
					</button>
				</div>
			</div>

			<div className="row">
				<div className="col s12">
					<p>{langs[lang].forms_reservation_paragraph_4}</p>
					<p>{langs[lang].forms_reservation_paragraph_5}</p>
				</div>
			</div>
		</form>
	);
};
