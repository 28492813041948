import axios from "axios";
import { backendServerUrl } from "../globals";
import { defaultLocale } from "../localization/locales";

const getAll = async (locale = defaultLocale): Promise<Category[]> => {
	return (await axios.get(`${backendServerUrl}/categories?lang=${locale}`)).data;
};


const getByQuery = async (query: string, locale = defaultLocale): Promise<Category> => {
	return (await axios.get(`${backendServerUrl}/categories/${query}?lang=${locale}`)).data;
};

const categoryServices: CategoryServices = {
	getByQuery,
	getAll,
};

export default categoryServices;
