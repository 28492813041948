import * as React from "react";
import { useEffect } from "react";
import { CareerForm } from "../../components/forms/CareerForm";
import { defaultLocale } from "../../localization/locales";
import { backendServerUrl } from "../../globals";

type CareerFormPageProps = {};
export const CareerFormPage = (props: CareerFormPageProps & any) => {
	const {lang = defaultLocale} = props.match?.params;
	useEffect(() => {
		window.scrollTo({top: 0, behavior: "smooth"});
		// eslint-disable-next-line
	}, []);
	return (
		<div id="consultation-form-page" className="container">
			<CareerForm action={backendServerUrl + "/forms/career?locale=" + lang} isRequired={false}/>
		</div>
	);
};
