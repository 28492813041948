export const employees: EmployeeOld[] = [
	{
		id: "marija-stefanovic",
		name: "Marija Stefanović",
		title: "Receptionist",

	},
	{
		id: "miljana-miljkovic",
		name: "Miljana Miljković",
		title: "Therapist",

	},
	{
		id: "jelena-stankovic",
		name: "Jelena Stanković",
		title: "Therapist",

	},
	{
		id: "jovana-jankovic",
		name: "Jovana Janković",
		title: "Receptionist",

	},
	{
		id: "milena-stefanovic",
		name: "Milena Stefanović",
		title: "Therapist",

	},
	{
		id: "nevena-rapcan",
		name: "Nevena Rapčan",
		title: "Therapist",

	},
	{
		id: "teodora-petrovic",
		name: "Teodora Petrović",
		title: "Therapist",

	},
	{
		id: "milica-ristic",
		name: "Milica Ristić",
		title: "Therapist"
	},
	{
		id: "jelena-mladenovic",
		name: "Jelena Mladenović",
		title: "Therapist"
	},
	{
		id: "nikola-nedeljkovic",
		name: "Nikola Nedeljković",
		title: "Therapist"
	},
	{
		id: "milica-ilic",
		name: "Milica Ilić",
		title: "Therapist"
	},
	{
		id: "jovana-arsic",
		name: "Jovana Arsić",
		title: "Receptionist"
	},
	{
		id: "kristina-ljubic",
		name: "Kristina Ljubić",
		title: "Therapist"
	}
];
