import * as React from "react";
import { createRef, useEffect } from "react";
import "./ContactPage.css";
import clockImg from "../../assets/img/clock-icon.png";
import questionImg from "../../assets/img/question-mark-icon.png";
import mailImg from "../../assets/img/mail-icon.png";
import contactMap from "../../assets/img/contact-map-2x.png";
import { useLocale } from "../../hooks/useLocale";
import contactJSON from "../../static/contact.json";
import { CircleButton } from "../../components/buttons/CircleButton";
import { ContactListItem } from "./ContactListItem";
import langs from "../../localization/languages/languages";
import { localizeLinkTo } from "../../util/localization";
import routes from "../../localization/routes/routes";
import {backendServerUrl} from "../../globals";

export const ContactPage = (props: any) => {
	const collapsibleRef = createRef<HTMLUListElement>();
	const locale = useLocale();

	useEffect(() => {
		collapsibleRef.current &&
		M.Collapsible.init(collapsibleRef.current, {accordion: true});
		window.scrollTo({top: 0, behavior: "smooth"});
		// eslint-disable-next-line
	}, []);

	return (
		<div id="contact">
			<div className="container">
				<div className="row">
					<div className="row">
						<div className="col s12 m12 l12 circle-button-container">
							<div className="col s12 m12 l4 pt-2 d-flex justify-content-center">
								<CircleButton
									link={localizeLinkTo(`/${routes[locale].reservation}`, locale)}
									size={200}
									img={clockImg}
									caption={
										<span>
											{langs[locale].home_circle_button_text_1_1.toUpperCase()}
                                        </span>
									}
								/>
							</div>
							<div className="col s12 m12 l4 pt-2 d-flex justify-content-center">
								<CircleButton
									link={localizeLinkTo(`/${routes[locale].consultation}`, locale)}
									size={200}
									img={questionImg}
									caption={
										<span>
											{langs[locale].home_circle_button_text_2_1.toUpperCase()}
                                        </span>
									}
								/>
							</div>
							<div className="col s12 m12 l4 pt-2 d-flex justify-content-center">
								<CircleButton
									link={`${backendServerUrl}/purchase`}
									size={200}
									img={mailImg}
									caption={
										<span>
											{langs[locale].home_circle_button_text_3_1.toUpperCase()}
                                        </span>
									}
								/>
							</div>
						</div>
					</div>
					<div className="row contact-content">
						<div className="contact-list col s12 m12 l6">
							<ContactListItem
								title={langs[locale].contact_list_item_title_address_text}
								heading={langs[locale].footer_location_1_text +": "}
								caption={
									langs[locale].contact_list_item_caption_text_1
								}
							/>
							<ContactListItem
								title={""}
								heading={langs[locale].footer_location_2_text +": "}
								caption={
									langs[locale].contact_list_item_caption_text_2
								}
							/>
							<ContactListItem
								title={"E-MAIL"}
								caption={<a className="gold" href="mailto://info@zenmasaza.com">INFO@ZENMASAZA.COM</a>}
							/>
							<ContactListItem
								title={langs[locale].contact_list_item_hours_open}
								caption={"10:00/22:00"}
							/>
							<ContactListItem
								title={langs[locale].contact_list_item_saturday}
								caption={"10:00/20:00"}
							/>
							<ContactListItem
								title={langs[locale].contact_list_item_sunday}
								caption={"12:00/20:00"}
							/>
							<ContactListItem
								title={langs[locale].contact_list_item_telephone}
								caption={<a className="gold" href={"tel:+381-64-04-88-256"}>+381 (0) 64 04 88
									256</a>}
							/>
							{/*<ContactListItem*/}
							{/*	title={"NEWSLETTER"}*/}
							{/*	caption={<Link className={"white-text"} to="/newsletter">Prijavite se za <span className="gold">newsletter</span></Link>}*/}
							{/*/>*/}
						</div>
						<div className="contact-map col s12 m12 l6 p-0">
							<a href={contactJSON.mapURL_1} target={"blank"}>
								<img src={contactMap} alt=""/>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
