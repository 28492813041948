export const faq_treatment: FAQQuestion[] = [
	{
		id: 1,
		question: "Da li postoje pravila kojih bih trebao/la da se pridržavam?",
		answer: "Nastojimo da svojim klijentima pružimo najbolje iskustvo u korišćenju naših usluga. Stoga se pridržavamo izvesnih pravila da bi osigurali harmonično okruženje gde se terapeuti mogu fokusirati na određivanje Vaših potreba.\n Kao znak dobre volje prema drugim klijentima, molimo Vas da u našim prostorijama održite miran nivo razgovora.\n Mobilni telefoni se moraju utišati prilikom ulaska u naše prostorije.",
	}, {
		id: 2,
		question: "Kada da dođem na zakazani termin?",
		answer: "Savetujemo Vam da na svoju zakazanu masažu dođete 5 minuta pre zakazanog termina, što će Vam dati dovoljno vremena za pripremu.",
	}, {
		id: 3,
		question: "Šta ako kasnim na zakazani termin?",
		answer: "Ukoliko ste bili sprečeni da stignete na vreme, uvek ćemo se potruditi da Vam pružimo punu uslugu kada je to moguće. U slučaju vezanih tretmana, trajanje Vaše masaže će biti modifikovano, kako bi se izbeglo odlaganje tretmana ostalim klijentima.",
	}, {
		id: 4,
		question: "Da li mogu da izaberem svog terapeuta?",
		answer: "Da, naravno. Ukoliko preferirate nekog od naših terapeuta, neophodno je da nam to naglasite prilikom rezervacije.",
	}, {
		id: 5,
		question: "Kako da zakažem termin ukoliko imam vaučer?",
		answer: "Prilikom zakazivanja neophodno je da nam date numerički broj vaučera ili šifru vaučera u slučaju online kupovine.\n Otkazivanje tretmana mora biti minimum 24h ranije! Ukoliko niste u mogućnosti da iskoristite svoj vaučer u opsegu u kojem je limitiran, neophodno je da nam najavite, u suprotnom Vaš vaučer postaje nevažeći.",
	}, {
		id: 6,
		question: "Da li smem da se idem na trening posle masaže?",
		answer: "Iako trening nakon tretmana nije kontraindukovan, naša preporuka je da nakon masaže dozvolite svom telu da se odmori i regeneriše, te ostavite trening za naredni dan. Ovo se posebno odnosi na sportske i terapeutske masaže.",
	}, {
		id: 7,
		question: "Da li je terapeutska masaža bolna?",
		answer: "Terapeutska masaža je masaža doziranog pritiska. To znači da će se primeniti onaj pritisak koji će opustiti napetost mišića, a pritom neće naneti dodatnu štetu tkivu. Zbog toga se masaže te vrste rade u kontinuitetu 4-5 zaredom kako bi se zgrčenim mišićima „dalo vremena“ da postepeno uspostave normalan rad.",
	}, {
		id: 8,
		question: "Da li smem da se masiram u trudnoći?",
		answer: "Ukoliko je prenatalnim testovima utvrđeno da je sve u redu sa trudnoćom, apsolutno da! Masaža je dozvoljena i preporučena u svim trimestrima.",
	}, {
		id: 9,
		question: "Da li smem da se masiram u toku menstualnog ciklusa?",
		answer: "Ukoliko se vi osećate prijatno, da. Masaža Vas može dodatno opustiti i umanjiti menstrualne bolove ukoliko ih imate.",
	}, {
		id: 10,
		question: "Koliko često treba da idem na masažu",
		answer: "Ukoliko Vam je cilj da se opusitite uz relaksacionu masažu, optimalno je da na masažu idete jednom mesečno, a ukoliko imate napetost u telu, ili se profesionalno bavite sportom, savetujemo da nas posećujete bar jednom nedeljno.",
	}, {
		id: 11,
		question: "Koji se delovi tela masiraju kada se radi masaža celog tela?",
		answer: "Leđa, vrat, ramena, sedalni deo, noge, stopala, ruke i po želji- lice.",
	}, {
		id: 12,
		question: "Koji su benefiti masaže?",
		answer: "Masaža nam pomaže da se opustimo, umanjimo hroničan bol ukoliko ga imamo, sprečimo nastanak povreda, poboljšamo kvalitet spavanja, ispravimo svoju posturu tela, umanjimo mišićnu napetost, redukujemo stres itd.",
	}, {
		id: 13,
		question: "Da li masaža treba da boli?",
		answer: "Napeto tkivo je senzitivno na pritisak. Opuštanje istog je svakako bolno do nekog stepena, ali taj prag nikako ne bi smeo da se pređe  bol ne sme da postane nepodnošljiva. Stoga uvek savetujemo da se masaže rade u kontinuitetu da se izbegli jaki mišićni spazami.",
	},
];
