import * as React from "react";
import {useEffect} from "react";
import prenatalna from "../../assets/img/courseLevels/prenatalna.jpg";
import vulkansko from "../../assets/img/courseLevels/vulkansko.jpeg";
import anticelulit from "../../assets/img/courseLevels/anticelulit.jpg";
import {SeminarCard} from "./SeminarCard";
import {useLocale} from "../../hooks/useLocale";
import {Redirecter} from "../../components/redirecter/Redirecter";
import routes from "../../localization/routes/routes";
import {Route, Switch} from "react-router-dom";
import {PrenatalWeekendSeminar} from "./weekendSeminars/PrenatalWeekendSeminar";
import {VolcanicWeekendSeminar} from "./weekendSeminars/VolcanicWeekendSeminar";
import {AnticeluliteProgramme} from "./weekendSeminars/AnticeluliteProgramme";

type WeekendSeminarType = {
    title: string;
    titleDate?: string;
    description: string;
    image: any;
    path: string;
    component: (props?: any) => JSX.Element;
}

export const WeekendSeminar = () => {
	const lang = useLocale();

	const getWeekendSeminarPath = (path: string) =>
		`/${lang}/${routes[lang].courses_level_weekend_seminar}/${path}`;

	const weekendSeminars: WeekendSeminarType[] = [
		// {
		// 	title: "OBUKA ZA MADEROTERAPIJU",
		// 	description: "",
		// 	image: maderoterapija,
		// 	buttons: [<Link className="btn bg-dark-gold white-text"
		// 	                to={`/${lang}/${routes[lang].courses_level_weekend_seminar}/maderoterapija`}>
		// 		SAZNAJ VIŠE</Link>],
		// },
		// {
		// 	title: "UVODNI SEMINAR U THAI MASAŽU",
		// 	description: "",
		// 	image: thai,
		// 	buttons: [<Link className="btn bg-dark-gold white-text"
		// 	                to={`/${lang}/${routes[lang].courses_level_weekend_seminar}/thai`}>
		// 		SAZNAJ VIŠE</Link>],
		// },
		// {
		// 	title: "RADIONICA: NAUČITE DA MASIRATE ONE KOJE VOLITE",
		// 	description: "",
		// 	image: radionica,
		// 	imageStyles: {width: "auto", position: "absolute", right: "-20px"} as CSSProperties,
		// 	buttons: [<Link className="btn bg-dark-gold white-text"
		// 					to={`/${lang}/${routes[lang].courses_level_weekend_seminar}/radionica`}>
		// 		SAZNAJ VIŠE</Link>],
		// },
		{
			title: "PRENATALNA MASAŽA - MASAŽA TRUDNICA",
			description: "",
            path: getWeekendSeminarPath("prenatal"),
			component: PrenatalWeekendSeminar,
			image: prenatalna,
		},
		{
			title: "MASAŽA VULKANSKIM KAMENJEM",
            path: getWeekendSeminarPath("vulkansko"),
			description: "",
			component: VolcanicWeekendSeminar,
			image: vulkansko,
		},
		{
			title: "ULTIMATIVNI ANTICELULIT PROGRAM",
			description: "",
            path: getWeekendSeminarPath("anticelulit"),
			component: AnticeluliteProgramme,
			image: anticelulit,
		},
	];

	useEffect(() => {
		// console.log(props)
		window.scrollTo({top: 0});
		// eslint-disable-next-line
	}, []);

	if (lang === "en")
		return <Redirecter to={routes[lang].home}/>;

	return (
		<div className="course-description">
			<div className="container">
				<Switch>
                    {weekendSeminars.map(props => (
                        <Route key={props.path} path={props.path} exact>
                            <props.component/>
                        </Route>))}
					<Route>
						{weekendSeminars.map(props => (<SeminarCard {...props}/>))}
					</Route>
				</Switch>
			</div>
		</div>
	);
};
