import { useLocation } from "react-router-dom";
import { defaultLocale, locales } from "../localization/locales";

export const useLocale = () => {
	const {pathname} = useLocation();
	let lang = pathname.match(/\/[a-z]{2}\//) ? pathname.match(/\/([a-z]{2})\//)![1] : defaultLocale;
	if (locales.indexOf(lang) === -1) {
		lang = defaultLocale;
	}

	return lang;
};
