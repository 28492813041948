import * as React from "react";
import { useEffect, useState } from "react";
import { Link, RouteComponentProps, useLocation } from "react-router-dom";
import { useLocale } from "../../hooks/useLocale";
import purchaseServices from "../../services/purchaseServices";
import { localizeLinkTo } from "../../util/localization";

type PurchaseFailureProps = {};
export const PurchaseFailure = (props: PurchaseFailureProps & RouteComponentProps) => {
	const lang = useLocale();

	const [errorText, setErrorText] = useState("Greška");
	const [treatment, setTreatment] = useState({slug: ""});

	const location = useLocation();
	const query = new URLSearchParams(location.search);
	const locale = useLocale();

	useEffect(() => {
		const orderId = query.get("orderId");
		if (orderId)
			purchaseServices.verify(orderId, locale).catch(err => {
				const data = err.response.data;
				console.log(err.response);
				if (typeof data === "object") {
					if (data.errors && data.errors instanceof Array) {
						setErrorText(data.errors[0]);
					}
				} else {
					setErrorText(data);
				}

				if (err.response.data["treatment_slug"])
					setTreatment({
						slug: err.response.data["treatment_slug"],
					});
			});
		// eslint-disable-next-line
	}, []);

	return (
		<div className="container purchase-container">
			<div className="row">
				<div className="col s12">
					<h4 className="gold">{errorText}</h4>
					<p className="white-text">Poštovani/a,</p>
					<p className="white-text">Na žalost, kupovina vaučera nije uspela.</p>
					<p className="white-text">Molim Vas da probate&nbsp;
						<Link className="gold"
						      to={localizeLinkTo(`/purchase?treatment=${treatment["slug"]}`, lang)}>ponovo</Link>,
						proverite date informacije ili stanje sa Vašom bankom.</p>
					<p className="white-text">Hvala vam na strpljenju,<br/>Vaš Zen.</p>
				</div>
			</div>
		</div>
	);
};
