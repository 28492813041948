import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../../assets/img/zen-logo.png";
import en_flagIcon from "../../assets/img/flags/zastava-eng.png";
import rs_flagIcon from "../../assets/img/flags/zastava-srb.png";
import { useLocale } from "../../hooks/useLocale";
import langs from "../../localization/languages/languages";
import { locales } from "../../localization/locales";
import routes from "../../localization/routes/routes";
import categoryServices from "../../services/categoryServices";
import treatmentServices from "../../services/treatmentServices";
import { localizeLinkTo } from "../../util/localization";
import { getPurchaseParamsFromQuery } from "../../util/util";
import "./NavigationBar.css";
import {academyUrl} from "../../globals";
import * as M from "materialize-css";

export const NavigationBar = () => {
	const [navClass, setNavClass] = useState<string>("scrolled-nav-reset");
	const navRef = useRef<HTMLElement>(null);
	const navMobileRef = useRef<HTMLUListElement>(null);
	const courseDropdownRef = useRef<HTMLAnchorElement>(null);
	const navInstance = useRef<M.Sidenav>();
	const locale = useLocale();
	const [treatments, setTreatments] = useState<Record<string, Treatment[]>>({en: [], rs: []});

	const setupNavbar = () => {

		window.addEventListener("scroll", () => {
			const scroll = window.scrollY;
			if (scroll > navRef.current!.offsetHeight + 10) {
				setNavClass("scrolled-nav");
			} else if (scroll === 0) {
				setNavClass("scrolled-nav-default");
			}
		});
		if (navMobileRef.current) {
			navInstance.current = M.Sidenav.init(navMobileRef.current, {draggable: true, edge: "right"});
		}
	};

	const getTreatments = async () => {
		const newTreatData: Record<string, Treatment[]> = {};
		const newCategData: Record<string, Category[]> = {};
		for (const lang of locales) {
			newTreatData[lang] = await treatmentServices.getAll(lang);
			newCategData[lang] = await categoryServices.getAll(lang);
		}
		setTreatments(newTreatData);
	};

	const changeLang = (ev: React.MouseEvent) => {
		ev.preventDefault();
		const newLang = locales[(locales.indexOf(locale) + 1) % locales.length];
		let currPage = window.location.href
			.split(/\/[a-z]{2}\//)[1]
			.split("/")[0];

		if (currPage) {
			if (currPage.startsWith(routes[locale].voucher)) {
				const params = currPage.split("?");
				currPage = params[0];

				const purchaseParams = getPurchaseParamsFromQuery(params[1]);

				let treatIndex = -1;
				for (let i = 0; i < treatments[locale].length; i++) {
					let t = treatments[locale][i];
					if (purchaseParams["treatment"] !== "" && t.slug === purchaseParams["treatment"]) {
						treatIndex = i;
					}
				}

				if (treatIndex !== -1) {
					window.location.href = `${window.location.protocol}//${window.location.host}/#/${newLang}/${routes[newLang].voucher}?treatment=${treatments[newLang][treatIndex].id}&price=${purchaseParams["price"]}`;
					window.location.reload();
					return;
				}
			}


			const idx = Object.values(routes[locale]).indexOf(currPage);
			if (idx !== -1) {
				window.location.href = `${window.location.protocol}//${window.location.host}/#/${newLang}/${Object.values(routes[newLang])[idx]}`;
				window.location.reload();
				return;
			}
		}

		// change to home if lookup fails
		window.location.href = `${window.location.protocol}//${window.location.host}/#/${newLang}/${routes[newLang].home}`;
		window.location.reload();
	};

	const localized_icon: any = {
		en: en_flagIcon,
		rs: rs_flagIcon,
	};

	//@formatter:off
	const menuItems = [
		<li key={0}><NavLink className="sidenav-close" activeClassName="active" exact to={localizeLinkTo(routes[locale].home, locale)}>{langs[locale].navigation_home}</NavLink></li>,
		<li key={1}><NavLink className="sidenav-close" activeClassName="active" to={localizeLinkTo(routes[locale].treatments, locale)}>{langs[locale].navigation_offer}</NavLink></li>,
		<li key={2}><a target="_blank" rel="noreferrer noopener" href={academyUrl}>{langs[locale].navigation_academy}</a></li>,
		<li key={2}><a ref={courseDropdownRef} className="dropdown-trigger" data-target="courses-dropdown" onClick={ev => ev.preventDefault()}>{langs[locale].navigation_courses}</a></li>,
		<li key={3}><a className="sidenav-close" href={`${window.location.protocol}//${window.location.hostname}/` + routes[locale].blog}>{langs[locale].navigation_blog}</a></li>,
		<li key={4}><NavLink className="sidenav-close" to={localizeLinkTo(routes[locale].contact, locale)}>{langs[locale].navigation_contact}</NavLink></li>,
		// eslint-disable-next-line jsx-a11y/anchor-is-valid
		<li key={5}><a className="sidenav-close lang-switcher" onClick={changeLang} href={"#"}><img alt={locale} src={localized_icon[locale]}/></a>
		</li>,
	];

	const mobileMenuItems = [
		<li key={0}><NavLink className="sidenav-close" activeClassName="active" exact to={localizeLinkTo(routes[locale].home, locale)}>{langs[locale].navigation_home}</NavLink></li>,
		<li key={1}><NavLink className="sidenav-close" activeClassName="active" to={localizeLinkTo(routes[locale].treatments, locale)}>{langs[locale].navigation_offer}</NavLink></li>,
		<li key={3}><a target="_blank" rel="noreferrer noopener" href={academyUrl}>{langs[locale].navigation_academy}</a></li>,
		<li className="divider" tabIndex={-1}/>,
		<li className="disabled" key={2}><a className="dropdown-trigger gold" onClick={ev => ev.preventDefault()}>{langs[locale].navigation_courses}</a></li>,
		<li>
			<Link className="sidenav-close" to={localizeLinkTo(routes[locale].courses_level1, locale)}>{langs[locale].navigation_courses_level1}</Link>
		</li>,
		<li>
			<Link className="sidenav-close" to={localizeLinkTo(routes[locale].courses_level2, locale)}>{langs[locale].navigation_courses_level2}</Link>
		</li>,
		// <li>
		// 	<Link className="sidenav-close" to={localizeLinkTo(routes[locale].courses_level_weekend_seminar, locale)}>{langs[locale].navigation_courses_seminar}</Link>
		// </li>,
		<li className="divider" tabIndex={-1}/>,
		<li key={8}><a className="sidenav-close" href={`${window.location.protocol}//${window.location.hostname}/` + routes[locale].blog}>{langs[locale].navigation_blog}</a></li>,
		<li key={9}><NavLink className="sidenav-close" to={localizeLinkTo(routes[locale].contact, locale)}>{langs[locale].navigation_contact}</NavLink></li>,
		// eslint-disable-next-line jsx-a11y/anchor-is-valid
		<li key={10}><a className="sidenav-close lang-switcher" onClick={changeLang} href={"#"}><img alt={locale} src={localized_icon[locale]}/></a>
		</li>,
	];
	//@formatter:on

	useEffect(() => {
		if (courseDropdownRef.current) {
			M.Dropdown.init(courseDropdownRef.current, {
				constrainWidth: false,
				coverTrigger: false,
				alignment: "left",
				hover: true,
			});
		}
		// eslint-disable-next-line
	}, [courseDropdownRef.current]);

	useEffect(() => {
		window.scrollTo(0, 0);
		setupNavbar();
		getTreatments();
		// eslint-disable-next-line
	}, []);

	return (
		<nav className={`${navClass}`} ref={navRef}>
			<div className="nav-wrapper container">
				<Link to={localizeLinkTo(routes[locale].home, locale)}>
					<img className="brand-logo left" src={logo} alt="Zen Studio Logo"/>
				</Link>
				{/*eslint-disable-next-line*/}
				<a href="#" data-target="mobile-nav" className="sidenav-trigger right"><i
					className="material-icons">menu</i></a>
				<ul className="right hide-on-med-and-down">
					{menuItems.map(item => item)}
				</ul>
				<ul id="courses-dropdown" className="dropdown-content">
					<li>
						<Link to={localizeLinkTo(routes[locale].courses_level1, locale)}>{langs[locale].navigation_courses_level1}</Link>
					</li>
					<li>
						<Link to={localizeLinkTo(routes[locale].courses_level2, locale)}>{langs[locale].navigation_courses_level2}</Link>
					</li>
					{/*<li>*/}
					{/*	<Link to={localizeLinkTo(routes[locale].courses_level_weekend_seminar, locale)}>{langs[locale].navigation_courses_seminar}</Link>*/}
					{/*</li>*/}
				</ul>
			</div>
			<ul ref={navMobileRef} id="mobile-nav" className="sidenav">
				{mobileMenuItems.map(item => item)}
			</ul>
		</nav>
	);
};
