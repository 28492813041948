import * as React from "react";

type ServiceNotAvailableProps = {
	heading?: string;
	heading2?: string;
};
export const ServiceNotAvailable = (props: ServiceNotAvailableProps) => {
	return (
		<div className="border-dark-gold p-4">
			<h3 className="gold text-left">{props.heading ? props.heading : ""}</h3>
			<h5 className="dark-gold text-left">{props.heading2 ? props.heading2 : ""}</h5>
		</div>
	);
};
