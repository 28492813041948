import * as React from "react";
import { createRef, useEffect } from "react";
import { ConsultationForm } from "../../components/forms/ConsultationForm";
import { PrenatalConsultationForm } from "../../components/forms/PrenatalConsultationForm";
import "./ConsultationFormPage.css";
import { defaultLocale } from "../../localization/locales";
import langs from "../../localization/languages/languages";
import { withNewlines } from "../../format/format";
import { backendServerUrl } from "../../globals";

type ConsultationFormPageProps = {};
export const ConsultationFormPage = (props: ConsultationFormPageProps & any) => {
	const tabsRef = createRef<HTMLUListElement>();
	const {lang = defaultLocale} = props.match?.params;

	useEffect(() => {
		window.scrollTo({top: 0, behavior: "smooth"});
		if (tabsRef.current) M.Tabs.init(tabsRef.current, {});
		// eslint-disable-next-line
	}, []);

	return (
		<div id="consultation-form-page" className="container">
			<div className="row">
				<div className="col s12 m12 l12 white-text">
					<p>{langs[lang].forms_consultation_regular_paragraph_1}</p>
					<p>{langs[lang].forms_consultation_regular_paragraph_2}</p>
					<p>{langs[lang].forms_consultation_regular_paragraph_3}</p>
					<p>{withNewlines(langs[lang].forms_consultation_regular_paragraph_4)}</p>
				</div>
			</div>
			<div className="row">
				<div className="col s12">
					<ul ref={tabsRef} className="tabs">
						<li className="tab col s6">
							<a href="#consultation">{langs[lang].forms_consultation_regular_title}</a>
						</li>
						<li className="tab col s6">
							<a href="#prenatal">{langs[lang].forms_consultation_prenatal_title}</a>
						</li>
					</ul>
				</div>
				<div id="consultation" className="col s12">
					<ConsultationForm action={backendServerUrl + "/forms/consultation?locale=" + lang} isRequired={false} lang={lang}/>
				</div>
				<div id="prenatal" className="col s12">
					<PrenatalConsultationForm action={backendServerUrl + "/forms/prenatal?locale=" + lang} isRequired={false} lang={lang}/>
				</div>
			</div>
		</div>
	);
};
