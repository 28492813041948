import * as React from "react";
import { Link } from "react-router-dom";
import { localizeLinkTo } from "../../util/localization";
import routes from "../../localization/routes/routes";
import { useLocale } from "../../hooks/useLocale";
import { backendServerUrl } from "../../globals";

type TreatmentCategoryCardProps = {
	category: Category;
	cardSize?: number;
};
export const TreatmentCategoryCard = ({category: {name, slug, imageUrl}, cardSize = 4}: TreatmentCategoryCardProps) => {
	const lang = useLocale();

	return (
		<div className={`col s12 m12 l${cardSize} category-card`}>
			<Link to={localizeLinkTo(`/${routes[lang].treatments}/` + slug, lang)}>
				<div className="course-element-avatar" style={{backgroundImage: `url(${backendServerUrl + (imageUrl ? imageUrl : "/img/round-placeholder.png")})`}}>
					<div className="transparent-filter">
						<h5>{name}</h5>
					</div>
				</div>
			</Link>
		</div>
	);
};
