export const faq_course = [
	{
		id: 1,
		question: "Kako da se prijavim za kurs?",
		answer: "Prijave se vrše na recepciji Centra za masažu Zen, na adresi: Milorada Veljkovića Špaje 14 (prvi sprat) u Nišu.\n Radno vreme recepcije je radnim danima od 10- 22 h, subotom od 10-20 h i nedeljom 12-20 časova.\n Za prijavu je potrebno priložiti očitanu ličnu kartu i avansnu uplatu u minimalnom iznosu od 5.000 RSD.\n Ukoliko ste maloletni, umesto lične karte možete priložiti bilo koji dokument koji prikazuje jedinstveni matični broj. ",
	}, {
		id: 2,
		question: "Da li postoje starosne granice da bi se kurs pohađao?",
		answer: "Da biste se prijavili za kurs, neophodno je da budete stariji od 16 godina.",
	}, {
		id: 3,
		question: "Da li smem da izostanem sa predavanja?",
		answer: "Minimalna prisutnost na kursu je 80% od ukupnog broja predavanja.\n Ukoliko ste hitnim slučajem sprečeni, propušteni časovi se moraju nadoknaditi ukoliko želite da ostvarite uslov za završno polaganje.\n Cena dodatnih časova će zavisiti od broja sati koje treba nadoknaditi.",
	}, {
		id: 4,
		question: "Da li moram da imam predznanje da bih započeo/la kurs?",
		answer: "Prethodno znanje nije potrebno za osnovni kurs relaksacione masaže (početni novo), dok je uslov za upis na nepredni kurs terapeutske masaže, uspešno položeni Nivo 1.\n Za vikend kurseve podrazumeva se znanje o masaži stečeno na Nivou 1 i Nivou 2. ",
	}, {
		id: 5,
		question: "Da li mogu da se zaposlim negde nakon kursa?",
		answer: "Kako je masaža vrlo praktična delatnost, nakon kursa, neophodno je da posvetite vreme vežbanju. Što se tome više predate, veće su šanse za Vaše zaposlenje.\n U zavisnosti od nivoa znanja koje posedujete, posao možete potražiti na raznim mestima, kao što su: spa centri, klinke za fizioterapiju, saloni za masažu, kozmetički saloni, hoteli, itd.\n Nakon završenog kursa, naša škola nudi mogućnost zaposlenja najboljim polaznicima. ",
	}, {
		id: 6,
		question: "Da li moram da imam određene fizičke predispozicije da bih bio profesionalni terapeut masaže?",
		answer: "Nikakve posebne fizičke predispozicije nisu neophodne.\n Poželjno je da budete u dobroj fizičkoj formi, vodite zdrav način života i izbegavate sve ono što Vam smeta da svoj nivo energije održite na optimalnom nivou. ",
	}, {
		id: 7,
		question: "Da li nakon kursa mogu da potražim posao u inostranstvu?",
		answer: "Ukoliko želite da nakon kursa potražite posao van granica naše zemlje, potrebno je da uradite dodatni test koji će još jednom verifikovati Vaše znanje.\n Nakon polaganja Vam se izdaje sertifikat koji je priznat u većini zemalja Evrope.\n Cena dodatnog testiranja je 9.000 RSD. ",
	}, {
		id: 8,
		question: "Da li omogućavate plaćanje u ratama i da li postoji popust za rane prijave?",
		answer: "Da!\n Za uplaćeni avans 2 meseca pre početka kursa ostvarujete popust od čak 1.500 RSD po nivou.\n Uplatu kursa možete vršiti kako Vama odgovara, sa jedinim uslovom da ceo iznos bude uplaćen 7 dana pre prvog predavanja.\n Ukoliko želite da pohađate nekoliko Zen kurseva odjednom, pogledajte naše specijalne popuste ispod. ",
	}, {
		id: 9,
		question: "Završio/la sam kurs u drugoj školi imam iskustvo u praktikovanju masaže. Da li mogu da se prijavim za upis na NIVO 2?",
		answer: "Možete, ali je neophodno da položite pismeni i praktični test kod nas kako bismo se uverili da ispunjavate osnovne kriterijume za upis.\n Testiranje znanja se naplaćuje 3.000 RSD, a literatura za učenje 1.500 RSD (opciono).\n Ukoliko ste početni nivo završili u našoj školi, nije potrebno da radite testiranje jer je ono već bilo obuhvaćeno obukom kroz koju ste prošli. ",
	}, {
		id: 10,
		question: "Da li postoje preduslovi za upis na vikend kurs specijalizacije?",
		answer: "Preduslov za pohađanje vikend kursa specijalizacije je sertifikat o uspešno završenom bar početnom kursu masaže u bilo kojoj školi. ",
	}, {
		id: 11,
		question: "Koje specijalne popuste nudite ukoliko želim da pohađam više Zen kurseva odjednom?",
		answer: "Onima koji žele da se posvete profesionalnom usavršavanju u oblasti masaže, nudimo posebne pogodnosti i popuste. Ove ponude važe se za prijave do 1.4.2020 sa avansom od 10.000 RSD. \nPonuda 1 \nNivo 1 (Uvod i osnova relaksacione masaže) + Nivo 2(Uvod i osnova terapeutske masaže) \nPuna cena: 44.000 RSD \nCena sa popustom: 41.000 RSD \nPonuda 2 \nNivo 1 (Uvod i osnova relaksacione masaže) + Nivo 2(Uvod i osnova terapeutske masaže) + 1 Vikend Kurs po izboru \nPuna cena: 54.000 RSD \nCena sa popustom: 49.000 RSD \nPonuda 3 \nNivo 1 (Uvod i osnova relaksacione masaže) + Nivo 2(Uvod i osnova terapeutske masaže) + 2 Vikend Kursa \nPuna cena: 64.000 RSD \nCena sa popustom: 58.000 RSD \nPonuda 4 \n2 Vikend Kursa (prenatalna masaža + masaža vulkanskim kamenjem) \nPuna cena: 20.000 RSD \nCena sa popustom: 18.000 RSD ",
	},
];
