import * as React from "react";

export const AnticeluliteProgramme = () => {

    return (
        <div>
            <h3>
                Ultimativni anticelulit program
            </h3>
            <h6>maderoterapija - anticelulit masaža - vakuum čašice</h6>
            <p>
                <span className="bold-text">Datum održavanja kursa:</span> 27. i 28. maj 17-21h<br/>
            </p>
            <h4>
                Šta podrazumeva obuka?
            </h4>
            <ul>
                <li>
                    <b>
                        Uvodno teorijsko predavanje o nastanku celulitnog tkiva:
                    </b>
                    <br/>
                    U uvodnom delu kursa detaljno ćemo objasniti šta uzrokuje i
                    kako nastaje celulit, kao i zbog čega su žene podložne
                    nastanku celulita.
                </li>
                <li>
                    <b>
                        Teorijsko objašnjenje delovanja masaže na smanjenje
                        obima celulita:
                    </b>
                    <br/>
                    U teorijskom delu objasnićemo na koji način uz pomoć
                    anticelulit masaže manipulišemo kožom i masnim naslagama
                    ispod kože.
                </li>
                <li><b>Obuka za maderoterapiju:</b><br/> Detaljni opis,
                    upoznavanje i demonstracija rada sa svim oblicima drvenih
                    masažera.
                </li>
                <li><b>Tehnika ručne anticelulit masaže:</b><br/> U ovom delu
                    obuke naučićete kako da praktično primenjujete tehnike
                    anticelulit masaže.
                </li>
                <li><b>Tehnika korišćenja vakum čašica:</b><br/> Obuka za
                    korišćenje vakum čašica kod tretiranja određenih tipova
                    celulita.
                </li>
                <li><b>Praktično vežbanje na modelu:</b><br/> Primena teorijskog
                    znanja na realnim klijentima.
                </li>
            </ul>
            <h4>
                Cena kursa
            </h4>
            <p>
                <ul>
                    <li>
                        200e.
                    </li>
                    <li>
                        Za prijave do 14. maja cena 150e.
                    </li>
                </ul>
            </p>
            <h4>
                Šta je uključeno u cenu?
            </h4>
            <ul>
                <li>Skripta za teorijski deo obuke</li>
                <li>Sertifikat izdat od strane Centra za masažu i edukaciju
                    Zen
                </li>
            </ul>
            <h4>
                Kako se prijaviti za kurs?
            </h4>
            <p>
                Prijavu i uplatu za kurs možete izvršiti na adresi Milorada
                Veljkovića Špaje broj 14 (na prvom spratu) svakog radnog dana od
                10-22h, subotom 10-20h i nedeljom 12-20h.
            </p>
            <h4>
                Zašto izabrati Centar za edukaciju zen?
            </h4>
            <ul>
                <li>Centar za edukaciju Zen ima 5+ godina iskustva u obučavanju
                    polaznika.
                </li>
                <li>Naši edukatori imaju 10+ godina iskustva u pružanju svih
                    vrsta masaža u spa centrima u zemlji i inostranstvu, kao i
                    5+ godina u edukaciji.
                </li>
                <li>Obučili smo više od 100 polaznika koji danas uspešno rade u
                    zemlji i inostranstvu ili su otvorili vlastite salone
                    masaže.
                </li>
                <li>Naši kursevi su sveobuhvatni u teoriji i u praksi, tako da
                    ste nakon završene obuke potpuno spremni za samostalni rad.
                </li>
                <li>Prostor u kome se obavlja edukacija u potpunosti je
                    opremljen najsavremenijom opremom.
                </li>
                <li>Volimo rad u malim grupama, jer se tako posvećujemo svakom
                    polazniku.
                </li>
                <li>Praktični deo kursa se izvodi na najmanje 2 različita
                    modela.
                </li>
                <li>Nakon završene obuke imate našu podršku ukoliko se u radu
                    susretnete sa nedoumicama.
                </li>
            </ul>
            <h4>
                Detaljni opis programa:
            </h4>
            <h4>
                Teorijski deo:
            </h4>
            <ul>
                <li>Uvodno predavanje o građi i strukturi kože.</li>
                <li>Teorijski deo o celulitu: vrste celulita, stadijumi
                    celulita, uzroci nastanka i mesta pojavljivanja celulita
                </li>
                <li>Značaj limfne drenaže</li>
                <li>Tehnika ručne anticelulit masaže (teorijski deo i tehnike
                    izvođenja)
                </li>
                <li>Maderoterapija (upoznavanje sa vrstama drvenih masažera i
                    njihovom primenom, tehnike izvođenja na različitim delovima
                    tela, indikacije i kontraindikacije, procena efekata i
                    doziranja, dezinfekcija i održavanje opreme).
                </li>
                <li>Upoznavanje sa tehnikama korišćenje vakum čašica kod
                    tretiranja celulita.
                </li>
            </ul>
            <h4>
                Praktični deo:
            </h4>
            <ul>
                <li>Procena stadijuma celulita na modelu</li>
                <li>Uzimanje mera i procena obima na modelu</li>
                <li>Tehnika stimulacije limfnog sistema na modelu</li>
                <li>Prikaz tehnike izvođenja ručne anticelulit masaže na
                    modelu
                </li>
                <li>Prikaz tehnike izvođenja maderoterapije na modelu</li>
                <li>Tehnika korišćenja vakum čašica na modelu</li>
                <li>Prikaz tehnike izvođenja ručne masaže i maderoterapije na
                    Vama, kako biste osetili dejstvo svake oklagije
                </li>
                <li>Primena stečnog praktičnog znanja na edukatoru, kako biste
                    dobili tačne povratne informacije kako ste savladali tehnike
                    i kako da unapredite stečeno znanje da biste bili potpuno
                    spremni za samostalni rad.
                </li>
            </ul>
        </div>);
};
