import * as React from "react";

type ContactListItemProps = {
	title: any;
	caption: any;
	child?: any;
	heading?:any;
};
export const ContactListItem = (props: ContactListItemProps) => {
	return (
		<div className="row mb-1">
			<div className="col s12">
				<h6 className="gold mt-2 mb-0">{props.title}</h6>
				<p className="white-text">{props.heading}</p>
				<p className="white-text mt-1 mb-0">{props.caption}</p>
				{props.child ?
					<div className="row mb-1">
						<div className="pl-3">
							{props.child}
						</div>
					</div> : ""}
			</div>
		</div>
	);
};
