import * as React from "react";
import langs from "../../localization/languages/languages";
import { parseUrls } from "../../util/parsing";
import { useLocale } from "../../hooks/useLocale";

type AboutEducatorProps = {
	name: string;
	image: any;
	experience: string[];
}
export const AboutEducator = (props: AboutEducatorProps) => {
	const lang = useLocale();


	return <div className="about-educator">
			<h4>{props.name}</h4>
			<div className="row bottom-line-gold">
				<div className="col s12 m12 l3">
					<div style={{backgroundImage: `url(${props.image})`}} className="educator-image"/>
				</div>
				<div className="col s12 m12 l9">
					<h5>{langs[lang].course_educators_educator_certificates_title}</h5>
					{props.experience.map(exp => (<p key={exp}>{parseUrls(exp, "gold")}</p>))}
				</div>
			</div>
		</div>;
};
