import { LocaleStrings } from "../LocaleStrings";
import { employees } from "./employees/en";
import { testimonials } from "./testimonials/en";
import { faq } from "./faq/faq";
import { level1Price, level1DurationEn, level2Price, level2DurationEn, level3DurationEn } from "../../globals";
import employeeImages from "../../static/employeeImages";

export const en: LocaleStrings = {
	navigation_home: "HOME",
	navigation_offer: "OFFER",
	navigation_academy: "ACADEMY",
	navigation_courses: "EDUCATION",
	navigation_courses_level1: "LEVEL 1",
	navigation_courses_level2: "LEVEL 2",
	navigation_courses_seminar: "WEEKEND SEMINARS",
	navigation_blog: "BLOG",
	navigation_contact: "CONTACT",
	home_header_slide_button_purchase: "PURCHASE VOUCHER",
	home_header_slide_button_purchase_for_one: "PURCHASE VOUCHER",
	home_header_slide_button_purchase_for_two: "PURCHASE FOR TWO",
	home_header_slide_button_schedule: "SCHEDULE APPOINTMENT",
	home_header_slide_button_learn_more: "LEARN MORE",
	home_header_slide_button_view_offer: "VIEW OFFER",
	about_image_title_prop: "Zen is for me",
	about_group_title: "Our team",

	home_about_heading_1: "About us",
	home_about_text: [
		"Experience ultimate relaxation in the heart of the city!  In calming and soothing environment of Zen Massage Center, a massage is not just a service, but an expression of our commitment to your health and well-being.",
		"Our expert therapists are here to guide you through the magical world of massage, tailoring treatments to your individual needs and helping you forget about everyday stress.",
		"With the perfect combination of fragrant massage oils and expert touch, you will achieve harmony and balance of mind, spirit, and body.",
		"The Zen Massage Center is dedicated to applying both modern and ancient massage techniques, drawing inspiration from the West and the East. Here, you can experience the best of both worlds and enjoy in unforgettable relaxation and revitalization.	",
	],
	home_about_button_text_1: "Our team",
	home_about_button_text_2: "Zen Career",
	home_circle_button_text_1_1: "Schedule\nAppointment",
	home_circle_button_text_2_1: "Consultation",
	home_circle_button_text_3_1: "Purchase\nvoucher ",
	footer_address_text_1: "MILORADA VELJKOVIĆA ŠPAJE 14 I FLOOR 18000 NIŠ SRBIJA",
	footer_address_text_2: "SEDMOG JULA 25a/12 II FLOOR 18000 NIŠ SRBIJA",
	footer_location_1_text: "Location 1 (info and vouchers)",
	footer_location_2_text: "Location 2 (scheduled treatments)",
	footer_reservation_button: "Schedule appointment",
	footer_career_button: "Zen Career",
	footer_faq_text: "FAQ",
	footer_developed: "Developed and designed by: ",

	contact_list_item_title_address_text: "ADDRESSES",
	contact_list_item_caption_location_text: "Location",
	contact_list_item_caption_text_1: "MILORADA VELJKOVIĆA ŠPAJE 14 I SPRAT 18000 NIŠ SRBIJA",
	contact_list_item_caption_text_2: "SEDMOG JULA 25a/12 II FLOOR 18000 NIŠ SRBIJA",
	contact_list_item_title_text_2: "ADDRESS",
	contact_list_item_hours_open: "WORKING HOURS",
	contact_list_item_saturday: "SATURDAY",
	contact_list_item_sunday: "SUNDAY",
	contact_list_item_telephone: "CONTACT PHONE",
	faq_page_heading_text: "FAQ",
	faq_page_list_title_for_clients: "FOR CLIENTS",
	faq_page_list_title_for_course_goers: "FOR STUDENTS",

	boss: {
		id: "kristina-jovcic",
		name: "Kristina Jovčić",
		title: "Founder, therapist and educator",
	},
	employees: employees,
	titles: {
		"OWNER": "Founder",
		"EDUCATOR": "Educator",
		"MANAGER": "Manager",
		"BEAUTICIAN": "Beautician",
		"RECEPTIONIST": "Receptionist",
		"THERAPIST": "Therapist",
	},

	// categories: categories,
	testimonials: testimonials,
	// treatments: treatments,
	treatments_not_available: "*This arrangement cannot be purchased online.",
	treatments_duration_price: "DURATION/PRICE(IN RSD)",
	faq_course: faq.course.en,
	faq_treatment: faq.treatment.en,

	course_lvl_1_card_title: "Level 1",
	course_lvl_1_card_date: level1DurationEn,
	course_lvl_1_card_text: "Designed for those with no prior knowledge about massage, who believe it would be a perfect hobby or profession - this is the right choice for you.",
	course_lvl_1_card_btn: "See More",
	course_lvl_1_title_text: "Introduction and basic technique of relaxation massage",
	course_lvl_1_for_who_header_txt: "The ideal student?",
	course_lvl_1_for_who_paragraph_1: "This course is designed for those wishing to do massage as a hobby or those who need basic knowledge in order to take their first steps in this profession.",
	course_lvl_1_for_who_paragraph_2: "It will allow you to understand what relaxation massage, known throughout the world, truly means.",
	course_lvl_1_for_who_paragraph_3: "You will learn basic massage techniques and how to apply them, contraindications and indications for conducting this type of massage, allowing you to safely and surely practice it after the course.",
	course_lvl_1_for_who_paragraph_4: "The course consists of a theoretical and practical segment, where you will have the chance to practice the massage on real clients, while being supervised by the educator, focused on helping you reach your maximum potential and getting you started on the journey to becoming a professional therapist.",
	course_lvl_1_goal_header_txt: "The aim",
	course_lvl_1_goal_paragraph: "Developing confidence in doing relaxation massages, recognising massage techniques that should be applied in a given situation and practical application of theoretical knowledge.",
	course_lvl_1_course_duration_header_txt: "The duration",
	course_lvl_1_course_duration_paragraph_1: "One month.",
	// course_lvl_1_course_duration_paragraph_1: "Course start: 14.09.2020",
	// course_lvl_1_course_duration_paragraph_2: "Course end: 10.10.2020.",
	course_lvl_1_schedule_header_txt: "Class schedule",
	course_lvl_1_schedule_paragraph_1: "Monday, Wednesday and Friday 17-20.30h.",
	// course_lvl_1_schedule_paragraph_2: "In case the students are employed full-time, it is possible to hold classes in the morning.",
	course_lvl_1_examination_header_txt: "The exam",
	course_lvl_1_examination_paragraph_1: "At the end of the course, there is a theoretical and practical exam.",
	// course_lvl_1_examination_paragraph_2: "The exam consists of a theory exam and a practical knowledge demonstration.",
	course_lvl_1_price_header_txt: "The price",
	course_lvl_1_price_paragraph_1: level1Price,
	course_lvl_1_price_paragraph_2: "If you sign up for both courses (LEVEL 1 & LEVEL 2), you earn a 5000 RSD discount.",
	course_lvl_1_included_header_txt: "What is included in the price?",
	course_lvl_1_included_course_lvl_1_card_list: ["-Classes", "-Practical lessons", "-Materials for practical lessons", "-Class materials", "-Centre for massage and education Zen Certification", ""],
	course_lvl_1_not_included_header_txt: "What is not included in the price?",
	course_lvl_1_not_included_paragraph_1: [
		"- People’s University in Nis Certification (optional)",
		"- Additional exam (2000RSD per test)"
		],
	course_lvl_1_why_us_header_txt: "Why choose our school?",
	course_lvl_1_why_us_course_lvl_1_card_list: ["- Lessons are held in a modern, air-conditioned classroom", "- We use modern equipment ", "- We have years of experience in massage education", "- We hire our best students", "- Learning takes place in small groups", "- We are fully focused on each individual student", "- Through practical application of what you have learned, you will gain confidence"],
	course_lvl_1_note_header_txt: "Note",
	course_lvl_1_note_paragraph_1: "We will not make up for any missed classes.",

	course_lvl_2_card_title: "Level 2",
	course_lvl_2_card_date: level2DurationEn,
	course_lvl_2_card_text: "Designed for those who have previously completed Level 1, hold knowledge about relaxation massage and seek further education on remedial and sports treatments.",
	course_lvl_2_card_btn: "See More",
	course_lvl_2_title_text: "Introduction and technique of remedial massage",
	course_lvl_2_for_who_header_txt: "The ideal student?",
	course_lvl_2_for_who_paragraph_1: "This course is designed for everyone, who has the necessary knowledge in the field of relaxation massage, and is willing to further improve their skill. The next step is learning the technique of remedial massage.",
	course_lvl_2_for_who_paragraph_2: "Remedial massage is vastly different to relaxation massage. Its aim is different, as is the application. It serves to remove muscle tension, improve wrist mobility, blood circulation, etc.",
	course_lvl_2_for_who_paragraph_3: "In order to become successful therapists, it is necessary to have knowledge of the techniques of remedial massage. This is why we designed this course, to be taken after Level 1 has been completed.",
	course_lvl_2_for_who_paragraph_4: "The course consists of a theoretical segment, where we will explore the muscle and skeletal anatomy, as well as their common conditions, apply massage techniques, learn about indications and contraindications, etc. In the practical segment, you will have the chance to practice the massage on real clients, while being supervised by the educator, focused on improving your technique and becoming a therapist. ",
	course_lvl_2_goal_header_txt: "The aim",
	course_lvl_2_goal_paragraph: "Developing confidence in applying remedial massage, recognising massage techniques to be used in a given situation and practical application of theoretical knowledge.",
	course_lvl_2_course_duration_header_txt: "The duration",
	course_lvl_2_course_duration_paragraph_1: "One month.",
	// course_lvl_2_course_duration_paragraph_1: "Course start: 12.10.2020.",
	// course_lvl_2_course_duration_paragraph_2: "Course end: 06.11.2020.",
	course_lvl_2_schedule_header_txt: "Class schedule",
	course_lvl_2_schedule_paragraph_1: "Lesson blocks of 2.5h (with a 30 min break) every Monday, Wednesday and Friday.",
	// course_lvl_2_schedule_paragraph_2: "In case the students are employed full-time, it is possible to hold classes in the morning.",
	course_lvl_2_examination_header_txt: "The exam",
	course_lvl_2_examination_paragraph_1: "At the end of the course, there is a theoretical and practical exam.",
	// course_lvl_2_examination_paragraph_2: "The exam consists of a theory exam and a practical knowledge demonstration, and class project presentation.",
	course_lvl_2_price_header_txt: "The price",
	course_lvl_2_price_paragraph_1: level2Price,
	course_lvl_2_price_paragraph_2: "If you sign up both courses (LEVEL 1 & LEVEL 2), you earn a 5000 RSD discount.",
	course_lvl_2_included_header_txt: "What is included in the price?",
	course_lvl_2_included_course_lvl_1_card_list: [
		"- Classes",
		"- Practical lessons",
		"- Materials for practical lessons  (tables, oils, towels, etc)",
		"- Class materials",
		"- Centre for massage and education Zen Certification"
	],
	course_lvl_2_not_included_header_txt: "What is not included in the price?",
	course_lvl_2_not_included_paragraph_1: [
		"- People’s University in Nis Certification (optional)",
		"- Additional exam (2000RSD per test)"
	],
	course_lvl_2_why_us_header_txt: "Why choose our school?",
	course_lvl_2_why_us_course_lvl_1_card_list: ["- Lessons are held in a modern, air-conditioned classroom", "- We use modern equipment", "- We have years of experience in massage education ", "- We hire our best students", "- Learning takes place in small groups", "- We are fully focused on each individual student"],
	course_lvl_2_note_header_txt: "Note",
	course_lvl_2_note_paragraph_2: "To enroll in this course, you need to have finished Level 1",
	course_lvl_2_note_paragraph_1: "We will not make up for any missed classes.",

	course_weekend_card_title: "Weekend Courses",
	course_weekend_card_date: level3DurationEn,
	course_weekend_card_text: "Improve your existing knowledge on massage, gain new experience and better your business - choose Zen weekend courses.",
	course_weekend_card_btn: "See More",
	course_weekend_rock_title: "Hot stone massage",
	course_weekend_rock_what_is_header_txt: "What is hot stone massage?",
	course_weekend_rock_what_is_paragraph_1: "Hot stone massage is a type of thermotherapeutic procedure, based around the ancient tradition of using hot volcanic stones for therapeutic purposes.",
	course_weekend_rock_what_is_paragraph_2: "This is a very popular treatment in spa centres around the world.",
	course_weekend_rock_what_is_paragraph_3: "During the treatment, the client can fully relax. Forty five different stones are heated in specially-designed chrome dishes, in water with a temperature of between 47° and 50° Celsius.",
	course_weekend_rock_what_is_paragraph_4: "Hot stone massage is a unique massage technique enabling the client to experience all thermodynamic benefits of heated basalt stones and aromatherapy.",
	course_weekend_rock_why_header_txt: "Why choose this course?",
	course_weekend_rock_why_paragraph_1: "Improve your massage therapy knowledge, enrich your offer and take a weekend to master this massage technique. Offering it will bring your clients satisfaction, while also improving your business.",
	course_weekend_rock_programme_header_txt: "Programme",
	course_weekend_rock_programme_paragraph_1: "The course consists of a theoretical and practical segment - focused on hot stone massage.",
	course_weekend_rock_programme_paragraph_2: "In the theoretical segment, we will discuss the benefits of this massage, equipment (where and how to get it), equipment maintenance and cleaning, contraindications and indications, safety, assessment of stone head prior to the massage, as well as getting new clients via knowledge promotion.",
	course_weekend_rock_programme_paragraph_3: "The practical segment includes practising the hot stone massage technique on real clients.",
	course_weekend_rock_duration_header_txt: "The duration",
	course_weekend_rock_duration_paragraph_1: "Six school classes (one day) ",
	course_weekend_rock_price_header_txt: "The price",
	course_weekend_rock_price_paragraph_1: "10.000 RSD",
	course_weekend_rock_included_header_txt: "What is included in the price?",
	course_weekend_rock_included_course_weekend_rock_hot_rock_list: ["- Classes", "- Practical lessons", "- Materials for practical lessons (tables, oils, towels, hot stones, etc)", "- Class materials", ""],

	course_weekend_prenatal_title: "Prenatal massage",
	course_weekend_prenatal_what_is_header_txt: "What is prenatal massage?",
	course_weekend_prenatal_what_is_paragraph_1: "Pregnancy is often accompanied with muscle tension, feet swelling, shoulder and hip pain.",
	course_weekend_prenatal_what_is_paragraph_2: "A prenatal massage can relieve these symptoms, when applied correctly. We will teach you how to safely carry out prenatal massages on mothers-to-be.",
	course_weekend_prenatal_what_is_paragraph_3: "This is a great opportunity for everyone in the massage business to enrich their knowledge, adding new and highly sought after techniques.",
	course_weekend_prenatal_why_header_txt: "Why choose this course?",
	course_weekend_prenatal_why_paragraph_1: "Improve your therapeutic skills and improve your business - the right way. Learn how to safely treat mothers-to-be. Your clients will love this treatment.",
	course_weekend_prenatal_programme_header_txt: "Programme",
	course_weekend_prenatal_programme_paragraph_1: "The course consists of a theoretical and practical segment.",
	course_weekend_prenatal_programme_paragraph_2: "The theoretical segment will explore the benefits of prenatal massage, contraindications and indications, equipment that can be safely used in this type of massage, the typical symptoms that pregnancy brings about and how to relieve them via a massage.",
	course_weekend_prenatal_programme_paragraph_3: "In the practical segment, we will talk about and practice the technique of prenatal massage, discussing the position clients should be in depending on trimester, how the technique varies depending on trimester and the type of consultation that must be conducted beforehand. We will also cover feet massage, hand massage and the acupressure points that are forbidden when massaging pregnant clients.",
	course_weekend_prenatal_duration_header_txt: "The duration",
	course_weekend_prenatal_duration_paragraph_1: "10 lessons of 45 mins each.",
	course_weekend_prenatal_duration_paragraph_2: "5 lessons of 45 mins per day, with breaks.",
	// course_weekend_prenatal_schedule_header_txt: "Class schedule",
	// course_weekend_prenatal_schedule_paragraph_1: "10 school classes",
	// course_weekend_prenatal_schedule_paragraph_2: "5 school classes per day ",
	course_weekend_prenatal_price_header_txt: "The price",
	course_weekend_prenatal_price_paragraph_1: "10.000 RSD",
	course_weekend_prenatal_included_header_txt: "What is included in the price?",
	course_weekend_prenatal_included_course_weekend_prenatal_list: ["- Classes", "- Practical lessons", "- Materials for practical lessons  (tables, oils, towels, etc)", "- Class materials", ""],


	course_weekend_massage_for_two_title: "Massage-for-two course",
	course_weekend_massage_for_two_for_whome_txt: "The massage-for-two course is a weekend-long massage training meant for couples, friends and close individuals who would like to conduct massages at home.",
	course_weekend_massage_for_two_for_whome_paragraph_1: "The massage-for-two course is a weekend-long massage training meant for couples, friends and close individuals who would like to conduct massages at home.",
	course_weekend_massage_for_two_for_whome_paragraph_2: "If you ever wanted your partner or anyone you’re close with to know how to massage your neck, or tired feet after a long day at work  - this is a chance for both of you to learn how to be able to do this for each other.",
	course_weekend_massage_for_two_why_header_txt: "The Aim",
	course_weekend_massage_for_two_why_paragraph_1: "Deepening the connection with your loved one through the time spent together in the course, as well as accumulation of basic practical knowledge of massage at home.",
	course_weekend_massage_for_two_duration_header_txt: "The Duration",
	course_weekend_massage_for_two_duration_paragraph_1: "6 lessons of 45 mins each.",
	course_weekend_massage_for_two_duration_paragraph_2: "3 lessons of 45 mins per day, with breaks.",
	// course_weekend_massage_for_two_schedule_header_txt: "Class schedule",
	// course_weekend_massage_for_two_schedule_paragraph_1: "6 school classes",
	// course_weekend_massage_for_two_schedule_paragraph_2: "3 school classes per day, with breaks in-between",
	course_weekend_massage_for_two_price_header_txt: "The price",
	course_weekend_massage_for_two_price_paragraph_1: "6.000 RSD (for both attendees of the course).",
	course_weekend_massage_for_two_included_header_txt: "What is included in the price?",
	course_weekend_massage_for_two_included_course_weekend_prenatal_list: ["-Classes", "-Practical lessons", "-Materials for practical lessons", "-Practice in pairs, under the educator’s supervision and with their suggestions for improvement"],
	course_weekend_massage_for_two_why_this_header: "Why choose this course?",
	course_weekend_massage_for_two_why_this_course_weekend_prenatal_list: ["- This activity for two will strengthen your bond", "-  Lessons are held in a modern, air-conditioned classroom", "-We have years of experience in massage education", "- Learning takes place in pairs, within a small group", "-We are fully focused on each individual pair"],

	course_weekend_massage_for_two_note_header: "Note",
	course_weekend_massage_for_two_note_pargraph_1: "You can apply for this course by 19.06. 2020. personally at the reception of Centre for massage and education Zen, by calling [+381 (0) 64 04 88](tel://+381 (0) 64 04 88) or emailing [office@zenmasaza.com](mailto://office@zenmasaza.com) .",
	course_weekend_massage_for_two_note_pargraph_2: "The number of available spots is limited. In the case of a larger number of interested attendees, the course will take place in several small groups. ",

	// course_notice_paragraph_text: "Should you have any questions, take a look at our [FAQ page](/en/faq), contact us at [+381 (0) 64 04 88](tel://+381 (0) 64 04 88) or [office@zenmasaza.com](mailto://office@zenmasaza.com). We will be happy to answer your questions.",
	course_notice_paragraph_text: "If you’re thinking about signing up or want more information about the course, contact us at [+381 (0) 64 04 88](tel://+381 (0) 64 04 88) or [office@zenmasaza.com](mailto://office@zenmasaza.com). We are happy to answer any questions you might have.",

	course_educators_educator_certificates_title: "Certifications and experience",
	course_educators: [
		{
			image:employeeImages["kristina-jovcic"],
			name: "Kristina Jovčić",
			experience: [
				"✓ Medical Massage Therapist - Australian Learning Group - New South Wales School of Massage, Sidney",
				"✓ Member of the Australian Association of Medical Massage Therapists - [amt.org.au](http://amt.org.au)",
				"✓ Oncology Massage Therapist (massage of cancer patients) - [www.oncologymassagetraining.com.au](http://www.oncologymassagetraining.com.au)",
				"✓ Prenatal Massage Therapist - [pregnancymassageaustralia.com.au](http://pregnancymassageaustralia.com.au)",
				"✓ Sound Massage with Tibetan bowl - Peter Hess®",
			]
		},
		{
			image:employeeImages["milena-stefanovic"],
			name: "Milena Stefanović",
			experience: [
				"✓ Physical Education professor",
				"✓ Physiotherapist",
				"✓ 10+ years of experience as a therapist performing all kinds of massages, facial and body treatments",
				"✓ A longtime member of the Zen team",
			]
		},
		{
			image: "https://zenmasaza.com/api/employees/MilicaRistic.JPG",
			name: "Milica Todorović",
			experience: [
				"✓ Therapist and member of the Zen team with 3+ years of experience in performing all kinds of massages, facial and body treatments",
				"✓ Specialized in performing therapeutic massages",
				"✓ 1+ years of experience assisting at Zen massage courses",
			]
		}
	],
	// course_about_educator_title: "About the educator",
	// course_about_educator_paragraph_1: "Kristina Jovčić is the owner and founder of Centre for massage and education Zen in Nis.",
	// course_about_educator_paragraph_2: "She has a rich experience as a therapist, educator and supervisor of spa centres in Serbia and Australia.",
	// course_about_educator_paragraph_3: "As a consultant for the spa industry, she provides support in the creation of a business plan using the spa business model, employee training, offer creating, marketing strategy development and increased revenue strategy development.",

	forms_reservation_header_txt: "Schedule appointment",
	forms_reservation_paragraph_1: "For an irreplaceable Zen experience, choose your ideal treatment and schedule an appointment today.",
	forms_reservation_paragraph_2: "To make your choice easier, we are offering you a wide variety of treatment options to suit your needs. Find the [full treatment list](/en/treatments) in our offer",
	forms_reservation_list_header: "Schedule your appointment in the following ways:",
	forms_reservation_list: ["contact us at  [+381 (0) 64 04 88 256](tel:+381-64-04-88-256) and we will help you select the ideal treatment for you, as well as schedule an appointment at the time most suitable for you.", "Visit us at Milorada Veljkovića Špaje 14b (first floor) and our reception will be happy to recommend the best treatment for you.", "Send us an inquiry and we will promptly be in touch."],
	forms_reservation_name: "NAME",
	forms_reservation_name_placeholder: "Your Name",
	forms_reservation_email: "E-MAIL",
	forms_reservation_email_placeholder: "your.name@mail.com",
	forms_reservation_phone: "PHONE NUMBER",
	forms_reservation_phone_placeholder: "+44 20 7183 8750",
	forms_reservation_message: "INQUIRY",
	forms_reservation_button_txt: "SEND",
	forms_reservation_paragraph_4: "Thank you for your trust,",
	forms_reservation_paragraph_5: "Centre for massage and education Zen",
	forms_voucher_name: "PURCHASED BY",
	forms_voucher_phone: "PHONE NUMBER",
	forms_voucher_email: "E-MAIL",
	forms_voucher_category: "TREATMENT CATEGORY",
	forms_voucher_treatment_name: "TREATMENT NAME",
	forms_voucher_duration: "TREATMENT DURATION",
	forms_voucher_price: "VOUCHER PRICE (RSD)",
	forms_voucher_for: "PURCHASED FOR",
	forms_voucher_for_phone: "CONTACT",
	forms_voucher_radio_header: "How would you like to receive your voucher? (choose an option)",
	forms_voucher_radio_1: "I would like to collect my voucher at Zen",
	forms_voucher_radio_2_extra: "ADDRESS",
	forms_voucher_radio_2: "I would like you to send the voucher per post to the person I’m purchasing it for (an additional 100 RSD charge)",
	forms_voucher_radio_3_extra: "E-MAIL",
	forms_voucher_radio_3: "I would like to receive the voucher per email",
	forms_voucher_button_txt: "SEND",

	forms_consultation_regular_title: "FOR CLIENTS",
	forms_consultation_regular_paragraph_1: "Dear Sir/Madam,",
	forms_consultation_regular_paragraph_2: "If this is your first time scheduling a professional massage or you are uncertain of the right treatment for your needs, please fill out one of our consultation forms, depending on your condition, based on which we can recommend a treatment.",
	forms_consultation_regular_paragraph_3: "The information will help our therapists to make your Zen experience unique and memorable.",
	forms_consultation_regular_paragraph_4: "Thank you for your trust,\nYour Zen",
	forms_consultation_regular_first_name: "NAME",
	forms_consultation_regular_last_name: "LAST NAME",
	forms_consultation_regular_email: "E-MAIL",
	forms_consultation_regular_phone: "PHONE NUMBER",
	forms_consultation_regular_therapist_gender: "THERAPIST SEX",
	forms_consultation_regular_therapist_gender_list: ["Either", "Male", "Female"],
	forms_consultation_regular_radio_age_group_title: "AGE GROUP",
	forms_consultation_regular_radio_age_group_items: ["Under 21", "22 - 35", "36 - 55", "Over 56", ""],
	forms_consultation_regular_check_condition_title: "DO YOU HAVE ANY OF THE FOLLOWING CONDITIONS?",
	forms_consultation_regular_check_condition_items: ["Recent surgery", "Spinal disc herniation/Discopathy", "Lower back pain", "Arthritis", "Allergies", "Neck or arm pain", "Muscle spasms", "Cancer", "Pregnancy", "High/Low blood pressure", "Venous thrombosis", "Epilepsy", "Osteoporosis", "Migraines/Headaches", "Stress", "Shoulder Pain/Injury", "Previous breaks/twists/sprains", "Varicose veins", "History of cancer", "Removed lymph nodes"],
	forms_consultation_regular_radio_expectation_title: "WHAT ARE YOU EXPECTING FROM THE TREATMENT?",
	forms_consultation_regular_radio_expectation_items: ["Relaxation", "Pain removal", "Relieving muscle tension", "Stress release", ""],
	forms_consultation_regular_radio_massage_frequency_title: "HOW OFTEN DO YOU SCHEDULE TREATMENTS?",
	forms_consultation_regular_radio_massage_frequency_items: ["At least once per month", "Once or twice per year", "I’ve never had a professional massage"],
	forms_consultation_regular_radio_sport_frequency_title: "DO YOU ENGAGE IN PHYSICAL ACTIVITY?",
	forms_consultation_regular_radio_sport_frequency_items: ["Yes", "No"],
	forms_consultation_regular_sport: "WHICH SPORT? HOW OFTEN (PER WEEK)?",
	forms_consultation_regular_radio_heard_about_title: "HOW DID YOU HEAR ABOUT US?",
	forms_consultation_regular_radio_heard_about_items: ["Facebook", "Instagram", "I saw Zen while passing by", "Recommendation", "Billboard", "I’ve received a Zen voucher", "Other"],
	forms_consultation_regular_other: "OTHER",
	forms_consultation_regular_button_txt: "SEND",
	forms_consultation_regular_paragraph_5: "I have read the consultation and am responsible for the accuracy of everything written. I have made my psycho-physical condition known to my therapist and have clearly stated all relevant information pertaining to my health.",
	forms_consultation_prenatal_title: "FOR PREGNANT CLIENTS",
	forms_consultation_prenatal_first_name: "NAME",
	forms_consultation_prenatal_last_name: "LAST NAME",
	forms_consultation_prenatal_email: "E-MAIL",
	forms_consultation_prenatal_phone: "PHONE NUMBER",
	forms_consultation_prenatal_emergency_phone: "EMERGENCY CONTACT",
	forms_consultation_prenatal_num_pregnancies: "NUMBER OF PREVIOUS PREGNANCIES",
	forms_consultation_prenatal_radio_risky_pregnancy_title: "WERE YOUR PREVIOUS PREGNANCIES AT ALL RISKY?",
	forms_consultation_prenatal_radio_risky_pregnancy_items: ["Yes", "No"],
	forms_consultation_prenatal_pregnancy_weeks: "HOW MANY WEEKS ALONG ARE YOU AT THE MOMENT OF FILLING OUT THIS CONSULTATION?",
	forms_consultation_prenatal_radio_pregnancy_weeks_title: "HAVE YOU EVER HAD A PRENATAL MASSAGE BEFORE?",
	forms_consultation_prenatal_radio_pregnancy_weeks_items: ["Yes", "No"],
	forms_consultation_prenatal_medicament: "ARE YOU CURRENTLY ON MEDICATION? IF YES, WHICH MEDICATION?",
	forms_consultation_prenatal_radio_visit_reason_title: "WHAT IS YOUR MAIN AIM?",
	forms_consultation_prenatal_radio_visit_reason_items: ["Relaxation", "Muscle tension relief", "Lymphatic drainage", "All of the above"],
	forms_consultation_prenatal_radio_massage_frequency_title: "HOW OFTEN DO YOU SCHEDULE TREATMENTS?",
	forms_consultation_prenatal_radio_massage_frequency_items: ["Rarely - a few times per year", "Regularly- at least once per month", "I’ve never had a professional massage"],
	forms_consultation_prenatal_radio_massage_pressure_title: "WHAT TYPE OF PRESSURE DO YOU PREFER?",
	forms_consultation_prenatal_radio_massage_pressure_items: ["Gentle, relaxing", "Medium", "Medium going on strong", "Strong"],
	forms_consultation_prenatal_check_medical_condition_title: "DO ANY OF THE FOLLOWING CONDITIONS DESCRIBE YOUR CURRENT MEDICAL STATE OR YOUR MEDICAL STATE IN ANY POINT OF YOUR PREGNANCY?",
	forms_consultation_prenatal_check_medical_condition_items: ["Weaker blood circulation", "Former injuries", "Heart conditions", "Osteoporosis/Arthritis", "Varicose veins", "Allergy to any particular product", "Skin sensitivity", "Sensitivity to almond oil", "Headaches", "Numbness of the arms or legs", "Inflammation of the bladder", "Preeclampsia (high blood pressure)", "Gestational diabetes", "Strong and persistent abdominal pain", "Depression", "Vaginal bleeding", "Painful urination with a burning sensation", "Headache accompanied by visual or speech impairment", "Frequent leg cramps, calf pain", "Lower back pain", "Neck/shoulder/arm pain", "Pain in the hip area", "Diastasis of pubic symphysis", "Arm/Leg swelling", "History of thrombus", "History of premature labour", "Diastasis of the abdominal muscles", "Frequent nausea", "Former twists/sprains"],
	forms_consultation_prenatal_medical_condition_other: "LEAVE A NOTE IF YOU HAVE A CONDITION THAT WAS NOT LISTED ABOVE.",
	forms_consultation_prenatal_radio_heard_about_title: "HOW DID YOU HEAR ABOUT US?",
	forms_consultation_prenatal_radio_heard_about_items: ["Facebook", "Instagram", "I saw Zen while passing by", "Recommendation", "Billboard", "I’ve received a Zen voucher", "Other"],
	forms_consultation_prenatal_other: "OTHER",
	forms_consultation_prenatal_button_txt: "SEND",

	forms_career_title_1: "Start your career in Zen!",
	forms_career_paragraph_1: "We, at Centre for massage and education Zen, are proud to hire creative and capable individuals who share our values.",
	forms_career_paragraph_2: "Professionalism, kindness and quality massages are synonyms for Zen, and have been for years.",
	forms_career_paragraph_3: "If you are wishing for a profession you can take pride in - become a part of our team.",
	forms_career_paragraph_4: "Apply today!",
	forms_career_title_2: "Our story",
	forms_career_paragraph_5: "Since we have opened our doors, six years ago, we have offered Nis citizens an invaluable and timeless experience of massage in a 5* spa centre. Our story has turned into a fairytale.",
	forms_career_paragraph_6: "Apart from our signature treatment - the Zen massage - which remains unique and special, our desire is to become the leading educational centre for future therapists, because we believe in young people and their potential.",
	forms_career_title_3: "Our values",
	forms_career_paragraph_7: "It is in the nature of Zen to represent balance. This is precisely what our philosophy is based on. To achieve balance, it is necessary to be moderate in all things, have taste and creative energy.",
	forms_career_paragraph_8: "Zen is a place in which people can find their inner peace during the treatment, learn to love themselves and feel better. This is why therapists need to carry peace within, be satisfied to fully enjoy this selfless calling.",
	forms_career_title_4: "Apply for a job at Zen",
	forms_career_paragraph_9: "If you have previous knowledge on massage (no matter the work experience), are a team player looking for flexible working hours and a job that is anything but boring - apply and we will contact you!\nBecause Zen is a manner of life rather than just a job.",
	forms_career_title_5: "Welcome!",
	forms_career_name: "NAME",
	forms_career_name_placeholder: "Your Name",
	forms_career_phone: "PHONE NUMBER",
	forms_career_phone_placeholder: "+44 20 7183 8750",
	forms_career_email: "E-MAIL",
	forms_career_email_placeholder: "your.name@mail.com",
	forms_career_bio: "MESSAGE",
	forms_career_btn: "SEND",

	forms_invalid_title: "Error",
	forms_invalid_field: "Invalid field",

	forms_info_message: "Info",
	forms_info_title: "Message sent successfully.",

	forms_unavailable_paragraph_1: "We're sorry but online shop is not available at the moment.",
	forms_unavailable_paragraph_2: "Please try again later.",

	modal_close_btn: "CLOSE",
	modal_confirm_btn: "CONFIRM"
};
