import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import "./Modal.css";
import langs from "../localization/languages/languages";
import { useLocale } from "./useLocale";

type ModalProps = {
	title?: string;
	list?: any[];
	onInit: Function;
}

type SetModalPropsOptions = {
	title?: string;
	list?: any[];
}

const Modal = ({title, list, onInit}: ModalProps) => {
	const lang = useLocale();

	const [ref, setRef]= useState<HTMLDivElement|null>(null);

	useEffect(() => {
		onInit(ref);
		// eslint-disable-next-line
	}, [ref]);

	return (
		<div className="container-modal">
			<div id="error-modal" ref={elem => setRef(elem)} className="modal">
				<div className=" modal-content">
					<h4>{title ? title : ""}</h4>
					{list ? list.map((item, i) => <p key={i}>{item}</p>) : ""}
				</div>
				<div className="modal-footer">
					{/*eslint-disable-next-line*/}
					<a className="modal-close bg-dark-gold white-text pl-4 pr-4 pt-2 pb-2">{langs[lang].modal_close_btn}</a>
				</div>
			</div>
		</div>
	);
};


export const useModal = (): [any, Dispatch<SetStateAction<SetModalPropsOptions | undefined>>, Function, Function] => {
	const [props, setProps] = useState<SetModalPropsOptions>();
	const [ref, setRef] = useState<Element|null>(null);
	const [instance, setInstance] = useState<M.Modal>();
	const [modal, setModal] = useState<any>();

	useEffect(() => {
		setModal(<Modal title={props?.title}
		                list={props?.list}
		                onInit={(val: HTMLDivElement|null) => setRef(val)}/>);
		// eslint-disable-next-line
	}, [props]);

	useEffect(() => {
		const inst = ref && M.Modal.init(ref, {});
		if (inst) setInstance(inst);
		// eslint-disable-next-line
	}, [ref]);


	const open = () => {
		if (instance) instance.open();
	};

	const close = () => {
		if (instance) instance.close();
	};

	return [modal, setProps, open, close];
};
