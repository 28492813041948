import React from "react";

type FAQElementProps = {
	id: number | string,
	question: string,
	answer: string
};
export const FAQElement = ({id, question, answer}: FAQElementProps) => {
	return (
		<div className="row faq-element">
			<div className='col s12 m12 l12 question'>
				<h5 className="gold">{`${id}. ${question}`}</h5>
			</div>
			<div className="col s12 m12 l12 question">
				{answer.split("\n").map(text => <p>{text}</p>)}
			</div>
		</div>
	);
};
