import * as React from "react";

export const VolcanicWeekendSeminar = () => {
	return (
		<div>
			<h3>
				Masaža vulkanskim kamenjem
			</h3>
			<p>
				<span className="bold-text">Datum održavanja kursa:</span> april/maj 2023.<br/>
				<span className="bold-text">Vreme održavanja kursa:</span> 10 – 18h (pauza za ručak 13-14h)
			</p>
			<h4>
				Šta je to masaža vulkanskim kamenjem?
			</h4>
			<p>
				Masaža vulkanskim kamenjem (hot stone) je vid termoterapijske procedure, zasnovane na
				drevnoj tradiciji korišćenja toplog vulkanskog kamenja u terapijske svrhe.
			</p>
			<p>
				Jako je popularan tretman u spa centrima širom sveta.
			</p>
			<p>
				Za vreme trajanja tretmana klijent se potpuno opušta.
				Četrdeset i pet različitih kamena zagrevaju se u posebnim hromiranim posudama, u vodi, temperature
				između 47 – 50C.
			</p>
			<p>
				Masaža vulkanskim kamenjem predstavlja jedinstvenu tehniku masaže koja omogućuje
				prožimanje svih termodinamičkih blagodeti zagrejanog bazalnog kamenja i uzvišenih svojstava eteričnih
				ulja.
			</p>
			<h4>Zašto upisati ovaj kurs?</h4>
			<p>
				Usavršite se kao terapeut, obogatite svoj posao i za samo
				jedan vikend savladajte ovu tehniku masaže koja će vam doneti zadovoljne klijente ili napredak u spa
				industriji.
			</p>
			<h4>Program kursa</h4>
			<p>
				Ovaj kurs se sastoji od teorijskog dela i praktične vežbe
				tehnike masaže vulkanskog kamenja.
			</p>
			<p>
				U teorijskom delu govoriće se o benefitima ove masaže, opremi
				(gde i kako je nabaviti), održavanju i čišćenju opreme, kontraindikacijama i indikacijama, bezbedonosnim
				uslovima, proceni toplote kamenja pre masaže kao i sticanju novih klijenata promocijom znanja.
			</p>
			<p>
				U praktičnom delu vežbaće se masaža ova tehnika sa vulkanskim kamenjem na realnom klijentu.
			</p>
			<h4>
				Trajanje kursa
			</h4>
			<p>
				Šest školskih časova. (jedan dan)
			</p>
			<h4>
				Cena kursa
			</h4>
			<p>
				150e
			</p>
			<h4>
				Šta je uključeno u cenu?
			</h4>
			<ul>
				<li>✓ Predavanje</li>
				<li>✓ Praksa</li>
				<li>✓ Materijal za praktično učenje masaže (stolovi,ulja, peškiri, vulkansko kamenje itd.)</li>
				<li>✓ Literatura</li>
			</ul>
		</div>
	);
};
