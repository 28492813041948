import { RouteStrings } from "../RouteStrings";

export const rs: RouteStrings = {
	home: "pocetna",
	treatments: "tretmani",
	courses: "kursevi",
	courses_level1: "kursevi/nivo-1",
	courses_level2: "kursevi/nivo-2",
	courses_level_weekend_seminar: "kursevi/seminar",
	blog: "blog",
	contact: "kontakt",

	career: "karijera",
	consultation: "konsultacije",
	voucher: "vaucer",
	reservation:"rezervacija",
	purchase:"kupovina",

	about: "o-nama",
	faq: "cesto-postavljana-pitanja"
};
