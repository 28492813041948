import { LocaleStrings } from "../LocaleStrings";
import { employees } from "./employees/rs";
import { testimonials } from "./testimonials/rs";
import { faq } from "./faq/faq";
import {
	level1DurationRs,
	level1Price,
	level2Price,
	level2DurationRs,
	level3DurationRs,
} from "../../globals";
import employeeImages from "../../static/employeeImages";

export const rs: LocaleStrings = {
	navigation_home: "POČETNA",
	navigation_offer: "PONUDA",
	navigation_academy: "AKADEMIJA",
	navigation_courses: "EDUKACIJA",
	navigation_courses_level1: "NIVO 1",
	navigation_courses_level2: "NIVO 2",
	navigation_courses_seminar: "VIKEND SEMINARI",
	navigation_blog: "BLOG",
	navigation_contact: "KONTAKT",
	home_header_slide_button_purchase: "KUPI VAUČER",
	home_header_slide_button_purchase_for_one: "KUPI VAUČER",
	home_header_slide_button_purchase_for_two: "KUPI VAUČER ZA DVOJE",
	home_header_slide_button_schedule: "ZAKAŽI TRETMAN",
	home_header_slide_button_learn_more: "SAZNAJ VIŠE",
	home_header_slide_button_view_offer: "VIDI PONUDU",

	home_about_heading_1: "O nama",
	home_about_text: [
		"Iskusite vrhunski doživljaj opuštanja u srcu grada! U našem umirujućem ambijentu, masaža nije samo usluga, već izraz posvećenosti Vašem zdravlju i blagostanju.",
		"Naši stručni terapeuti su tu da Vas provedu kroz magični svet masaže, prilagode tretmane Vašim individualnim potrebama i omoguće Vam da zaboravite na svakodnevni stres i pronađete svoj Zen.",
		"Uz kombinaciju mirisnih aroma i dodira, doživećete potpunu harmoniju i savršen balans uma, duha i tela.",
		"Centar za masažu Zen posevećen je primeni savremenih i drevnih tehnika masaže, inspirisanih Zapadom i Istokom, tako da ovde možete doživeti najbolje što oba sveta pružaju i iskusiti nezaboravno opuštanje i revitalizaciju.",
	],
	home_about_button_text_1: "Naš tim",
	home_about_button_text_2: "Karijera u Zenu",
	home_circle_button_text_1_1: "ZAKAŽITE\nTERMIN",
	home_circle_button_text_2_1: "POPUNITE\nKONSULTACIJU",
	home_circle_button_text_3_1: "KUPITE \nVAUČER",
	about_image_title_prop: "Zen je za mene",
	about_group_title: "Naš tim",

	footer_address_text_1: "MILORADA VELJKOVIĆA ŠPAJE 14 I SPRAT 18000 NIŠ SRBIJA",
	footer_address_text_2: "SEDMOG JULA 25a/12 II SPRAT 18000 NIŠ SRBIJA",
	footer_location_1_text: "Lokacija 1 (info i vaučeri)",
	footer_location_2_text: "Lokacija 2 (zakazani tretmani)",
	footer_reservation_button: "Zakažite termin",
	footer_career_button: "Karijera u ZEN-u",
	footer_faq_text: "Često postavljana pitanja",
	footer_developed: "Kodirali i dizajnirali: ",

	contact_list_item_title_address_text: "ADRESE",
	contact_list_item_caption_location_text: "Lokacija",
	contact_list_item_caption_text_1: "MILORADA VELJKOVIĆA ŠPAJE 14 I SPRAT 18000 NIŠ SRBIJA",
	contact_list_item_caption_text_2: "SEDMOG JULA 25a/12 II SPRAT 18000 NIŠ SRBIJA",
	contact_list_item_title_text_2: "ADRESA",
	contact_list_item_hours_open: "RADNO VREME",
	contact_list_item_saturday: "SUBOTA",
	contact_list_item_sunday: "NEDELJA",
	contact_list_item_telephone: "KONTAKT TELEFON",
	faq_page_heading_text: "ČESTO POSTAVLJANA PITANJA",
	faq_page_list_title_for_clients: "ZA KLIJENTE",
	faq_page_list_title_for_course_goers: "ZA POLAZNIKE KURSA",

	testimonials: testimonials,
	// categories: categories,
	boss: {
		id: "kristina-jovcic",
		name: "Kristina Jovčić",
		title: "Osnivač, terapeut i edukator",

	},
	employees: employees,
	titles: {
		"OWNER": "Osnivač",
		"EDUCATOR": "Edukator",
		"MANAGER": "Menadžer",
		"BEAUTICIAN": "Kozmetičar",
		"RECEPTIONIST": "Recepcija",
		"THERAPIST": "Terapeut",
	},
	// treatments: treatments,
	treatments_not_available: "*Ova masaža nije dostupna u online kupovini.",
	treatments_duration_price: "TRAJANJE/CENA(RSD)",
	faq_course: faq.course.rs,
	faq_treatment: faq.treatment.rs,

	course_lvl_1_card_title: "Nivo 1",
	course_lvl_1_card_btn: "Detaljnije",
	course_lvl_1_card_date: level1DurationRs,
	course_lvl_1_card_text: "Namenjen onima koji nemaju prethodno znanje o masaži, a smatraju da bi ona bila savršen hobi ili buduće zanimanje - ovaj kurs je pravi izbor.",
	course_lvl_1_title_text: "Uvod i osnovna tehnika relaksacione masaže",
	course_lvl_1_for_who_header_txt: "Kome je namenjen?",
	course_lvl_1_for_who_paragraph_1: "Svima koji žele da se bave masažom iz hobija ili onima kojima je potrebno osnovno znanje za započinjanje karijere u spa industriji.",
	course_lvl_1_for_who_paragraph_2: "Upoznaće se sa pojmom relaksacione masaže, poznate širom sveta.",
	course_lvl_1_for_who_paragraph_3: "Naučiće osnovne hvatove i tehnike primenjivanja ove masaže, kontraindikacije i indikacije za izvođenje masaže i sa sigurnošću će moći da je praktikuju nakon kursa.",
	course_lvl_1_for_who_paragraph_4: "Kurs se sastoji od teorijskog i praktičnog dela u kome se masaža izvodi na realnim klijentima, uz nadzor edukatora koji će vam pomoći da dosegnete svoj maksimum i time otpočnete svoj put ka uspešnoj karijeri terapeuta.",
	course_lvl_1_goal_header_txt: "Cilj kursa",
	course_lvl_1_goal_paragraph: "Razvijanje samopouzdanja izvođenja relaksacione masaže, prepoznavanje masažnih hvatova koje treba primeniti u datoj situaciji i praktična primena stečenih teorijskih znanja.",
	course_lvl_1_course_duration_header_txt: "Trajanje kursa",
	course_lvl_1_course_duration_paragraph_1: "Mesec dana.",
	// course_lvl_1_course_duration_paragraph_1: "Početak kursa: 14.09.2020",
	// course_lvl_1_course_duration_paragraph_2: "Završetak kursa: 10.10.2020.",
	course_lvl_1_schedule_header_txt: "Raspored predavanja",
	course_lvl_1_schedule_paragraph_1: "Ponedeljak, sreda i petak 17-20.30h",
	// course_lvl_1_schedule_paragraph_2: "U slučaju grupe zapošljenih, predavanja su moguća i prepodne.",
	course_lvl_1_examination_header_txt: "Polaganje",
	course_lvl_1_examination_paragraph_1: "Na kraju svih predavanja, polaže se teorijski i praktični test. Za prolaznost, neophodno je demonstrirati minimum 80% savladanog gradiva.",
	// course_lvl_1_examination_paragraph_2: "Polaganje se sastoji od teorijskog testa i praktične demonstracije znanja.",
	course_lvl_1_price_header_txt: "Cena kursa",
	course_lvl_1_price_paragraph_1: level1Price,
	course_lvl_1_price_paragraph_2: "Za uplaćena oba nivoa obuke (NIVO 1 i NIVO 2), ostvarujete  popust na ukupan iznos od 5000 dinara.",
	course_lvl_1_included_header_txt: "Šta je uključeno u cenu?",
	course_lvl_1_included_course_lvl_1_card_list: ["-Predavanja", "-Praksa", "-Materijal za praktično učenje masaže", "-Literatura", "-Uverenje o položenom kursu izdato od strane Centra za masažu i edukaciju Zen"],
	course_lvl_1_not_included_header_txt: "Šta nije uključeno u cenu?",
	course_lvl_1_not_included_paragraph_1: [
		"- Sertifikat o položenom kursu od strane narodnog Univerziteta u Nišu (opciono).",
		"- Dodatno polaganje ispita (2000 dinara po testu)"
	],
	course_lvl_1_why_us_header_txt: "Zašto izabrati našu školu?",
	course_lvl_1_why_us_course_lvl_1_card_list: [
		"✓ Zapošljavamo najbolje polaznike",
		"✓ Većina naših polaznika se bavi profesionalnom masažom nakon kursa ili ima svoj biznis",
		"✓ Naučićemo Vas masaži na način koji će učiniti da se osećate samopouzdano i sigurno za rad",
		"✓ Praktični deo kursa u se izvodi na realnim klijentima, uz nadzor edukatora koji će vam pomoći da dosegnete svoj maksimum u učenju i vežbanju novih tehnika",
		"✓ Omogućavamo Vam 70% praktičnog učenja prilikom kojeg se teorijski deo utvrđuje",
		"✓ Predavanja se održavaju u savremenoj i klimatizovanoj učinioci",
		"✓ Koristimo modernu opremu u edukaciji polaznika",
		"✓ Imamo višegodišnje iskustvo u edukaciji masaže",
		"✓ Učenje je u malim grupama",
		"✓ Potpuno se povećujemo svakom polazniku",
	],

	course_lvl_1_note_header_txt: "Napomena",
	course_lvl_1_note_paragraph_1: "Propuštene časove ne nadoknađujemo.",

	course_lvl_2_card_title: "Nivo 2",
	course_lvl_2_card_btn: "Detaljnije",
	course_lvl_2_card_date: level2DurationRs,
	course_lvl_2_card_text: "Namenjen svima koji su prethodno završili Nivo 1, poseduju znanje o relaksacionoj masaži i žele dalje usavršavanje ka terapeutskoj i sportskoj masaži.",
	course_lvl_2_title_text: "Uvod i tehnika terapeutske masaže",
	course_lvl_2_for_who_header_txt: "Kome je namenjen?",
	course_lvl_2_for_who_paragraph_1: "Za sve one koji, nakon upoznavanja relaksacione masaže, žele da napreduju i dalje se usavršavaju ovo je sledeći korak - upoznavanje sa tehnikom terapeutske masaže.",
	course_lvl_2_for_who_paragraph_2: "Teraputska masaža se u mnogo čemu razlikuje od relaksacione. Njena svrha je drugačija, kao i primenjivanje. Tu je da ukloni mišićnu napetost, poboljša mobilnost zglobova, poboljša cirkulaciju i slično.",
	course_lvl_2_for_who_paragraph_3: "Da bi postali uspešni terapeuti, poznavanje tehnika terapeutske masaže je neophodno. Zato, nakon uspešno završenog Nivoa 1, predlažemo ovaj kurs.",
	course_lvl_2_for_who_paragraph_4: "On se sastoji od teorijskog predavanja ukome se detaljnije izučavaju mišićna iskeletna anatomija, kao i patološka stanjaistih, primena hvatova, indikacija ikontraindikacija itd. Nakon toga sledipraksa na realnim klijentima uz nadzoredukatora koji će vam pomoći da se usavršitei prihvatite novo zvanje - terapeuta.",
	course_lvl_2_goal_header_txt: "Cilj kursa",
	course_lvl_2_goal_paragraph: "Razvijanje samopouzdanja izvođenja terapeutske masaže, prepoznavanje masažnih hvatova koje treba primeniti u datoj situaciji i praktična primena teorijskih stečenih znanja.",
	course_lvl_2_course_duration_header_txt: "Trajanje kursa",
	course_lvl_2_course_duration_paragraph_1: "Mesec dana.",
	// course_lvl_2_course_duration_paragraph_2: "Završetak kursa: 06.11.2020.",
	course_lvl_2_schedule_header_txt: "Raspored predavanja",
	course_lvl_2_schedule_paragraph_1: "Časovi se održavaju ponedeljkom, sredom i petkom u trajanju od 3 i po sata (sa pauzom od pola sata).",
	// course_lvl_2_schedule_paragraph_2: "U slučaju grupe zapošljenih, predavanja su moguća i prepodne.",
	course_lvl_2_examination_header_txt: "Polaganje",
	course_lvl_2_examination_paragraph_1: "Na kraju svih predavanja, polaže se teorijski i praktični test.",
	// course_lvl_2_examination_paragraph_2: "Polaganje se sastoji od teorijskog testa, praktične demonstracije znanja i prezentacije seminarskog rada.",
	course_lvl_2_price_header_txt: "Cena kursa",
	course_lvl_2_price_paragraph_1: level2Price,
	course_lvl_2_price_paragraph_2: "Za uplaćena oba nivoa obuke (NIVO 1 i NIVO 2), ostvarujete  popust na ukupan iznos od 5000 dinara.",
	course_lvl_2_included_header_txt: "Šta je uključeno u cenu?",
	course_lvl_2_included_course_lvl_1_card_list: [
		"- Predavanja",
		"- Praksa",
		"- Materijal za praktično učenje masaže",
		"- Literatura",
		"- Uverenje o položenom kursu izdato od strane Centra za masažu i edukaciju Zen"
	],
	course_lvl_2_not_included_header_txt: "Šta nije uključeno u cenu?",
	course_lvl_2_not_included_paragraph_1: [
		"- Sertifikat o položenom kursu od strane narodnog Univerziteta u Nišu (opciono).",
		"- Dodatno polaganje ispita (2000 dinara po testu)"
	],
	course_lvl_2_why_us_header_txt: "Zašto izabrati našu školu?",
	course_lvl_2_why_us_course_lvl_1_card_list: [
		"✓ Zapošljavamo najbolje polaznike",
		"✓ Većina naših polaznika se bavi profesionalnom masažom nakon kursa ili ima svoj biznis",
		"✓ Naučićemo Vas masaži na način koji će učiniti da se osećate samopouzdano i sigurno za rad",
		"✓ Praktični deo kursa u se izvodi na realnim klijentima, uz nadzor edukatora koji će vam pomoći da dosegnete svoj maksimum u učenju i vežbanju novih tehnika",
		"✓ Omogućavamo Vam 70% praktičnog učenja prilikom kojeg se teorijski deo utvrđuje",
		"✓ Predavanja se održavaju u savremenoj i klimatizovanoj učinioci",
		"✓ Koristimo modernu opremu u edukaciji polaznika",
		"✓ Imamo višegodišnje iskustvo u edukaciji masaže",
		"✓ Učenje je u malim grupama",
		"✓ Potpuno se povećujemo svakom polazniku",
	],

	course_lvl_2_note_header_txt: "Napomene",
	course_lvl_2_note_paragraph_2: "Da bi se upisao ovaj nivo kursa, neophodan je uspešno završeni Nivo 1.",
	course_lvl_2_note_paragraph_1: "Propuštene časove ne nadoknađujemo.",

	course_weekend_card_title: "Vikend Kursevi",
	course_weekend_card_date: level3DurationRs,
	course_weekend_card_text: "Unapredite ono što već znate o masaži, steknite novo iskustvo kao terapeut i usavršite posao kojim se već bavite - izaberite vikend specijalizacije Zena.",
	course_weekend_card_btn: "Detaljnije",
	course_weekend_rock_title: "Masaža vulkanskim kamenjem",
	course_weekend_rock_what_is_header_txt: "Šta je to masaža vulkanskim kamenjem??",
	course_weekend_rock_what_is_paragraph_1: "Masaža vulkanskim kamenjem (hot stone) je vid termoterapijske procedure, zasnovane na drevnoj tradiciji korišćenja toplog vulkanskog kamenja u terapijske svrhe.",
	course_weekend_rock_what_is_paragraph_2: "Jako je popularan tretman u spa centrima širom sveta.",
	course_weekend_rock_what_is_paragraph_3: "Za vreme trajanja tretmana klijent se potpuno opušta. Četrdeset i pet različitih kamena zagrevaju se u posebnim hromiranim posudama, u vodi, temperature između 47 – 50C.",
	course_weekend_rock_what_is_paragraph_4: "Masaža vulkanskim kamenjem predstavlja jedinstvenu tehniku masaže koja omogućuje prožimanje svih termodinamičkih blagodeti zagrejanog bazalnog kamenja i uzvišenih svojstava eteričnih ulja.",
	course_weekend_rock_why_header_txt: "Zašto upisati ovaj kurs?",
	course_weekend_rock_why_paragraph_1: "Usavršite se kao terapeut, obogatite svoj posao i za samo jedan vikend savladajte ovu tehniku masaže koja će vam doneti zadovoljne klijente ili napredak u spa industriji.",
	course_weekend_rock_programme_header_txt: "Program kursa",
	course_weekend_rock_programme_paragraph_1: "Ovaj kurs se sastoji od teorijskog dela i praktične vežbe tehnike masaže vulkanskog kamenja.",
	course_weekend_rock_programme_paragraph_2: "U teorijskom delu govoriće se o benefitima ove masaže, opremi (gde i kako je nabaviti), održavanju i čišćenju opreme, kontraindikacijama i indikacijama, bezbedonosnim uslovima, proceni toplote kamenja pre masaže kao i sticanju novih klijenata promocijom znanja.",
	course_weekend_rock_programme_paragraph_3: "U praktičnom delu vežbaće se masaža ova tehnika sa vulkanskim kamenjem na realnom klijentu.",
	course_weekend_rock_duration_header_txt: "Trajanje kursa",
	course_weekend_rock_duration_paragraph_1: "Šest školskih časova. (jedan dan)",
	course_weekend_rock_price_header_txt: "Cena kursa",
	course_weekend_rock_price_paragraph_1: "10.000 RSD",
	course_weekend_rock_included_header_txt: "Šta je uključeno u cenu?",
	course_weekend_rock_included_course_weekend_rock_hot_rock_list: ["- Predavanje", "- Praksa", "- Materijal za praktično učenje masaže (stolovi,ulja, peškiri, vulkansko kamenje itd.)", "- Literatura"],

	course_weekend_prenatal_title: "Prenatalna masaža - masaža trudnica",
	course_weekend_prenatal_what_is_header_txt: "Šta je to prenatalna masaža?",
	course_weekend_prenatal_what_is_paragraph_1: "Mišićna napetost, otok u nogama, bol u ramenom predelu i kukovima su neretke pojave u drugom stanju.",
	course_weekend_prenatal_what_is_paragraph_2: "Masaža u ovome može da puno pomogne kada se primenjuje na pravilni način. Mi Vas učimo kako da bezbedno izvodite tretman masaže budućim majkama.",
	course_weekend_prenatal_what_is_paragraph_3: "Ovo je sjajna prilika za sve koji se već bave masažom, da upotpune svoje znanje novim i jako traženim tehnikama.",
	course_weekend_prenatal_why_header_txt: "Zašto upisati ovaj kurs?",
	course_weekend_prenatal_why_paragraph_1: "Usavršite se kao teraput ili poboljšajte Vaš posao, na adekvatan način. Naučite kako da da bezbedno pružite najbolji tretman budućoj majci. Ovaj tretman će Vaše buduće klijentkinje obožavati.",
	course_weekend_prenatal_programme_header_txt: "Program kursa",
	course_weekend_prenatal_programme_paragraph_1: "Ovaj kurs sastoji se od teorijskog predavanja i praktične vežbe.",
	course_weekend_prenatal_programme_paragraph_2: "Na teorijskom predavanju saznaćete benefite prenatalne masaže, kontraindikacije i indikacije za izvođenje iste, opreme koja bezbedna u ovakvom tipu masaže kakvi su opšti simptomi trudnoće i kako ih je moguće ublažiti masažom",
	course_weekend_prenatal_programme_paragraph_3: "Na praktičnom delu objašnjavaće se i vežbati tehnika masaže trudnice, način na koji treba da leže u zavisnosti od trimestra, govoriće o tome kako se tehnika razlikuje od trimestra, kakvu konsultaciju morate odraditi sa klijentkinjom u drugom stanju, kao i kako se masiraju noge, ruke i koje su to akupresurme tačke koje su zabranjene u masaži trudnica.",
	course_weekend_prenatal_duration_header_txt: "Trajanje kursa",
	course_weekend_prenatal_duration_paragraph_1: "10 školskih časova.",
	course_weekend_prenatal_duration_paragraph_2: "Po 5 školskih časova, sa puzama, na dan.",
	// course_weekend_prenatal_schedule_header_txt: "Raspored časova",
	// course_weekend_prenatal_schedule_paragraph_1: "10 školskih časova.",
	// course_weekend_prenatal_schedule_paragraph_2: "Po 5 školskih časova na dan.",
	course_weekend_prenatal_price_header_txt: "Cena kursa",
	course_weekend_prenatal_price_paragraph_1: "10.000 RSD",
	course_weekend_prenatal_included_header_txt: "Šta je uključeno u cenu?",
	course_weekend_prenatal_included_course_weekend_prenatal_list: ["- Predavanje", "- Praksa", "- Materijal za praktično učenje masaže trudnica (stolovi, ulja, peškiri, itd.)", "- Literatura"],

	course_weekend_massage_for_two_title: "Kurs masaže za dvoje ",
	course_weekend_massage_for_two_for_whome_txt: "Kome je namenjen?",
	course_weekend_massage_for_two_for_whome_paragraph_1: "Kurs masaže za dvoje je vikend radionica masaže namenjen parovima, prijateljima i bliskim osobama koji žele da praktikuju masažu u kućnim uslovima.",
	course_weekend_massage_for_two_for_whome_paragraph_2: "Ako ste ikada hteli da Vaš partner ili neko od Vaših bliskih ljudi zna da Vam izmasira vrat, ili umorna stopala posle napornog dana na poslu - ovo je prilika da oboje naučite kako to da pružite jedan drugom.",
	course_weekend_massage_for_two_why_header_txt: "Cilj kursa",
	course_weekend_massage_for_two_why_paragraph_1: "Povezivanje sa bliskom osobom u vidu provedenog vremena na kursu, kao i sticanje osnovnog praktičnog znanja izvođenja masaže u kućnim uslovima.",
	course_weekend_massage_for_two_duration_header_txt: "Trajanje kursa",
	course_weekend_massage_for_two_duration_paragraph_1: "6 školskih časova.",
	course_weekend_massage_for_two_duration_paragraph_2: "3 školska časa, sa pauzama, po danu.",
	// course_weekend_massage_for_two_schedule_header_txt: "Raspored predavanja",
	// course_weekend_massage_for_two_schedule_paragraph_1: "6 školskih časova.",
	// course_weekend_massage_for_two_schedule_paragraph_2: "3 školska časa, sa pauzama, po danu.",
	course_weekend_massage_for_two_price_header_txt: "Cena kursa",
	course_weekend_massage_for_two_price_paragraph_1: "6.000 RSD (cena je za oboje).",
	course_weekend_massage_for_two_included_header_txt: "Šta je uključeno u cenu?",
	course_weekend_massage_for_two_included_course_weekend_prenatal_list: ["-Predavanje", "-Praktična demonstracija masaže", "-Materijal za praktično učenje masaže", "-Praktična vežba masaže u parovima, uz nadzor i sugestije edukatora."],
	course_weekend_massage_for_two_why_this_header: "Zašto izabrati ovaj kurs?",
	course_weekend_massage_for_two_why_this_course_weekend_prenatal_list: ["- Kroz zajedničku aktivnost ćete se unaprediti sa svojom bliskom osobom", "- Predavanja se održavaju u savremenoj i klimatizovanoj učinioci", "- Mi imamo višegodišnje iskustvo u edukaciji masaže", "- Učenje je u parovima, i u maloj grupi", "- Potpuno se povećujemo svakom paru"],
	course_weekend_massage_for_two_note_header: "Napomena",
	course_weekend_massage_for_two_note_pargraph_1: "Prijave za kurs se vrše do 19.06. na recepciji Centra za masažu Zen, pozivom na broj [+381 (0) 64 04 88 256](tel://+381 (0) 64 04 88 256) ili mailom na [office@zenmasaza.com](mailto://office@zenmasaza.com)",
	course_weekend_massage_for_two_note_pargraph_2: "Mesta su ograničena. U slučaju većeg broja prijavljenih, kurs će se izvoditi u više malih grupa.",


	// course_notice_paragraph_text: "Ukoliko imate nekih nedoumica pogledajte [najčeće postavljana pitanja](/rs/cesto-postavljana-pitanja), kontaktirajte nas na broj [+381 (0) 64 04 88 256](tel://+381 (0) 64 04 88 256) ili nam pišite na mail [office@zenmasaza.com](mailto://office@zenmasaza.com). Rado ćemo odgovoriti na sva vaša pitanja.",
	course_notice_paragraph_text: "Ukoliko razmišljate o upisu ili želite više informacija o kursu, kontaktirajte nas na broj [+381 (0) 64 04 88 256](tel://+381 (0) 64 04 88 256) ili nam pišite na mail [office@zenmasaza.com](mailto://office@zenmasaza.com). Rado ćemo odgovoriti na sva Vaša pitanja.",

	course_educators_educator_certificates_title: "Sertifikati i iskustvo",
	course_educators: [
		{
			image:employeeImages["kristina-jovcic"],
			name: "Kristina Jovčić",
			experience: [
				"✓ Diplomirani terapeut medicinske masaže - Australian Learning Group - New South Wales School of Massage, Sidney",
				"✓ Član australijske asocijacije medicinskih terapeuta - Association of massage therapist - [amt.org.au](http://amt.org.au)",
				"✓ Sertifikacija u oblasti onkološke masaže (masaže pacijenata obolelih od raka) - [www.oncologymassagetraining.com.au](http://www.oncologymassagetraining.com.au)",
				"✓ Sertifikacija u oblasti specijalizovanog programa masaža trudnica (prenatalna masaža) - [pregnancymassageaustralia.com.au](http://pregnancymassageaustralia.com.au)",
				"✓ Sertifikacija u oblasti manuelnih tretmana za lice -The Comité International d'Esthétique et de Cosmétologie (International Committee of Aesthetics and Cosmetology)",
				"✓ Predavač masaže sa 5+ godina iskustva u Srbiji, Australiji i na Kajmanskim ostrvima (Karibi)",
				"✓ Spa konsultant sa višegodišnjim internacionalnim iskustvom",
				"✓ Sound Massage with Tibetan bowl -  Peter Hess®"
			]
		},
		{
			image:employeeImages["milena-stefanovic"],
			name: "Milena Stefanović",
			experience: [
				"✓ Profesor sporta i fizičkog vaspitanja",
				"✓ Fizioterapeut",
				"✓ Iskustvo od 10+ godina kao terapeut u pružanju svih vrsta masaža, tretmana lica i tela",
				"✓ Višegodišnji član Zen tima",
			]
		},
		{
			image: "https://zenmasaza.com/api/employees/MilicaRistic.JPG",
			name: "Milica Todorović",
			experience: [
				"✓ Tereapeut i član Zen tima  sa 3+ godina iskustva u pružanju svih vrsta masaža, tretmana lica i tela",
				"✓ Specijalizovana za izvođenje terapeutskih masaža",
				"✓ 1+ godina iskustva asistiranja na Zen kursevima masaže",
			]
		}
	],

	forms_reservation_header_txt: "Zakažite termin",
	forms_reservation_paragraph_1: "Za neponovljivo Zen iskustvo, odaberite svoj idealni tretman i zakažite termin još danas.",
	forms_reservation_paragraph_2: "Kako bismo Vam olakšali izbor, potrudili smo se da Vam pružimo veliki odabir tretmana koji će ispuniti Vaša očekivanja. Pun izbor tretmana pogledajte u našoj [ponudi](/rs/tretmani).",
	forms_reservation_list_header: "Zakažite termin na jedan od tri načina:",
	forms_reservation_list: ["Kontaktirajte nas na [+381 (0) 64 04 88 256](tel:+381-64-04-88-256) i mi ćemo Vam pomoći u odabiru idealnog tretmana i zakazati termin koji Vam najviše odgovara.", "Posetite nas na adresi Milorada Veljkovića Špaje 14 (prvi sprat) i naša recepcija preporučiće Vam savršen tretman za Vas.", "Pošaljite nam upit i mi ćemo Vas kontaktirati u najkraćem roku."],
	forms_reservation_name: "VAŠE IME",
	forms_reservation_name_placeholder: "Ime Prezime",
	forms_reservation_email: "VAŠ E-MAIL",
	forms_reservation_email_placeholder: "ime.prezime@mail.com",
	forms_reservation_phone: "VAŠ KONTAKT TELEFON",
	forms_reservation_phone_placeholder: "+381 18 123 456",
	forms_reservation_message: "PITANJE",
	forms_reservation_button_txt: "POŠALJI",
	forms_reservation_paragraph_4: "Hvala Vam što nam verujete,",
	forms_reservation_paragraph_5: "Centar za masažu Zen",
	forms_voucher_name: "VAUČER KUPUJE",
	forms_voucher_phone: "KONTAKT TELEFON",
	forms_voucher_email: "VAŠ E-MAIL",
	forms_voucher_category: "IZABERITE KATEGORIJU",
	forms_voucher_treatment_name: "IZABERITE TRETMAN",
	forms_voucher_duration: "IZABERITE TRAJANJE TRETMANA",
	forms_voucher_price: "CENA VAUČERA (RSD)",
	forms_voucher_for: "VAUČER PRIMA",
	forms_voucher_for_phone: "KONTAKT TELEFON",
	forms_voucher_radio_header: "KAKO ŽELITE DA PREUZMETE VAUČER? (OZNAČITE POLJE)",
	forms_voucher_radio_1: "Želim da vaučer fizički preuzmem u Zenu",
	forms_voucher_radio_2: "Želim da vaučer stigne na kućnu adresu osobi kojoj poklanjam vaučer (plaća se dodatnih 100,00 RSD)",
	forms_voucher_radio_2_extra: "ADRESA",
	forms_voucher_radio_3: "Želim da preuzmem online vaučer, preko mail-a",
	forms_voucher_radio_3_extra: "E-MAIL",
	forms_voucher_button_txt: "POŠALJI",

	forms_consultation_regular_title: "KOSULTACIJA",
	forms_consultation_regular_paragraph_1: "Poštovani/a,",
	forms_consultation_regular_paragraph_2: "Ako dolazite prvi put na profesionalnu masažu ili niste sigurni koji tretman je pravi izbor za vas, Molimo Vas da popunite jednu od konsultacija, u zavisnosti od Vašeg stanja, nakon koje možete očekivati našu preporuku.",
	forms_consultation_regular_paragraph_3: "Navedene informacije pomoćiće našim terapeutima da Vaše iskustvo u Zenu bude jedinstveno i za pamćenje.",
	forms_consultation_regular_paragraph_4: "Hvala Vam što nam verujete,\nVaš Zen.",
	forms_consultation_regular_first_name: "VAŠE IME",
	forms_consultation_regular_last_name: "VAŠE PREZIME",
	forms_consultation_regular_email: "VAŠ E-MAIL",
	forms_consultation_regular_phone: "VAŠ KONTAKT TELEFON",
	forms_consultation_regular_therapist_gender: "ŽELJENI POL TERAPEUTA",
	forms_consultation_regular_therapist_gender_list: ["Svejedno", "Muški", "Ženski"],
	forms_consultation_regular_radio_age_group_title: "GRUPA GODINA",
	forms_consultation_regular_radio_age_group_items: ["Do 21", "Od 22 do 35", "Od 36 do 55", "Preko 56"],
	forms_consultation_regular_check_condition_title: "DA LI IMATE NEKA OD NAVEDENIH STANJA?",
	forms_consultation_regular_check_condition_items: ["Nedavne operacije", "Diskus hernija/Diskopatija", "Bol u donjem delu leđa", "Artritis", "Alergija", "Bol u vratu ili ruci", "Spazam mišića ('grčevi')", "Kancer", "Trudnoća", "Povišeni/sniženi krvni pritisak", "Venska tromboza", "Epilepsija", "Osteoporoza", "Migrene/Glavobolje", "Stres", "Bol/Povreda ramena", "Bivši prelomi/Uganuća/Istegnuća", "Proširene vene", "Istorija kancera", "Uklonjeni limfni čvorovi"],
	forms_consultation_regular_radio_expectation_title: "ŠTA OČEKUJETE OD TRETMANA?",
	forms_consultation_regular_radio_expectation_items: ["Relaksaciju", "Otklanjanje bola", "Opuštanje mišićne tenzije", "Oslobađanje od stresa"],
	forms_consultation_regular_radio_massage_frequency_title: "KOLIKO ČESTO IDETE NA MASAŽU?",
	forms_consultation_regular_radio_massage_frequency_items: ["Minimum jednom mesečno", "Jednom ili dva puta godišnje", "Nikada nisam imao/la profesionalnu masažu"],
	forms_consultation_regular_radio_sport_frequency_title: "DA LI SE BAVITE FIZIČKOM AKTIVNOŠĆU?",
	forms_consultation_regular_radio_sport_frequency_items: ["Da", "Ne"],
	forms_consultation_regular_sport: "KOJIM SPORTOM? KOLIKO PUTA NEDELJNO?",
	forms_consultation_regular_radio_heard_about_title: "KAKO STE ČULI ZA NAS?",
	forms_consultation_regular_radio_heard_about_items: ["Facebook", "Instagram", "Videla sam salon u prolazu", "Preporuka", "Bilbord", "Dobila sam vaučer", "Drugo"],
	forms_consultation_regular_other: "DRUGO?",
	forms_consultation_regular_button_txt: "POŠALJI",
	forms_consultation_regular_paragraph_5: "Pročitao/la sam konsultaciju i odgovaram za tačnost napisanog. Svog terapeuta sam obavestio/la o svom psiho-fizičkom stanju i jasno izneo/la sve relevantne informacije o svom zdravlju.",
	forms_consultation_prenatal_title: "ZA TRUDNICE",
	forms_consultation_prenatal_first_name: "VAŠE IME",
	forms_consultation_prenatal_last_name: "VAŠE PREZIME",
	forms_consultation_prenatal_email: "VAŠ E-MAIL",
	forms_consultation_prenatal_phone: "VAŠ KONTAKT TELEFON",
	forms_consultation_prenatal_emergency_phone: "KONTAKT OSOBE U HITNIM SLUČAJEVIMA",
	forms_consultation_prenatal_num_pregnancies: "BROJ PRETHODNIH TRUDNOĆA",
	forms_consultation_prenatal_radio_risky_pregnancy_title: "DA LI SU VAŠE PRETHODNE TRUDNOĆE BILE RIZIČNE U BILO KOM POGLEDU?",
	forms_consultation_prenatal_radio_risky_pregnancy_items: ["Da", "Ne"],
	forms_consultation_prenatal_pregnancy_weeks: "KOLIKO NEDELJA JE ODMAKLA VAŠA TRUDNOĆA NA DAN POPUNJAVANJA KONSULTACIJE?",
	forms_consultation_prenatal_radio_pregnancy_weeks_title: "DA LI STE RANIJE IŠLI NA PRENATALNU MASAŽU?",
	forms_consultation_prenatal_radio_pregnancy_weeks_items: ["Da", "Ne"],
	forms_consultation_prenatal_medicament: "DA LI TRENUTNO PIJETE LEKOVE I AKO DA, KOJE?",
	forms_consultation_prenatal_radio_visit_reason_title: "KOJI JE GLAVNI RAZLOG VAŠE POSETE DANAS?",
	forms_consultation_prenatal_radio_visit_reason_items: ["Relaksacija", "Opuštanje mišićne tenzije", "Limfna drenaža", "Sve od navedenog"],
	forms_consultation_prenatal_radio_massage_frequency_title: "KOLIKO ČESTO IDETE NA MASAŽU?",
	forms_consultation_prenatal_radio_massage_frequency_items: ["Retko - par puta godišnje", "Redovno - minimum jednom mesečno", "Nikad nisam imala profesionalnu masažu"],
	forms_consultation_prenatal_radio_massage_pressure_title: "KAKAV PRITISAK U MASAŽI PREFERIRATE?",
	forms_consultation_prenatal_radio_massage_pressure_items: ["Blagi, relaksirajuci", "Srednji", "Srednji ka jačem", "Snažan"],
	forms_consultation_prenatal_check_medical_condition_title: "U SLEDEĆOJ KOLONI NAGLASITE UKOLIKO NEŠTO OD NAVEDENOG OPISUJE VAŠE TRENUTNO MEDICINSKO STANJE ILI UKOLIKO STE NEŠTO ISKUSILI U RANIJIM DANIMA TRUDNOĆE:",
	forms_consultation_prenatal_check_medical_condition_items: ["Oslabljena cirkulacija", "Bivše povrede", "Problemi sa srcem", "Posteoporoza/Artritis", "Proširene vene", "Alergija na neke preparate", "Osetljiva koža", "Osetljivost na ulje badema", "Glavobolje", "Utrnuće nogu ili ruku", "Upala bešike", "Preeklampsija (povišeni krvni pritisak)", "Gestacijski dijabetes", "Jaka i uporna bol u području abdomena", "Depresija", "Vaginalno krvarenje", "Bolno mokrenje s osećajem žarenja", "Glavobolja popraćena smetnjama s vidom ili govorom", "Česti grčevi u nogama, bolovi u listovima", "Bol u donjem delu leđa", "Bol u vratu/ramenu/ruci", "Bol u predelu kukova", "Dijastaza pubične simfize", "Otok u nogama/rukama", "Istorija tromba", "Istorija prevremenog porođaja", "Dijastaza trbušnih mišića", "Česta mučnina", "Bivša uganuća-istegnuća"],
	forms_consultation_prenatal_medical_condition_other: "OSTAVITE NAPOMENU UKOLIKO POSTOJI STANJE KOJE NIJE NAVEDENO IZNAD",
	forms_consultation_prenatal_radio_heard_about_title: "KAKO STE ČULI ZA NAS?",
	forms_consultation_prenatal_radio_heard_about_items: ["Facebook", "Instagram", "I saw Zen while passing by", "Recommendation", "Billboard", "I’ve received a Zen voucher", "Other"],
	forms_consultation_prenatal_other: "DRUGO?",
	forms_consultation_prenatal_button_txt: "POŠALJI",

	forms_career_title_1: "Započni svoju karijeru u Zenu!",
	forms_career_paragraph_1: "Mi u Centru za masažu Zen, sa ponosom, zapošljavamo kreativne i sposobne ljude koji dele slične vrednosti koje negujemo.",
	forms_career_paragraph_2: "Profesionalnost, ljubaznost i kvalitetna masaža su sinonimi za Zen, već godinama.",
	forms_career_paragraph_3: "Ako si i ti željan/a profesije kojom ćeš se ponositi – postani deo našeg tima.",
	forms_career_paragraph_4: "Prijavi se!",
	forms_career_title_2: "Naša priča",
	forms_career_paragraph_5: "Od kada smo, pre šest godina, otvorili svoja vrata Nišlijama i pružili im vanvremensko iskustvo masaže spa centra od pet zvezdica, naša priča postaje prava bajka.",
	forms_career_paragraph_6: "Osim zaštitnog pečata – masaže Zena koja je uvek unikatna i posebna, želja nam je da postanemo vodeći edukativni centar budućih terapeuta jer verujemo u mlade ljude i njihov potencijal.",
	forms_career_title_3: "Naše vrednosti",
	forms_career_paragraph_7: "Zen po prirodi predstavlja balans. Naša filozofija je na tome i zasnovana. Kako bi se ostvario balans potrebno je biti umeren u svemu, imati ukusa i kreativne energije.",
	forms_career_paragraph_8: "Zen je mesto u kome ljudi mogu da pronađu svoj mir dok traje masaža, da zavole sebe i osećaju se bolje. Zato i terapeuti moraju nositi mir sa sobom, biti zadovoljni da bi uživali u ovom nesebičnom pozivu.",
	forms_career_title_4: "Prijavi se za posao",
	forms_career_paragraph_9: "Ako imaš predznanje o masaži (bez obzira na radno iskustvo), ako si timski radnik, želiš fleksibilno radno vreme, posao koji nije dosadan - prijavi se i mi ćemo te kontaktirati! Jer, Zen je način života više nego sam posao.",
	forms_career_title_5: "Dobro došao/la!",
	forms_career_name: "VAŠE IME I PREZIME",
	forms_career_name_placeholder: "Ime Prezime",
	forms_career_phone: "VAŠ KONTAKT TELEFON",
	forms_career_phone_placeholder: "+381 60 123 45 67",
	forms_career_email: "VAŠ E-MAIL",
	forms_career_email_placeholder: "ime.prezime@mail.com",
	forms_career_bio: "RECITE NAM NEŠTO VIŠE O SEBI...",
	forms_career_btn: "POŠALJI",

	forms_invalid_title: "Greška",
	forms_invalid_field: "Niste uneli validno polje",

	forms_info_title: "Obaveštenje",
	forms_info_message: "Uspešno ste poslali poruku.",

	forms_unavailable_paragraph_1: "Žao nam je, online kupovina trenutno nije dostupna.",
	forms_unavailable_paragraph_2: "Molimo pokušajte kasnije.",

	modal_close_btn: "ZATVORI",
	modal_confirm_btn: "POTVRDI",
};
