import noUser from "../assets/img/no-user.png";
import stefan_milosavljevic from "../assets/img/employees/stefan-milosavljevic.jpg";
import kristina_jovcic from "../assets/img/employees/kristina-jovcic.jpg";
import marija_stefanovic from "../assets/img/employees/marija-stefanovic.jpg";
import miljana_miljkovic from "../assets/img/employees/miljana-miljkovic.jpg";
import jelena_stankovic from "../assets/img/employees/jelena-stankovic.jpg";
import jovana_jankovic from "../assets/img/employees/jovana-jankovic.jpg";
import milena_stefanovic from "../assets/img/employees/milena-stefanovic.jpg";
import nevena_rapcan from "../assets/img/employees/nevena-rapcan.jpg";
import teodora_petrovic from "../assets/img/employees/teodora-petrovic.jpg";
import sara_ilic from "../assets/img/employees/sara-ilic.jpg";
import milica_ristic from "../assets/img/employees/milica-ristic.jpg";
import jelena_mladenovic from "../assets/img/employees/jelena-mladenovic.jpg";
import nikola_nedeljkovic from "../assets/img/employees/nikola-nedeljkovic.jpg";
import milica_ilic from "../assets/img/employees/milica-ilic.jpg";
import jovana_arsic from "../assets/img/employees/jovana-arsic.jpg";
import kristina_ljubic from "../assets/img/employees/kristina-ljubic.jpg";

const employeeImages: Record<any, any> = {
	"noUser": noUser,
	"milica-ilic": milica_ilic,
	"nikola-nedeljkovic": nikola_nedeljkovic,
	"stefan-milosavljevic": stefan_milosavljevic,
	"kristina-jovcic": kristina_jovcic,
	"marija-stefanovic": marija_stefanovic,
	"miljana-miljkovic": miljana_miljkovic,
	"jelena-stankovic": jelena_stankovic,
	"jovana-jankovic": jovana_jankovic,
	"milena-stefanovic": milena_stefanovic,
	"nevena-rapcan": nevena_rapcan,
	"teodora-petrovic": teodora_petrovic,
	"sara-ilic": sara_ilic,
	"milica-ristic": milica_ristic,
	"jelena-mladenovic": jelena_mladenovic,
	"jovana-arsic": jovana_arsic,
	"kristina-ljubic": kristina_ljubic,
};

export default employeeImages;
