import * as React from "react";
import {useLocale} from "../../hooks/useLocale";
import {semanticJoin, ownerFirst, translateTitle} from "../../util/util";

type EmployeeCardProps = {
	id: string;
	name: string;
	titles: string[];
	img: any;
	imgTitleProp:string;
};
export const EmployeeCard = (props: EmployeeCardProps) => {
	const lang = useLocale();

	return (
		<div className="employee-card col s12 m12 l4">
			<div className="image-container">
				<img src={props.img} alt="Zen je za mene" title={props.imgTitleProp} />
			</div>
			<h4 className="employee-name">{props.name}</h4>
			<h5 className="employee-title">{props.titles
				.sort(ownerFirst)
				.map(translateTitle(lang))
				.reduce(semanticJoin(lang))}</h5>
			<div className="employee-summary">
			</div>
		</div>
	);
};
