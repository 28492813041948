import * as React from "react";
import { createRef, FormEvent } from "react";
import "./Forms.css";
import { useModal } from "../../hooks/useModal";
import axios from "axios";
import langs from "../../localization/languages/languages";

type PrenatalFormProps = {
	action: string;
	isRequired: boolean;
	lang: string;
};

export const PrenatalConsultationForm = ({isRequired, action, lang}: PrenatalFormProps) => {
	const [modal, setModalProps, openModal] = useModal();
	const formRef = createRef<HTMLFormElement>();

	const onSubmit = async (ev: FormEvent) => {
		ev.preventDefault();
		const target = formRef.current as HTMLFormElement;
		let formData: Record<string, string|string[]> = {};
		Object.keys(target.elements).forEach((k) => {
			const elem = target.elements.item(parseInt(k)) as HTMLInputElement;

			if (elem.name !== "") {
				if (elem.type === "radio") {
					if (elem.checked) {
						formData[elem.name] = elem.value;
					}
				} else if (elem.type === "checkbox") {
					if (elem.checked) {
						if (!formData[elem.name]) {
							formData[elem.name] = [];
						}
						(formData[elem.name] as string[]).push(elem.value);
					}
				} else {
					formData[elem.name] = elem.value;
				}
			}
		});

		try {
			await axios.post(action, formData);
			setModalProps({title: langs[lang].forms_info_title, list: [langs[lang].forms_info_message]});
			openModal();
			target.reset();
		} catch (e) {
			let errors = e.response.data;
			let errorMessages = Object.keys(errors).map(key => `${key}: ${errors[key]}`)
			setModalProps({title: langs[lang].forms_invalid_title, list: errorMessages});
			// console.log(e.response.data);
			openModal();
			// console.dir(e);
			return;
		}
	};

	return (
		<form onSubmit={onSubmit} ref={formRef} action={action} method="POST">
			{modal}
			<div className="row">
				<div className="input-field col s12 m12 l6">
					<input
						placeholder=""
						name="first_name"
						type="text"
						required={isRequired}
					/>
					<label htmlFor="first_name">{langs[lang].forms_consultation_prenatal_first_name}</label>
				</div>
				<div className="input-field col s12 m12 l6">
					<input
						placeholder=""
						name="last_name"
						type="text"
						required={isRequired}
					/>
					<label htmlFor="last_name">{langs[lang].forms_consultation_prenatal_last_name}</label>
				</div>
			</div>
			<div className="row mb-0">
				<div className="input-field col s12 m12 l12">
					<input
						placeholder="example@mail.com"
						name="email"
						type="email"
						required={isRequired}
					/>
					<label htmlFor="email">{langs[lang].forms_consultation_prenatal_email}</label>
				</div>
			</div>
			<div className="row mb-0">
				<div className="input-field col s12 m12 l6">
					<input
						placeholder="+381 60 00 00 000 "
						name="phone"
						type="text"
						required={isRequired}
					/>
					<label htmlFor="name">{langs[lang].forms_consultation_prenatal_phone}</label>
				</div>
				<div className="input-field col s12 m12 l6">
					<input
						placeholder="+381 60 00 00 000 "
						name="emergency_phone"
						type="text"
						required={isRequired}
					/>
					<label htmlFor="phone">{langs[lang].forms_consultation_prenatal_emergency_phone}</label>
				</div>
			</div>
			<div className="row">
				<div className="input-field col s12 m12 l6">
					<input
						placeholder="0"
						name="num_pregnancies"
						type="text"
						required={isRequired}
					/>
					<label htmlFor="phone">{langs[lang].forms_consultation_prenatal_num_pregnancies}</label>
				</div>
				<div className="col s12 m12 l6">
					<label className="input-label">{langs[lang].forms_consultation_prenatal_radio_risky_pregnancy_title}</label>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="risky_pregnancy"
								value="Da"
								type="radio"
								defaultChecked={true}
								required={isRequired}
							/>
							<span>{langs[lang].forms_consultation_prenatal_radio_risky_pregnancy_items[0]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="risky_pregnancy"
								value="Ne"
								type="radio"
								required={isRequired}
							/>
							<span>{langs[lang].forms_consultation_prenatal_radio_risky_pregnancy_items[1]}</span></label>
					</div>
				</div>
			</div>
			<div className="row mb-2">
				<div className="input-field col s12 m12 l6">
					<input
						placeholder="3"
						name="pregnancy_weeks"
						type="text"
						required={isRequired}
					/>
					<label className="multiline-label-2" htmlFor="pregnancy_weeks">{langs[lang].forms_consultation_prenatal_pregnancy_weeks}</label>
				</div>
				<div className="col s6 m6 l6">
					<label className="input-label">{langs[lang].forms_consultation_prenatal_radio_pregnancy_weeks_title}</label>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="prenatal_experience"
								value="Da"
								type="radio"
								defaultChecked={true}
								required={isRequired}
							/>
							<span>{langs[lang].forms_consultation_prenatal_radio_pregnancy_weeks_items[0]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="prenatal_experience"
								value="Ne"
								type="radio"
								required={isRequired}
							/>
							<span>{langs[lang].forms_consultation_prenatal_radio_pregnancy_weeks_items[1]}</span></label>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="input-field col s12 m12 l6">
					<textarea
						name="medicament"
						className="materialize-textarea"
					/>
					<label className="multiline-label-1" htmlFor="medicament">{langs[lang].forms_consultation_prenatal_medicament}</label>
				</div>
				<div className="col s12 m12 l6">
					<label className="input-label">{langs[lang].forms_consultation_prenatal_radio_visit_reason_title}</label>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="visit_reason"
								value="Relaksacija"
								type="radio"
								defaultChecked={true}
							/>
							<span>{langs[lang].forms_consultation_prenatal_radio_visit_reason_items[0]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="visit_reason"
								value="Opuštanje mišićne tenzije"
								type="radio"
							/>
							<span>{langs[lang].forms_consultation_prenatal_radio_visit_reason_items[1]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="visit_reason"
								value="Limfna drenaža"
								type="radio"
							/>
							<span>{langs[lang].forms_consultation_prenatal_radio_visit_reason_items[2]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="visit_reason"
								value="Sve od navedenog"
								type="radio"
							/>
							<span>{langs[lang].forms_consultation_prenatal_radio_visit_reason_items[3]}</span></label>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col s12 m12 l6">
					<label className="input-label">{langs[lang].forms_consultation_prenatal_radio_massage_frequency_title}</label>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="massage_frequency"
								value="Retko - par puta godišnje"
								type="radio"
								required={isRequired}
							/>
							<span>{langs[lang].forms_consultation_prenatal_radio_massage_frequency_items[0]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="massage_frequency"
								value="Redovno - minimum jednom mesečno"
								type="radio"
								required={isRequired}
							/>
							<span>{langs[lang].forms_consultation_prenatal_radio_massage_frequency_items[1]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="massage_frequency"
								value="Nikad nisam imala profesionalnu masažu"
								type="radio"
								required={isRequired}
								defaultChecked={true}
							/>
							<span>{langs[lang].forms_consultation_prenatal_radio_massage_frequency_items[2]}</span></label>
					</div>
				</div>
				<div className="col s12 m12 l6">
					<label className="input-label">{langs[lang].forms_consultation_prenatal_radio_massage_pressure_title}</label>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="massage_pressure"
								value="Blagi, relaksirajuci"
								type="radio"
								defaultChecked={true}
								required={isRequired}
							/>
							<span>{langs[lang].forms_consultation_prenatal_radio_massage_pressure_items[0]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="massage_pressure"
								value="Srednji"
								type="radio"
								defaultChecked={true}
								required={isRequired}
							/>
							<span>{langs[lang].forms_consultation_prenatal_radio_massage_pressure_items[1]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="massage_pressure"
								value="Srednji ka jačem"
								type="radio"
								defaultChecked={true}
								required={isRequired}
							/>
							<span>{langs[lang].forms_consultation_prenatal_radio_massage_pressure_items[2]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="massage_pressure"
								value="Snažan"
								type="radio"
								defaultChecked={true}
								required={isRequired}
							/>
							<span>{langs[lang].forms_consultation_prenatal_radio_massage_pressure_items[3]}</span></label>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col s12 m12 l6">
					<label className="input-label">{langs[lang].forms_consultation_prenatal_check_medical_condition_title}</label>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="medical_condition"
								value="Oslabljena cirkulacija"
								className="filled-in"
								type="checkbox"
							/>
							<span>{langs[lang].forms_consultation_prenatal_check_medical_condition_items[0]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="medical_condition"
								value="Bivše povrede"
								className="filled-in"
								type="checkbox"
							/>
							<span>{langs[lang].forms_consultation_prenatal_check_medical_condition_items[1]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="medical_condition"
								value="Problemi sa srcem"
								className="filled-in"
								type="checkbox"
							/>
							<span>{langs[lang].forms_consultation_prenatal_check_medical_condition_items[2]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="medical_condition"
								value="Osteoporoza/Artritis"
								className="filled-in"
								type="checkbox"
							/>
							<span>{langs[lang].forms_consultation_prenatal_check_medical_condition_items[3]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="medical_condition"
								value="Proširene vene"
								className="filled-in"
								type="checkbox"
							/>
							<span>{langs[lang].forms_consultation_prenatal_check_medical_condition_items[4]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="medical_condition"
								value="Alergija na neke preparate"
								className="filled-in"
								type="checkbox"
							/>
							<span>{langs[lang].forms_consultation_prenatal_check_medical_condition_items[5]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="medical_condition"
								value="Osetljiva koža"
								className="filled-in"
								type="checkbox"
							/>
							<span>{langs[lang].forms_consultation_prenatal_check_medical_condition_items[6]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="medical_condition"
								value="Osetljivost na ulje badema"
								className="filled-in"
								type="checkbox"
							/>
							<span>{langs[lang].forms_consultation_prenatal_check_medical_condition_items[7]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="medical_condition"
								value="Glavobolje"
								className="filled-in"
								type="checkbox"
							/>
							<span>{langs[lang].forms_consultation_prenatal_check_medical_condition_items[8]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="medical_condition"
								value="Utrnuće nogu ili ruku"
								className="filled-in"
								type="checkbox"
							/>
							<span>{langs[lang].forms_consultation_prenatal_check_medical_condition_items[9]}</span></label>
					</div>

					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="medical_condition"
								value="Upala bešike"
								className="filled-in"
								type="checkbox"
							/>
							<span>{langs[lang].forms_consultation_prenatal_check_medical_condition_items[10]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="medical_condition"
								value="Preeklampsija (povišeni krvni pritisak)"
								className="filled-in"
								type="checkbox"
							/>
							<span>{langs[lang].forms_consultation_prenatal_check_medical_condition_items[11]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="medical_condition"
								value="Gestacijski dijabetes"
								className="filled-in"
								type="checkbox"
							/>
							<span>{langs[lang].forms_consultation_prenatal_check_medical_condition_items[12]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="medical_condition"
								value="Jaka i uporna bol u području abdomena"
								className="filled-in"
								type="checkbox"
							/>
							<span>{langs[lang].forms_consultation_prenatal_check_medical_condition_items[13]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="medical_condition"
								value="Depresija"
								className="filled-in"
								type="checkbox"
							/>
							<span>{langs[lang].forms_consultation_prenatal_check_medical_condition_items[14]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="medical_condition"
								value="Vaginalno krvarenje"
								className="filled-in"
								type="checkbox"
							/>
							<span>{langs[lang].forms_consultation_prenatal_check_medical_condition_items[15]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="medical_condition"
								value="Bolno mokrenje s osećajem žarenja"
								className="filled-in"
								type="checkbox"
							/>
							<span>{langs[lang].forms_consultation_prenatal_check_medical_condition_items[16]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="medical_condition"
								value="Glavobolja popraćena smetnjama s vidom ili govorom"
								className="filled-in"
								type="checkbox"
							/>
							<span>{langs[lang].forms_consultation_prenatal_check_medical_condition_items[17]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="medical_condition"
								value="Česti grčevi u nogama, bolovi u listovima"
								className="filled-in"
								type="checkbox"
							/>
							<span>{langs[lang].forms_consultation_prenatal_check_medical_condition_items[18]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="medical_condition"
								value="Bol u donjem delu leđa"
								className="filled-in"
								type="checkbox"
							/>
							<span>{langs[lang].forms_consultation_prenatal_check_medical_condition_items[19]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="medical_condition"
								value="Bol u vratu/ramenu/ruci"
								className="filled-in"
								type="checkbox"
							/>
							<span>{langs[lang].forms_consultation_prenatal_check_medical_condition_items[20]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="medical_condition"
								value="Bol u predelu kukova"
								className="filled-in"
								type="checkbox"
							/>
							<span>{langs[lang].forms_consultation_prenatal_check_medical_condition_items[21]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="medical_condition"
								value="Dijastaza pubične simfize"
								className="filled-in"
								type="checkbox"
							/>
							<span>{langs[lang].forms_consultation_prenatal_check_medical_condition_items[22]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="medical_condition"
								value="Otok u nogama/rukama"
								className="filled-in"
								type="checkbox"
							/>
							<span>{langs[lang].forms_consultation_prenatal_check_medical_condition_items[23]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="medical_condition"
								value="Istorija tromba"
								className="filled-in"
								type="checkbox"
							/>
							<span>{langs[lang].forms_consultation_prenatal_check_medical_condition_items[24]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="medical_condition"
								value="Istorija prevremenog porođaja"
								className="filled-in"
								type="checkbox"
							/>
							<span>{langs[lang].forms_consultation_prenatal_check_medical_condition_items[25]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="medical_condition"
								value="Dijastaza trbušnih mišića"
								className="filled-in"
								type="checkbox"
							/>
							<span>{langs[lang].forms_consultation_prenatal_check_medical_condition_items[26]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="medical_condition"
								value="Česta mučnina"
								className="filled-in"
								type="checkbox"
							/>
							<span>{langs[lang].forms_consultation_prenatal_check_medical_condition_items[27]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="medical_condition"
								value="Bivša uganuća-istegnuća"
								className="filled-in"
								type="checkbox"
							/>
							<span>{langs[lang].forms_consultation_prenatal_check_medical_condition_items[28]}</span></label>
					</div>
				</div>
				<div className="input-field col s12 m12 l6 mb-0">
					<div>
						<label>{langs[lang].forms_consultation_prenatal_medical_condition_other}</label>
					</div>
					<textarea
						name="medical_condition_other"
						className="materialize-textarea"
					/>
				</div>
			</div>
			<div className="row">
				<div className="col s12 m12 l6">
					<label className="input-label">{langs[lang].forms_consultation_prenatal_radio_heard_about_title}</label>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="heard_about"
								value="Facebook"
								type="radio"
								defaultChecked={true}
								required={isRequired}
							/>
							<span>{langs[lang].forms_consultation_prenatal_radio_heard_about_items[0]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="heard_about"
								value="Instagram"
								type="radio"
								required={isRequired}
							/>
							<span>{langs[lang].forms_consultation_prenatal_radio_heard_about_items[1]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="heard_about"
								value="Videla sam salon u prolazu"
								type="radio"
								required={isRequired}
							/>
							<span>{langs[lang].forms_consultation_prenatal_radio_heard_about_items[2]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="heard_about"
								value="Preporuka"
								type="radio"
								required={isRequired}
							/>
							<span>{langs[lang].forms_consultation_prenatal_radio_heard_about_items[3]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="heard_about"
								value="Bilbord"
								type="radio"
								required={isRequired}
							/>
							<span>{langs[lang].forms_consultation_prenatal_radio_heard_about_items[4]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="heard_about"
								value="Dobila sam vaučer"
								type="radio"
								required={isRequired}
							/>
							<span>{langs[lang].forms_consultation_prenatal_radio_heard_about_items[5]}</span></label>
					</div>
					<div className="mb-2">
						<label className="input-checkbox">
							<input
								name="heard_about"
								value="Drugo"
								type="radio"
								required={isRequired}
							/>
							<span>{langs[lang].forms_consultation_prenatal_radio_heard_about_items[6]}</span></label>
					</div>
				</div>
				<div className="input-field col s12 m12 l6">
					<textarea name="other" className="materialize-textarea"/>
					<label>{langs[lang].forms_consultation_prenatal_other}</label>
				</div>
			</div>
			<div className="row">
				<div className="col s12">
					<button className="btn-dark-gold" type="submit">
						{langs[lang].forms_consultation_prenatal_button_txt}
					</button>
				</div>
			</div>
		</form>
	);
};
