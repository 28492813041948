import axios from "axios";
import { backendServerUrl } from "../globals";
import { defaultLocale } from "../localization/locales";

const getAll = async (locale = defaultLocale): Promise<Employee[]> => {
	return (await axios.get(`${backendServerUrl}/employees?lang=${locale}`)).data;
};

const employeeServices: EmployeeServices = {
	getAll,
};

export default employeeServices;
