import { RouteStrings } from "../RouteStrings";

export const en: RouteStrings = {
	home: "home",
	treatments: "treatments",
	courses: "courses",
	courses_level1: "courses/level-1",
	courses_level2: "courses/level-2",
	courses_level_weekend_seminar: "courses/seminar",

	blog: "blog",
	contact: "contact",

	career: "career",
	consultation: "consultation",
	voucher: "voucher",
	reservation: "reservation",
	purchase:"purchase",

	about: "about",
	faq: "faq",
};
