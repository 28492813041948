import * as React from "react";
import { useEffect, useState } from "react";
import { RouteComponentProps, useLocation } from "react-router-dom";
import { useLocale } from "../../hooks/useLocale";
import purchaseServices from "../../services/purchaseServices";

type PurchaseSuccessProps = {};
export const PurchaseSuccess = (props: PurchaseSuccessProps & RouteComponentProps) => {
	const [verifyData, setVerifyData] = useState<PurchaseVerifyResponse | null>(null);
	const location = useLocation();
	const query = new URLSearchParams(location.search);
	const locale = useLocale();

	useEffect(() => {
		const orderId = query.get("orderId");
		if (orderId)
			purchaseServices.verify(orderId, locale).then(data => {
				setVerifyData(data);
			}).catch(err => {
				console.error(err);
				props.history.push(`/${locale}/purchase/failure?orderId=${orderId}`);
			});
		// eslint-disable-next-line
	}, []);
	return (
		<div className="container purchase-container">
			<div className="row">
				<div className="col s12">
					{verifyData ?
						<div>
							<p className="white-text">Poštovani/a,</p>
							<p className="white-text">Uspešno ste izvršili kupovinu vaučera <span
								className="gold">{verifyData["treatment_name"]}</span>.</p>
							<h4 className="white-text">Šifra Vašeg vaučera je: <span
								className="gold">{verifyData["OrderID"]}</span></h4>
							<p className="white-text">Na unetu email adresu (<span
								className="gold">{verifyData["Email"]}</span>), stićiće mail sa više detalja.</p>
							<h6 className="white-text">Sve što treba da znate, ukratko:</h6>
							<p className="white-text">Sa ovom šifrom možete podići vaučer u prostorijama Zena, svakog
								radnog dana i subotom od 10 do 22, a nedeljom od 12 do 20 časova.</p>
							<p className="white-text">U slučaju opredeljenja za pristizanje vaučera poštom, rok isporuke
								je 3 dana.</p>
							<p className="white-text">Ako ste se odlučili za online varijantu vaučera, korisnik prilikom
								dolaska na tretman na recepciji mora pokazati PDF vaučer ili reći šifru vaučera.</p>
							<p className="white-text">Vaučer se može iskoristi u 30 dana od datuma kupovine.</p>
							<p className="white-text">Hvala Vam što nam verujete,<br/>Vaš Zen.</p>
						</div>
						:
						<div className="progress">
							<div className="indeterminate"/>
						</div>
					}
				</div>
			</div>
		</div>
	);
};
