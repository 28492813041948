import * as React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Header.css";
import axios from "axios";
import {HeaderSlide} from "./HeaderSlide";
import { useLocale } from "../../hooks/useLocale"; import { backendServerUrl } from "../../globals";
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Fader} from "../fader/Fader";

interface Slide {
	image: any;
	imageMobile?: any;
	text: any;
	links: any[];
	[key: string]: any;
}

interface SlideLinkDto {
	id:number;
	locale: string;
	text: string
	href: string;

}

interface SlideDto {
	id:number;
	title: string;
	locale: string;
	textUrl: string;
	backgroundUrl: string;
	backgroundMobileUrl: string;
	links: SlideLinkDto[];
}

export const Header = () => {
	const lang = useLocale();
	const [slidesArray, setSlidesArray] = useState<Slide[]>([]);

	const [carouselItems, setCarouselItems] = useState<React.ReactChild[]>([] as React.ReactChild[]);

	const slideChangeInterval = 6000;

	useEffect(() => {
		const slides: any[] = slidesArray.map((slide)  => {
			return <HeaderSlide key={slide.links[0]} backImg={slide.image} mobileImg={slide.imageMobile} textImg={slide.text} links={[slide.links]}/>
		});
		setCarouselItems(slides);
	}, [slidesArray]);

	useEffect(() => {
		axios.get(backendServerUrl + "/slides?lang=" + lang)
			.then(res => {
				setSlidesArray((res.data as SlideDto[]).map(dto => {
					return {
						image: backendServerUrl + dto.backgroundUrl,
						imageMobile: dto.backgroundMobileUrl ? backendServerUrl + dto.backgroundMobileUrl : undefined,
						text: backendServerUrl + dto.textUrl,
						links: dto.links.map(link => {
							if (link.href.startsWith("http")) {
								return <a key={link.href} target="_blank" rel="noopener noreferrer" href={link.href}>{link.text}</a>;
							} else if (link.href.startsWith("/api")) {
								return <a key={link.href} href={backendServerUrl + link.href.substring("/api".length)}>{link.text}</a>;
							} else {
								return <Link key={link.href} to={link.href}>{link.text}</Link>;
							}
						})
					}
				}))
			})
	}, [lang])

	return (
		<header>
			<Carousel
				showThumbs={false}
				autoPlay={true}
				showStatus={false}
				swipeable={false}
				infiniteLoop={true}
				interval={slideChangeInterval}
				renderArrowPrev={(onClickHandler, hasPrev, label) => {
					return <button className="carousel-prev" onClick={onClickHandler}>
						<i className="material-icons">chevron_left</i>
					</button>
				}}
				renderArrowNext={(onClickHandler, hasNext, label) => {
					return <button className="carousel-next" onClick={onClickHandler}>
						<i className="material-icons">chevron_right</i>
					</button>
				}}
				className="h-100">
				{carouselItems}
			</Carousel>
			<Fader/>
		</header>
	);
};
