import React, { useEffect, useRef, useState } from "react";
import { Header } from "../../components/header/Header";
import "./HomePage.css";
import rsCircleImg from "../../assets/img/home-page-circle.png";
import enCircleImg from "../../assets/img/en/loveurself.png";
import clockImg from "../../assets/img/clock-icon.png";
import questionImg from "../../assets/img/question-mark-icon.png";
import mailImg from "../../assets/img/mail-icon.png";
import { CircleButton } from "../../components/buttons/CircleButton";
import { Link } from "react-router-dom";
import { defaultLocale, locales } from "../../localization/locales";
import langs from "../../localization/languages/languages";
import { localizeLinkTo } from "../../util/localization";
import routes from "../../localization/routes/routes";
import {backendServerUrl} from "../../globals";

export const HomePage = (props: any) => {
	let interval = useRef<NodeJS.Timeout>();
	let {lang = defaultLocale} = props.match?.params;
	if (locales.indexOf(lang) === -1) {
		lang = defaultLocale;
	}
	const localizedImg: Record<string, any> = {
		rs: rsCircleImg,
		en: enCircleImg,
	};

	const [testimony, setTestimony] = useState(langs[lang].testimonials[0]);
	useEffect(() => {
		window.scrollTo({top: 0, behavior: "smooth"});
		interval.current = setInterval(() => {
			const randNum = Math.floor(Math.random() * langs[lang].testimonials.length);
			setTestimony(langs[lang].testimonials[randNum]);
		}, 5000);
		// eslint-disable-next-line
	}, []);
	return (
		<div className="home-body">
			<Header/>
			<div className="container pt-5">
				<div className="about row pt-1">
					<div className="col s12 m4 l4 circle-image">
						<img src={localizedImg[lang]} alt=""/>
					</div>
					<div className="col s12 m8 l8 about-text mt-4">
						<h3>{langs[lang].home_about_heading_1}</h3>
						{langs[lang].home_about_text.map(text => <p key={text}>{text}</p>)}
						<div className="row about-buttons">
							<div className="col s12 m12 l12 btn-contain">
								<button>
									<Link to={localizeLinkTo(`/${routes[lang].about}`, lang)}>
										{langs[lang].home_about_button_text_1}
									</Link>
								</button>
								<button>
									<Link to={localizeLinkTo(`/${routes[lang].career}`, lang)} target="_blank">
										{langs[lang].home_about_button_text_2}
									</Link>
								</button>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col s12 m4 l4 pt-2 d-flex justify-content-center">
						<CircleButton
							link={localizeLinkTo(`/${routes[lang].reservation}`, lang)}
							size={200}
							img={clockImg}
							caption={<span>{langs[lang].home_circle_button_text_1_1.toUpperCase()}</span>}/>
					</div>
					<div className="col s12 m4 l4 pt-2 d-flex justify-content-center">
						<CircleButton
							link={localizeLinkTo(`/${routes[lang].consultation}`, lang)}
							size={200}
							img={questionImg}
							caption={<span>{langs[lang].home_circle_button_text_2_1.toUpperCase()}</span>}/>
					</div>
					<div className="col s12 m4 l4 pt-2 d-flex justify-content-center">
						<CircleButton
							link={`${backendServerUrl}/purchase`}
							size={200}
							img={mailImg}
							caption={<span>{langs[lang].home_circle_button_text_3_1.toUpperCase()}</span>}/>
					</div>
				</div>
				<div className="row">
					<div className="testimony">
						<h4>{testimony.name}</h4>
						<p>{testimony.text}</p>
					</div>
				</div>
			</div>
		</div>
	);
};
