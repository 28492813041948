import React, { createRef, useEffect, useState } from "react";
import { FAQElement } from "./FAQElement";
import "./FAQ.css";
import { defaultLocale } from "../../localization/locales";
import langs from "../../localization/languages/languages";

export const FAQ = (props: any) => {
	const [courseQA, setCourseQA] = useState<FAQQuestion[]>([]);
	const [treatmentQA, setTreatmentQA] = useState<FAQQuestion[]>([]);
	const tabsRef = createRef<HTMLUListElement>();
	const {lang = defaultLocale} = props.match?.params;


	useEffect(() => {
		window.scrollTo({top: 0, behavior: "smooth"});
		if (tabsRef.current) M.Tabs.init(tabsRef.current, {});

		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		setCourseQA(langs[lang].faq_course);
		setTreatmentQA(langs[lang].faq_treatment);
	}, [lang]);

	return (
		<div className="container faq-container">
			<div className="row">
				<h3 className="gold">{langs[lang].faq_page_heading_text}</h3>
				<div className="col s12">
					<ul ref={tabsRef} className="tabs">
						<li className="tab col s6"><a
							href="#treatment">{langs[lang].faq_page_list_title_for_clients}</a></li>
						<li className="tab col s6"><a
							href="#courses">{langs[lang].faq_page_list_title_for_course_goers}</a></li>
					</ul>
				</div>
				<div id="treatment" className="col s12">
					{treatmentQA.map((qa, i) =>
						<FAQElement id={qa.id} question={qa.question} answer={qa.answer} key={i}/>)}
				</div>
				<div id="courses" className="col s12">
					{courseQA.map((qa, i) => (
						<FAQElement id={qa.id} question={qa.question} answer={qa.answer} key={i}/>
					))}
				</div>
			</div>

		</div>
	);
};
