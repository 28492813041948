export const faq_course: FAQQuestion[] = [
	{
		id: 1,
		question: "How can I sign up for the courses?",
		answer: "You can sign up at our reception, at Milorada Veljkovića Špaje 14 (first floor) in Nis. We are open Monday-Friday from 10:00 until 22:00, Saturday from 10:00 until 20:00, and Sunday from 12:00 until 20:00. \nTo sign up, you will need a copy of your ID card, as well as forward payment of at least 5.000 RSD. \nIf you are underage, instead of an ID card, you can use any document that shows your unique personal identity number.",
	}, {
		id: 2,
		question: "Is there an age limit for enrolling in the courses?",
		answer: "To sign up for any of the courses, you need to be older than 16 years of age.",
	}, {
		id: 3,
		question: "Can I miss class?",
		answer: "You need to be present in at least 80% of classes to take the exam. \nIf you have been prevented from attending due to an emergency, you will need to make up for missed classes in order to fulfil the minimum attendance requirement and be able to take the exam. \nThe price of additional classes is dependent on the number of missed hours.",
	}, {
		id: 4,
		question: "Do I need to have previous knowledge on massage to enroll in a course?",
		answer: "To enroll in our beginner course of relaxation massage (Level 1), you do not need any previous knowledge on massage. However, successful completion of the Level 1 course is a requirement for enrolment in our advanced course of remedial massage (Level 2). \nTo attend our weekend courses, you will need knowledge obtained at the Level 1 and Level 2 courses.",
	}, {
		id: 5,
		question: "Will I be able to get a job after the course?",
		answer: "As massage is something very practical, you will need to dedicate time to practice after the course. The more time you dedicate to improving your skills, the greater the chances of you finding a job are. \nDepending on your knowledge level, you can seek employment in: spa centres, physiotherapy clinics, massage parlors, cosmetics centres, hotels, etc. \nOur school is offering the chance of employment to the best students in our courses.",
	}, {
		id: 6,
		question: "Are there any physical qualities necessary to become a professional massage therapist?",
		answer: "There are no necessary physical qualities necessary to become a professional massage therapist. \nIt would be good if you were in good physical shape, lead a healthy lifestyle and avoid anything that might negatively impact your energy levels.",
	}, {
		id: 7,
		question: "Can I seek employment abroad after the course? ",
		answer: "If you would like to look for work outside of Serbia following course completion, you will need to take an additional exam to verify your knowledge. \nShould you pass it, you will receive a certificate recognised across most European countries. \nThe price of the additional exam is 9.000 RSD.",
	}, {
		id: 8,
		question: "Can I pay for the course in installments and are there early bird applications?",
		answer: "Yes! \nIf you make a forward payment two months prior to the beginning of the course, you will qualify for a discount of 1.500 RSD per level. \nYou can pay for the course in a way you find most suitable, with the condition that it is paid for in full seven days before the first class. \nIf you’d like to attend several Zen courses at once, take a look at our special offers below.",
	}, {
		id: 9,
		question: "I’ve completed a course in another school and have massage experience. Can I enroll in a Level 2 course?",
		answer: "You can, but you will need to pass our written and practical exam, so that we can make sure you fulfil the minimum enrolment requirements. \nThe price of the entrance exam is 3.000 RSD, with preparation materials priced at 1.500 RSD (optional). \nIf you have completed our Level 1 course, there is no need for an entrance exam, as this exam was already part of your previous course.",
	}, {
		id: 10,
		question: " Are there any prerequisites for enrolling in a weekend course? ",
		answer: "The prerequisite for enrolling in one of our weekend courses is having a certificate proving that you have successfully completed a beginner massage course in any school.",
	}, {
		id: 11,
		question: " What special offers do you have, if I want to attend multiple courses at once?",
		answer: "To those keen on professional improvement in the field of massage, we offer special benefits and discounts. These offers are valid for any applications received by 01.04.2020, with a forward payment of at least 10.000 RSD. \nOffer 1 \nLevel 1 ( Introduction and basic technique of relaxation massage) + Level 2 (Introduction and technique of remedial massage) \nFull price: 44.000 RSD \nDiscount price: 41.000 RSD \nOffer 2 \nLevel 1 ( Introduction and basic technique of relaxation massage) + Level 2 (Introduction and technique of remedial massage) + One Weekend Course \nFull price: 54.000 RSD \nDiscount price: 49.000 RSD \nOffer 3 \nLevel 1 ( Introduction and basic technique of relaxation massage) + Level 2 (Introduction and technique of remedial massage) + Two Weekend Courses \nFull price: 64.000 RSD \nDiscount price: 58.000 RSD \nOffer 4 \nTwo Weekend Courses (Prenatal Massage + Hot Stone Massage) \nFull price: 20.000 RSD \nDiscount price: 18.000 RSD",
	},
]
