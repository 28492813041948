export const testimonials = [
	{
		name: "Lifewithavista",
		text: "Odlična atmosfera i vrhunska masaža! Produžićemo boravak u Nišu samo da bismo otišli na još jednu masažu.",
	}, {
		name: "Ana Tanasković",
		text: "Ako se pitate kako da provedete nedeljno popodne siguro necete pogrešiti ako sebi priuštite masažu u ZEN-u! Profesionalna usluga, više nego ljubazno osoblje i užitak za sva čula.",
	}, {
		name: "Emilja Živković",
		text: "Ukoliko želite ugodjaj za sva cula, od prijatnog i stručnog osoblja, preko opuštajuće muzike i atmosfere, pa do osećaja lakoće na svakom koraku dok se sa osmehom vraćate svojoj kuci - ZEN je pravo mesto!",
	}, {
		name: "Igor Jovanović",
		text: "Kada god dođem u Niš, ovo je jedno od mesta koje moram da posetim. Terapeuti i recepcija su prljatejski raspoloženi, prijatni i, pre svega, profesionalnI. Najbolje mesto da se dođe, opusti i uživa u masaži. Velika ponuda trutmana, kao i masaže u parovima. Preporučujem toplog srca.",
	},
];
