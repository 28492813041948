import React, { useEffect, useState } from "react";
import { useLocale } from "../../hooks/useLocale";
import treatmentServices from "../../services/treatmentServices";
import "./TreatmantsPage.css";
import { Treatment } from "./Treatment";

export const TreatmentsPage = (props: any) => {
	const [treatments, setTreatments] = useState<Treatment[]>([]);
	const locale = useLocale();
	const {category_id} = props.match.params;

	const getTreatments = async () => {
		const treats = await treatmentServices.getByCategory(category_id, locale);
		setTreatments(treats);
	};

	useEffect(() => {
		getTreatments();
		window.scrollTo({top: 0});
		// eslint-disable-next-line
	}, []);

	return (
		<div className="treatment-list-container">
			<div className="container">
				{treatments.length > 0 ? treatments.map(t => (
						<Treatment key={t.id} treatment={t}/>
					)) :
					<div className="progress mt-5">
						<div className="indeterminate"/>
					</div>
				}
			</div>
		</div>
	);
};
