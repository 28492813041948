import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import routes from "../localization/routes/routes";
import { HomePage } from "../pages/home/HomePage";
import { OfferPage } from "../pages/offer/OfferPage";
import { TreatmentsPage } from "../pages/treatments/TreatmentsPage";
import { ConsultationFormPage } from "../pages/forms/ConsultationFormPage";
import { CareerFormPage } from "../pages/forms/CareerFormPage";
import { ReservationFormPage } from "../pages/forms/ReservationFormPage";
import { ContactPage } from "../pages/contact/ContactPage";
import { About } from "../pages/about/About";
import { FAQ } from "../pages/faq/FAQ";
import { BlogStub } from "../pages/blog/BlogStub";
import { PurchaseFailure } from "../pages/purchase/PurchaseFailure";
import { PurchaseSuccess } from "../pages/purchase/PurchaseSuccess";
import { useLocale } from "../hooks/useLocale";
import { Level1Course } from "../pages/courses/specificCourses/Level1Course";
import { Level2Course } from "../pages/courses/specificCourses/Level2Course";
import { WeekendSeminar } from "../pages/courses/WeekendSeminar";

type RouterProps = {

};
const Router = (props: RouterProps) => {
	const lang = useLocale();

	return (
		<Switch>
			<Route path={`/:lang/${routes[lang].home}`} exact component={HomePage}/>
			<Route path={`/:lang/${routes[lang].courses_level1}`} component={Level1Course}/>
			<Route path={`/:lang/${routes[lang].courses_level2}`} component={Level2Course}/>
			<Route path={`/:lang/${routes[lang].courses_level_weekend_seminar}`} component={WeekendSeminar}/>
			<Route path={`/:lang/${routes[lang].treatments}`} exact component={OfferPage}/>
			<Route path={`/:lang/${routes[lang].treatments}/:category_id`} exact component={TreatmentsPage}/>
			<Route path={`/:lang/${routes[lang].consultation}`} exact component={ConsultationFormPage}/>
			<Route path={`/:lang/${routes[lang].career}`}  exact component={CareerFormPage}/>
			<Route path={`/:lang/${routes[lang].reservation}`} exact component={ReservationFormPage}/>
			{/*<Route path={`/:lang/${routes[lang].voucher}`} exact component={Purchase}/>*/}
			<Route path={`/:lang/${routes[lang].contact}`} exact component={ContactPage}/>
			<Route path={`/:lang/${routes[lang].about}`} exact component={About}/>
			<Route path={`/:lang/${routes[lang].faq}`} exact component={FAQ}/>

			<Route path="/:lang/blog" exact component={BlogStub}/>

			{/*<Route path="/:lang/purchase" exact component={Purchase}/>*/}
			<Route path="/:lang/purchase/failure" exact component={PurchaseFailure}/>
			<Route path="/:lang/purchase/success" exact component={PurchaseSuccess}/>
			<Redirect path={`/${lang}`} to={`/${lang}/${routes[lang].home}`}/>
		</Switch>
	);
};

export default Router;
