export const faq_treatment: FAQQuestion[] =[
	{
		id: 1,
		question: "Are there any rules I need to know?",
		answer: "We aim to offer our clients the best possible experience. Therefore, there are some rules everyone needs to be aware of, so that the ambient is pleasant and therapists can focus on your needs. \nAs a sign of good will, please keep your voice down in our centre. \nYou must silence your phone before entering Zen.",
	}, {
		id: 2,
		question: "When should I come to my scheduled appointment?",
		answer: "Our advice is to come to your scheduled massage 5 minutes before the appointment is to start, which will give you enough time to prepare.",
	}, {
		id: 3,
		question: "What if I’m late to my scheduled appointment?",
		answer: "If you have been prevented from arriving on time, we will do our best to provide you with our full service. Should your therapist have a massage scheduled directly after your appointment, the duration of your massage will be modified, to prevent postponing the appointments of other clients.",
	}, {
		id: 4,
		question: "Can I choose my therapist?",
		answer: "Yes, of course. If you prefer one of our therapists to the others, please let us know when scheduling your appointment.",
	}, {
		id: 5,
		question: "How can I schedule an appointment if I have a voucher?",
		answer: "You will need to provide us with the numerical number of the voucher or the code of the voucher, if you have purchased it online.\n Cancelling an appointment needs to take place at least 24h prior to it! If you are unable to use your voucher in the time of its validity, please let us know. Otherwise, your voucher will become invalid.",
	}, {
		id: 6,
		question: "Can I work out after a massage?",
		answer: "While working out after a massage is not contraindicated, our recommendation is to give your body time to recuperate and regenerate after a massage, and leave your workout session for the following day. This is especially the case when it comes to sport and remedial massages.",
	}, {
		id: 7,
		question: "Does remedial massage hurt?",
		answer: "Remedial massage uses well-dosed pressure. This means that pressure will be applied to release muscle tension, without further damaging the tissue. This is why these massages are done in continuity 4-5 times in a row, to give the tense muscles time to gradually go back to normal.",
	}, {
		id: 8,
		question: "Should I get a massage while pregnant?",
		answer: "If prenatal tests show that your pregnancy is running smoothly, you can absolutely be massaged! \nMassage is allowed and recommended in all trimesters.",
	}, {
		id: 9,
		question: "Should I get a massage while on my period? ",
		answer: "If you are comfortable with it, then yes. A massage can relax you and relieve any menstrual pains you may be experiencing.",
	}, {
		id: 10,
		question: "How often should I schedule massages?",
		answer: "When your primary goal is to relax with one of our relaxation treatments, it is advisable to schedule a massage once per month. If you’re experiencing any body tension, or are a professional athlete, we advise you to schedule appointments at least once per week.",
	}, {
		id: 11,
		question: "What body parts are treated in a full-body massage?",
		answer: "Back, neck, shoulders, buttox, legs, feet, hands and face (optional).",
	}, {
		id: 12,
		question: "What are the benefits of massage?",
		answer: "Massage helps us relax, reduce chronic pain that we are experiencing, prevent injuries, improve our sleep quality, improve our posture, reduce muscle tension and stress, etc",
	}, {
		id: 13,
		question: "Should massage be painful?",
		answer: "Tense tissue is pressure-sensitive. In order to relax it, pressure is applied, which can hurt to a degree. However, this pain should never be intolerable. This is why it is advisable to schedule several massages in continuity to avoid strong muscle spasms.",
	},
]
