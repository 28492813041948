import * as React from "react";
import { ServiceNotAvailable } from "../errors/ServiceNotAvailable";

type BlogStubProps = {};
export const BlogStub = (props: BlogStubProps) => {
	return (
		<div style={{height: "78vh"}} className="container pt-5">
			<div className="p-5"/>
			<div className="row">
				<div className="col s12 m12 l12">
					<ServiceNotAvailable heading={"Blog trenutno nije dostupan"}
					                     heading2={"Dolazi uskoro"}/>
				</div>
			</div>
		</div>
	);
};
