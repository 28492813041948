export const requestHeaders = {
	"Accept": "text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9",
	"Content-Type": "application/x-www-form-urlencoded",
	"User-Agent": "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/80.0.3987.87 Safari/537.36",
	"Sec-Fetch-Dest": "document",
	"Sec-Fetch-Site": "cross-site",
	"Sec-Fetch-Mode": "navigate",
	"Sec-Fetch-User": "?1",
	"Accept-Encoding": "gzip, deflate, br",
	"Accept-Language": "en-US,en;q=0.9,sr-RS;q=0.8,sr;q=0.7",
};

// gateway address with failsafe testing url
export const gatewayAddress = process.env.REACT_APP_GATEWAY_ADDRESS || "https://ecg.test.upc.ua/rbrs/enter";
export const backendServerUrl = process.env.REACT_APP_BACKEND_SERVER_URL || "https://zenmasaza.com";
export const academyUrl = process.env.REACT_APP_ACADEMY_URL || "https://zenakademija.com";

export const level1DurationRs = "13.6.-8.7.2022."
export const level1DurationEn = "13.6.-8.7.2022."

export const level2DurationRs = "18.7-12.8.2022."
export const level2DurationEn = "18.7-12.8.2022."

export const level3DurationRs = "Upis 2022."
export const level3DurationEn = "Signup 2022."

export const level1Price = "30.000 RSD"
export const level2Price = "30.000 RSD"
