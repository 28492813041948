import React from "react";
import { useLocale } from "../../hooks/useLocale";
import langs from "../../localization/languages/languages";
import { parseUrls } from "../../util/parsing";
import { TreatmentPurchaseButton } from "./TreatmentPurchaseButton";
import { backendServerUrl } from "../../globals";


type TreatmentProps = {
	treatment: Treatment
};

export const Treatment = ({treatment}: TreatmentProps) => {
	const lang = useLocale();

	const {
		slug,
		name,
		description,
		prices,
		imageUrl
	} = treatment;

	return (
		<div className="row treatment-elem">
			<div className="col s12 m12 l3 image">
				<div className="course-element-avatar"
				     style={{backgroundImage: `url(${backendServerUrl + (imageUrl ? imageUrl : "/img/round-placeholder.png")})`}}>
					<div className="transparent-filter">
						<h6>{name.toUpperCase()}</h6>
					</div>
				</div>
			</div>
			<div className="col s12 m12 l6 description">
				{parseUrls(description, "gold")}
			</div>
			<div className="col s12 m12 l3 time-price">
				<div className="gold-line"/>
				<div className="row">
					<div className="col s12">
						<h6>{langs[lang].treatments_duration_price}</h6>
					</div>
				</div>
				<div className="row">
					{prices.length > 0 ?
						(prices.map((price, i) => (
							<TreatmentPurchaseButton key={slug} treatment={treatment} index={i}/>)))
						:
						(<div className="col s12 m12 l12">
                            <span>
	                            {langs[lang].treatments_not_available}
                            </span>
						</div>)}
				</div>
			</div>
		</div>
	);
};
