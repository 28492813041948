import React from "react";
import { Link } from "react-router-dom";
import { backendServerUrl } from "../globals";

export const parseUrls = (text: string, classes: string = "") => {
	const textParts = text.split(/\[[\w\u015A-\u0161\u0107-\u0111\u0179-\u017e@/:.\d\-+()\s]+]\([@#a-z/:.\d\-+()\s_]+\)/i);
	const urls = text.match(/\[([\w\u015A-\u0161\u0107-\u0111\u0179-\u017e@/:.\d\-+()\s]+)]\(([@#a-z/:.\d\-+()\s_]+)\)/gi);
	let out:any[] = [];
	textParts.forEach((t, i) => {
		const possibleBrParts = t.split("<br>");
		if (possibleBrParts.length > 1) {
			possibleBrParts.forEach(part => {
				out.push(part)
				out.push(<br/>);
			});
		} else {
			out.push(possibleBrParts);
		}
		if (urls && urls[i]) {
			const url = urls[i].match(/\[([\w\u015A-\u0161\u0107-\u0111\u0179-\u017e@/:.\d\-+()\s]+)]\(([@#\w/:.\d\-+()\s_]+)\)/i);
			if (url && url.length === 3){
				if (url[2].match(/\/[a-z]{2}\//)){
					out.push(<Link key={btoa(url[2])} className={classes} to={url[2]}>{url[1]}</Link>)
				} else {
					if (url[2].startsWith("/api")) {
						out.push(<a key={btoa(url[2])} className={classes}
						            href={backendServerUrl + url[2].substring("/api".length)}>{url[1]}</a>)
					} else {
						out.push(<a key={btoa(url[2])} className={classes} href={url[2]}>{url[1]}</a>)
					}
				}
			}
		}
	});
	return <span>
		{out.map(e=>e)}
	</span>;
};
